#starsLightMode,
#starsLightMode:after {
  z-index: 1;
  height: 1px;
  width: 1px;
}
.icon {
  z-index: 1;
  display: inline-block;
  font-size: 40px;
}
.feature {
  z-index: 1;
}
#starsLightMode {
  z-index: 1;
  -webkit-animation: animParticle 60s linear infinite;
  animation: animParticle 60s linear infinite;
  -webkit-box-shadow: 0 0 #fff, 2218px 302px #fff, 2116px 1445px #fff,
    2287px 1704px #fff, 1907px 352px #fff, 639px 1726px #fff, 1976px 2470px #fff,
    2485px 1154px #fff, 2343px 539px #fff, 1942px 2411px #fff, 497px 1674px #fff,
    1778px 2496px #fff, 1452px 263px #fff, 959px 293px #fff, 1905px 2329px #fff,
    2041px 276px #fff, 2132px 1146px #fff, 2413px 294px #fff, 2498px 1306px #fff,
    464px 1999px #fff, 1px 606px #fff, 172px 1370px #fff, 1922px 374px #fff,
    482px 2452px #fff, 2476px 1961px #fff, 2086px 775px #fff, 2559px 1619px #fff,
    390px 1998px #fff, 438px 2020px #fff, 1441px 471px #fff, 2507px 81px #fff,
    1270px 942px #fff, 947px 111px #fff, 1076px 1848px #fff, 226px 2237px #fff,
    1183px 1386px #fff, 1115px 1515px #fff, 1383px 1464px #fff, 793px 335px #fff,
    1716px 406px #fff, 1455px 32px #fff, 2371px 355px #fff, 85px 1994px #fff,
    557px 94px #fff, 989px 690px #fff, 1318px 1325px #fff, 808px 2162px #fff,
    2358px 2383px #fff, 1296px 1037px #fff, 1510px 21px #fff, 262px 1789px #fff,
    1966px 1295px #fff, 1903px 2352px #fff, 1799px 1797px #fff,
    1354px 1864px #fff, 267px 1567px #fff, 2005px 384px #fff, 1432px 2138px #fff,
    2093px 2228px #fff, 842px 2061px #fff, 2051px 2387px #fff, 1583px 861px #fff,
    472px 2222px #fff, 2521px 1168px #fff, 1825px 2469px #fff,
    1353px 2391px #fff, 503px 573px #fff, 788px 178px #fff, 279px 666px #fff,
    1199px 1839px #fff, 403px 1919px #fff, 1862px 786px #fff, 1951px 1369px #fff,
    2227px 2161px #fff, 1227px 1240px #fff, 2021px 1656px #fff, 807px 770px #fff,
    779px 402px #fff, 1077px 224px #fff, 2205px 1676px #fff, 407px 2036px #fff,
    2131px 475px #fff, 1458px 1626px #fff, 2159px 549px #fff, 1905px 2341px #fff,
    1084px 774px #fff, 1080px 1434px #fff, 1856px 1914px #fff,
    1963px 1171px #fff, 784px 2553px #fff, 1427px 1497px #fff, 1134px 414px #fff,
    1062px 451px #fff, 594px 1775px #fff, 571px 2367px #fff, 644px 1209px #fff,
    1974px 1869px #fff, 887px 167px #fff, 646px 117px #fff, 1818px 337px #fff,
    959px 1224px #fff, 224px 1420px #fff, 1266px 1286px #fff, 2302px 1834px #fff,
    1633px 1572px #fff, 716px 708px #fff, 2415px 1731px #fff, 742px 2127px #fff,
    1624px 1458px #fff, 288px 1102px #fff, 1917px 1363px #fff, 1217px 311px #fff,
    844px 1134px #fff, 1038px 1539px #fff, 1981px 1265px #fff,
    2517px 1142px #fff, 2127px 1121px #fff, 1995px 1366px #fff,
    1025px 410px #fff, 2476px 2426px #fff, 2330px 2451px #fff, 984px 882px #fff,
    254px 2546px #fff, 1746px 70px #fff, 1711px 1713px #fff, 1502px 940px #fff,
    1656px 1775px #fff, 1775px 448px #fff, 1188px 1804px #fff, 254px 2538px #fff,
    814px 2493px #fff, 2452px 845px #fff, 2309px 2385px #fff, 1555px 2399px #fff,
    581px 506px #fff, 546px 955px #fff, 13px 593px #fff, 2458px 350px #fff,
    2172px 1632px #fff, 1240px 479px #fff, 1485px 1377px #fff, 1028px 625px #fff,
    2037px 1483px #fff, 2381px 981px #fff, 267px 2201px #fff, 1758px 167px #fff,
    1366px 2230px #fff, 1742px 2122px #fff, 1915px 236px #fff,
    1633px 1499px #fff, 1174px 684px #fff, 2163px 1180px #fff, 800px 2481px #fff,
    1830px 1611px #fff, 369px 2119px #fff, 1323px 1615px #fff, 558px 2377px #fff,
    2355px 1118px #fff, 1543px 2352px #fff, 892px 31px #fff, 189px 998px #fff,
    1385px 983px #fff, 1363px 2547px #fff, 833px 281px #fff, 1078px 2199px #fff,
    1701px 1553px #fff, 1681px 1155px #fff, 471px 2114px #fff,
    1765px 2081px #fff, 615px 872px #fff, 203px 1580px #fff, 828px 2547px #fff,
    115px 813px #fff, 51px 336px #fff, 419px 1036px #fff, 2000px 2215px #fff,
    1768px 2255px #fff, 580px 1899px #fff, 2329px 2020px #fff, 595px 2143px #fff,
    2209px 1790px #fff, 2510px 2038px #fff, 1560px 370px #fff,
    2534px 1476px #fff, 1203px 2044px #fff, 1747px 1217px #fff, 11px 1825px #fff,
    1375px 417px #fff, 1852px 548px #fff, 2495px 2418px #fff, 788px 2335px #fff,
    33px 317px #fff, 1790px 2116px #fff, 1092px 1169px #fff, 20px 560px #fff,
    1999px 2135px #fff, 1594px 2093px #fff, 1360px 1809px #fff, 633px 48px #fff,
    2049px 886px #fff, 776px 1988px #fff, 2291px 1706px #fff, 1000px 2034px #fff,
    308px 105px #fff, 685px 949px #fff, 1856px 2223px #fff, 1198px 1155px #fff,
    2559px 433px #fff, 1106px 1733px #fff, 1341px 2246px #fff,
    1672px 1820px #fff, 284px 1084px #fff, 219px 2357px #fff, 2514px 1360px #fff,
    1969px 339px #fff, 2214px 2057px #fff, 438px 38px #fff, 402px 794px #fff,
    1395px 2307px #fff, 1595px 235px #fff, 2430px 139px #fff, 968px 2026px #fff,
    441px 544px #fff, 1949px 1113px #fff, 1551px 1739px #fff, 478px 2314px #fff,
    1204px 88px #fff, 2182px 1148px #fff, 1001px 828px #fff, 1966px 285px #fff,
    864px 1958px #fff, 863px 1714px #fff, 1724px 1390px #fff, 2411px 507px #fff,
    1292px 911px #fff, 971px 619px #fff, 1595px 748px #fff, 1852px 1422px #fff,
    843px 2500px #fff, 533px 2113px #fff, 2067px 2211px #fff, 1230px 1168px #fff,
    1739px 2472px #fff, 1244px 1733px #fff, 2426px 1409px #fff, 871px 511px #fff,
    1980px 1149px #fff, 1163px 1473px #fff, 2191px 7px #fff, 2541px 1364px #fff,
    883px 411px #fff, 1747px 1659px #fff, 645px 859px #fff, 1393px 1764px #fff,
    2153px 1642px #fff, 851px 2045px #fff, 1537px 1363px #fff,
    1454px 1780px #fff, 1854px 2096px #fff, 1538px 2284px #fff, 125px 316px #fff,
    2181px 1643px #fff, 1891px 1367px #fff, 1213px 1054px #fff,
    1167px 2190px #fff, 1231px 1763px #fff, 1635px 1759px #fff,
    2525px 2366px #fff, 1481px 794px #fff, 2395px 2269px #fff, 561px 1786px #fff,
    399px 1176px #fff, 1549px 1191px #fff, 1349px 2004px #fff, 2334px 22px #fff,
    257px 1652px #fff, 2277px 2274px #fff, 2472px 506px #fff, 1439px 825px #fff,
    764px 1010px #fff, 1592px 164px #fff, 658px 731px #fff, 2459px 2047px #fff,
    76px 1718px #fff, 1778px 2395px #fff, 867px 1143px #fff, 826px 539px #fff,
    1454px 2389px #fff, 1883px 746px #fff, 915px 2033px #fff, 1604px 1253px #fff,
    2544px 449px #fff, 2074px 1248px #fff, 179px 2084px #fff, 1022px 721px #fff,
    2201px 2190px #fff, 1366px 2059px #fff, 2194px 1463px #fff,
    1930px 864px #fff, 435px 409px #fff, 348px 1184px #fff, 1271px 243px #fff,
    1153px 1491px #fff, 1217px 2277px #fff, 806px 961px #fff, 696px 748px #fff,
    1010px 384px #fff, 1254px 1376px #fff, 1593px 2414px #fff, 1923px 495px #fff,
    764px 2418px #fff, 1447px 212px #fff, 360px 2445px #fff, 1411px 366px #fff,
    2147px 503px #fff, 1130px 871px #fff, 2px 2118px #fff, 891px 2049px #fff,
    651px 1153px #fff, 1547px 2397px #fff, 1444px 1231px #fff, 2074px 55px #fff,
    703px 2px #fff, 1263px 2489px #fff, 1334px 2052px #fff, 1525px 170px #fff,
    2494px 1016px #fff, 2142px 1201px #fff, 70px 1299px #fff, 2369px 1385px #fff,
    2151px 1862px #fff, 1691px 37px #fff, 1188px 350px #fff, 2523px 60px #fff,
    1482px 2112px #fff, 1588px 1020px #fff, 563px 1210px #fff, 418px 263px #fff,
    2313px 1740px #fff, 1289px 1499px #fff, 1393px 876px #fff, 587px 1936px #fff,
    1421px 2235px #fff, 1585px 1542px #fff, 1200px 248px #fff,
    1604px 2295px #fff, 2082px 2040px #fff, 2312px 2283px #fff,
    230px 1633px #fff, 2034px 1549px #fff, 69px 195px #fff, 129px 1441px #fff,
    1639px 1566px #fff, 483px 2219px #fff, 1338px 2358px #fff,
    1202px 1488px #fff, 148px 637px #fff, 2361px 2211px #fff, 1722px 273px #fff,
    1995px 1320px #fff, 850px 32px #fff, 2031px 91px #fff, 2166px 837px #fff,
    916px 385px #fff, 446px 1519px #fff, 212px 99px #fff, 725px 1812px #fff,
    1765px 910px #fff, 1134px 481px #fff, 2154px 1037px #fff, 2035px 978px #fff,
    420px 243px #fff, 388px 2143px #fff, 1813px 1131px #fff, 1134px 943px #fff,
    2127px 2307px #fff, 1133px 681px #fff, 907px 442px #fff, 1072px 1028px #fff,
    727px 57px #fff, 1842px 197px #fff, 1187px 1742px #fff, 1210px 1343px #fff,
    443px 1306px #fff, 2102px 1739px #fff, 1225px 2369px #fff, 345px 2016px #fff,
    1893px 1954px #fff, 267px 2244px #fff, 597px 2168px #fff, 1959px 179px #fff,
    2045px 1283px #fff, 798px 1489px #fff, 2039px 1857px #fff, 503px 265px #fff,
    1868px 190px #fff, 1066px 305px #fff, 1980px 1901px #fff, 1110px 1139px #fff,
    1278px 286px #fff, 1244px 566px #fff, 257px 497px #fff, 2074px 545px #fff,
    701px 427px #fff, 2106px 1852px #fff, 2293px 791px #fff, 973px 1670px #fff,
    1016px 255px #fff, 1983px 390px #fff, 2533px 1900px #fff, 1164px 1571px #fff,
    1386px 193px #fff, 686px 367px #fff, 2180px 2071px #fff, 1181px 2444px #fff,
    2531px 2089px #fff, 2304px 2104px #fff, 1798px 1068px #fff,
    1010px 658px #fff, 903px 2213px #fff, 1649px 2229px #fff, 1073px 2124px #fff,
    1137px 1779px #fff, 22px 955px #fff, 2552px 337px #fff, 1998px 23px #fff,
    832px 97px #fff, 1532px 1457px #fff, 920px 2539px #fff, 1287px 930px #fff,
    2548px 3px #fff, 998px 438px #fff, 2157px 2543px #fff, 1392px 1579px #fff,
    652px 1664px #fff, 62px 2135px #fff, 638px 783px #fff, 2348px 562px #fff,
    39px 2213px #fff, 1691px 395px #fff, 2349px 9px #fff, 273px 596px #fff,
    1832px 2285px #fff, 2010px 2150px #fff, 87px 2148px #fff, 67px 174px #fff,
    2463px 285px #fff, 816px 301px #fff, 1216px 1438px #fff, 1229px 422px #fff,
    1638px 1028px #fff, 1733px 2439px #fff, 259px 19px #fff, 2027px 1397px #fff,
    2502px 1961px #fff, 2085px 2115px #fff, 1898px 1586px #fff,
    936px 2193px #fff, 2298px 796px #fff, 1055px 57px #fff, 1477px 2333px #fff,
    2058px 694px #fff, 70px 825px #fff, 446px 415px #fff, 869px 546px #fff,
    427px 423px #fff, 2212px 1096px #fff, 1833px 672px #fff, 1845px 963px #fff,
    52px 2446px #fff, 1908px 1856px #fff, 293px 298px #fff, 1457px 1564px #fff,
    942px 2332px #fff, 492px 142px #fff, 258px 2066px #fff, 2510px 2370px #fff,
    354px 967px #fff, 2351px 1268px #fff, 1707px 510px #fff, 576px 1677px #fff,
    90px 2232px #fff, 957px 1184px #fff, 2359px 1797px #fff, 1216px 2230px #fff,
    525px 2504px #fff, 1673px 1158px #fff, 1571px 1995px #fff,
    1816px 1106px #fff, 2153px 1658px #fff, 1959px 2027px #fff,
    2450px 690px #fff, 659px 484px #fff, 1635px 2437px #fff, 1477px 871px #fff,
    1985px 1055px #fff, 428px 1332px #fff, 2239px 1759px #fff,
    2221px 1010px #fff, 1665px 1141px #fff, 2509px 2225px #fff,
    533px 2112px #fff, 1072px 573px #fff, 1728px 1297px #fff, 178px 326px #fff,
    850px 560px #fff, 34px 1565px #fff, 1124px 815px #fff, 2023px 126px #fff,
    129px 686px #fff, 400px 23px #fff, 77px 1472px #fff, 2374px 2080px #fff,
    2165px 1669px #fff, 922px 2502px #fff, 1461px 2555px #fff, 371px 839px #fff,
    1179px 1210px #fff, 213px 932px #fff, 2262px 2508px #fff, 57px 2270px #fff,
    1411px 2149px #fff, 423px 876px #fff, 175px 2039px #fff, 2539px 1110px #fff,
    2465px 587px #fff, 1891px 1173px #fff, 1147px 564px #fff, 965px 1137px #fff,
    985px 453px #fff, 1317px 1691px #fff, 1413px 954px #fff, 162px 497px #fff,
    2106px 1615px #fff, 1535px 1662px #fff, 1733px 1649px #fff, 280px 323px #fff,
    18px 1985px #fff, 673px 783px #fff, 2311px 2554px #fff, 1437px 651px #fff,
    352px 924px #fff, 1148px 1655px #fff, 2370px 1861px #fff, 2409px 1451px #fff,
    1560px 661px #fff, 893px 1984px #fff, 2145px 1681px #fff, 1703px 1467px #fff,
    158px 195px #fff, 1801px 2290px #fff, 439px 1155px #fff, 562px 2507px #fff,
    525px 2030px #fff, 1249px 1671px #fff, 1032px 71px #fff, 728px 2042px #fff,
    1731px 287px #fff, 1587px 692px #fff, 1151px 2349px #fff, 191px 515px #fff,
    2298px 890px #fff, 1577px 1378px #fff, 2341px 1030px #fff, 1221px 365px #fff,
    2538px 288px #fff, 2160px 1713px #fff, 1232px 1614px #fff,
    2494px 1514px #fff, 1702px 1082px #fff, 2069px 1277px #fff,
    1407px 651px #fff, 2014px 257px #fff, 2233px 204px #fff, 1719px 501px #fff,
    2191px 2000px #fff, 1883px 1685px #fff, 576px 1553px #fff,
    1972px 2075px #fff, 332px 3px #fff, 867px 2380px #fff, 606px 1621px #fff,
    107px 2270px #fff, 1514px 2040px #fff, 2550px 224px #fff, 2005px 854px #fff,
    434px 1457px #fff, 1507px 2287px #fff, 2512px 646px #fff, 886px 1044px #fff,
    2262px 446px #fff, 1084px 2207px #fff, 988px 1961px #fff, 1160px 859px #fff,
    54px 155px #fff, 2139px 550px #fff, 1442px 80px #fff, 770px 1753px #fff,
    1194px 267px #fff, 2048px 2169px #fff, 711px 2291px #fff;
  box-shadow: 0 0 #fff, 2218px 302px #fff, 2116px 1445px #fff,
    2287px 1704px #fff, 1907px 352px #fff, 639px 1726px #fff, 1976px 2470px #fff,
    2485px 1154px #fff, 2343px 539px #fff, 1942px 2411px #fff, 497px 1674px #fff,
    1778px 2496px #fff, 1452px 263px #fff, 959px 293px #fff, 1905px 2329px #fff,
    2041px 276px #fff, 2132px 1146px #fff, 2413px 294px #fff, 2498px 1306px #fff,
    464px 1999px #fff, 1px 606px #fff, 172px 1370px #fff, 1922px 374px #fff,
    482px 2452px #fff, 2476px 1961px #fff, 2086px 775px #fff, 2559px 1619px #fff,
    390px 1998px #fff, 438px 2020px #fff, 1441px 471px #fff, 2507px 81px #fff,
    1270px 942px #fff, 947px 111px #fff, 1076px 1848px #fff, 226px 2237px #fff,
    1183px 1386px #fff, 1115px 1515px #fff, 1383px 1464px #fff, 793px 335px #fff,
    1716px 406px #fff, 1455px 32px #fff, 2371px 355px #fff, 85px 1994px #fff,
    557px 94px #fff, 989px 690px #fff, 1318px 1325px #fff, 808px 2162px #fff,
    2358px 2383px #fff, 1296px 1037px #fff, 1510px 21px #fff, 262px 1789px #fff,
    1966px 1295px #fff, 1903px 2352px #fff, 1799px 1797px #fff,
    1354px 1864px #fff, 267px 1567px #fff, 2005px 384px #fff, 1432px 2138px #fff,
    2093px 2228px #fff, 842px 2061px #fff, 2051px 2387px #fff, 1583px 861px #fff,
    472px 2222px #fff, 2521px 1168px #fff, 1825px 2469px #fff,
    1353px 2391px #fff, 503px 573px #fff, 788px 178px #fff, 279px 666px #fff,
    1199px 1839px #fff, 403px 1919px #fff, 1862px 786px #fff, 1951px 1369px #fff,
    2227px 2161px #fff, 1227px 1240px #fff, 2021px 1656px #fff, 807px 770px #fff,
    779px 402px #fff, 1077px 224px #fff, 2205px 1676px #fff, 407px 2036px #fff,
    2131px 475px #fff, 1458px 1626px #fff, 2159px 549px #fff, 1905px 2341px #fff,
    1084px 774px #fff, 1080px 1434px #fff, 1856px 1914px #fff,
    1963px 1171px #fff, 784px 2553px #fff, 1427px 1497px #fff, 1134px 414px #fff,
    1062px 451px #fff, 594px 1775px #fff, 571px 2367px #fff, 644px 1209px #fff,
    1974px 1869px #fff, 887px 167px #fff, 646px 117px #fff, 1818px 337px #fff,
    959px 1224px #fff, 224px 1420px #fff, 1266px 1286px #fff, 2302px 1834px #fff,
    1633px 1572px #fff, 716px 708px #fff, 2415px 1731px #fff, 742px 2127px #fff,
    1624px 1458px #fff, 288px 1102px #fff, 1917px 1363px #fff, 1217px 311px #fff,
    844px 1134px #fff, 1038px 1539px #fff, 1981px 1265px #fff,
    2517px 1142px #fff, 2127px 1121px #fff, 1995px 1366px #fff,
    1025px 410px #fff, 2476px 2426px #fff, 2330px 2451px #fff, 984px 882px #fff,
    254px 2546px #fff, 1746px 70px #fff, 1711px 1713px #fff, 1502px 940px #fff,
    1656px 1775px #fff, 1775px 448px #fff, 1188px 1804px #fff, 254px 2538px #fff,
    814px 2493px #fff, 2452px 845px #fff, 2309px 2385px #fff, 1555px 2399px #fff,
    581px 506px #fff, 546px 955px #fff, 13px 593px #fff, 2458px 350px #fff,
    2172px 1632px #fff, 1240px 479px #fff, 1485px 1377px #fff, 1028px 625px #fff,
    2037px 1483px #fff, 2381px 981px #fff, 267px 2201px #fff, 1758px 167px #fff,
    1366px 2230px #fff, 1742px 2122px #fff, 1915px 236px #fff,
    1633px 1499px #fff, 1174px 684px #fff, 2163px 1180px #fff, 800px 2481px #fff,
    1830px 1611px #fff, 369px 2119px #fff, 1323px 1615px #fff, 558px 2377px #fff,
    2355px 1118px #fff, 1543px 2352px #fff, 892px 31px #fff, 189px 998px #fff,
    1385px 983px #fff, 1363px 2547px #fff, 833px 281px #fff, 1078px 2199px #fff,
    1701px 1553px #fff, 1681px 1155px #fff, 471px 2114px #fff,
    1765px 2081px #fff, 615px 872px #fff, 203px 1580px #fff, 828px 2547px #fff,
    115px 813px #fff, 51px 336px #fff, 419px 1036px #fff, 2000px 2215px #fff,
    1768px 2255px #fff, 580px 1899px #fff, 2329px 2020px #fff, 595px 2143px #fff,
    2209px 1790px #fff, 2510px 2038px #fff, 1560px 370px #fff,
    2534px 1476px #fff, 1203px 2044px #fff, 1747px 1217px #fff, 11px 1825px #fff,
    1375px 417px #fff, 1852px 548px #fff, 2495px 2418px #fff, 788px 2335px #fff,
    33px 317px #fff, 1790px 2116px #fff, 1092px 1169px #fff, 20px 560px #fff,
    1999px 2135px #fff, 1594px 2093px #fff, 1360px 1809px #fff, 633px 48px #fff,
    2049px 886px #fff, 776px 1988px #fff, 2291px 1706px #fff, 1000px 2034px #fff,
    308px 105px #fff, 685px 949px #fff, 1856px 2223px #fff, 1198px 1155px #fff,
    2559px 433px #fff, 1106px 1733px #fff, 1341px 2246px #fff,
    1672px 1820px #fff, 284px 1084px #fff, 219px 2357px #fff, 2514px 1360px #fff,
    1969px 339px #fff, 2214px 2057px #fff, 438px 38px #fff, 402px 794px #fff,
    1395px 2307px #fff, 1595px 235px #fff, 2430px 139px #fff, 968px 2026px #fff,
    441px 544px #fff, 1949px 1113px #fff, 1551px 1739px #fff, 478px 2314px #fff,
    1204px 88px #fff, 2182px 1148px #fff, 1001px 828px #fff, 1966px 285px #fff,
    864px 1958px #fff, 863px 1714px #fff, 1724px 1390px #fff, 2411px 507px #fff,
    1292px 911px #fff, 971px 619px #fff, 1595px 748px #fff, 1852px 1422px #fff,
    843px 2500px #fff, 533px 2113px #fff, 2067px 2211px #fff, 1230px 1168px #fff,
    1739px 2472px #fff, 1244px 1733px #fff, 2426px 1409px #fff, 871px 511px #fff,
    1980px 1149px #fff, 1163px 1473px #fff, 2191px 7px #fff, 2541px 1364px #fff,
    883px 411px #fff, 1747px 1659px #fff, 645px 859px #fff, 1393px 1764px #fff,
    2153px 1642px #fff, 851px 2045px #fff, 1537px 1363px #fff,
    1454px 1780px #fff, 1854px 2096px #fff, 1538px 2284px #fff, 125px 316px #fff,
    2181px 1643px #fff, 1891px 1367px #fff, 1213px 1054px #fff,
    1167px 2190px #fff, 1231px 1763px #fff, 1635px 1759px #fff,
    2525px 2366px #fff, 1481px 794px #fff, 2395px 2269px #fff, 561px 1786px #fff,
    399px 1176px #fff, 1549px 1191px #fff, 1349px 2004px #fff, 2334px 22px #fff,
    257px 1652px #fff, 2277px 2274px #fff, 2472px 506px #fff, 1439px 825px #fff,
    764px 1010px #fff, 1592px 164px #fff, 658px 731px #fff, 2459px 2047px #fff,
    76px 1718px #fff, 1778px 2395px #fff, 867px 1143px #fff, 826px 539px #fff,
    1454px 2389px #fff, 1883px 746px #fff, 915px 2033px #fff, 1604px 1253px #fff,
    2544px 449px #fff, 2074px 1248px #fff, 179px 2084px #fff, 1022px 721px #fff,
    2201px 2190px #fff, 1366px 2059px #fff, 2194px 1463px #fff,
    1930px 864px #fff, 435px 409px #fff, 348px 1184px #fff, 1271px 243px #fff,
    1153px 1491px #fff, 1217px 2277px #fff, 806px 961px #fff, 696px 748px #fff,
    1010px 384px #fff, 1254px 1376px #fff, 1593px 2414px #fff, 1923px 495px #fff,
    764px 2418px #fff, 1447px 212px #fff, 360px 2445px #fff, 1411px 366px #fff,
    2147px 503px #fff, 1130px 871px #fff, 2px 2118px #fff, 891px 2049px #fff,
    651px 1153px #fff, 1547px 2397px #fff, 1444px 1231px #fff, 2074px 55px #fff,
    703px 2px #fff, 1263px 2489px #fff, 1334px 2052px #fff, 1525px 170px #fff,
    2494px 1016px #fff, 2142px 1201px #fff, 70px 1299px #fff, 2369px 1385px #fff,
    2151px 1862px #fff, 1691px 37px #fff, 1188px 350px #fff, 2523px 60px #fff,
    1482px 2112px #fff, 1588px 1020px #fff, 563px 1210px #fff, 418px 263px #fff,
    2313px 1740px #fff, 1289px 1499px #fff, 1393px 876px #fff, 587px 1936px #fff,
    1421px 2235px #fff, 1585px 1542px #fff, 1200px 248px #fff,
    1604px 2295px #fff, 2082px 2040px #fff, 2312px 2283px #fff,
    230px 1633px #fff, 2034px 1549px #fff, 69px 195px #fff, 129px 1441px #fff,
    1639px 1566px #fff, 483px 2219px #fff, 1338px 2358px #fff,
    1202px 1488px #fff, 148px 637px #fff, 2361px 2211px #fff, 1722px 273px #fff,
    1995px 1320px #fff, 850px 32px #fff, 2031px 91px #fff, 2166px 837px #fff,
    916px 385px #fff, 446px 1519px #fff, 212px 99px #fff, 725px 1812px #fff,
    1765px 910px #fff, 1134px 481px #fff, 2154px 1037px #fff, 2035px 978px #fff,
    420px 243px #fff, 388px 2143px #fff, 1813px 1131px #fff, 1134px 943px #fff,
    2127px 2307px #fff, 1133px 681px #fff, 907px 442px #fff, 1072px 1028px #fff,
    727px 57px #fff, 1842px 197px #fff, 1187px 1742px #fff, 1210px 1343px #fff,
    443px 1306px #fff, 2102px 1739px #fff, 1225px 2369px #fff, 345px 2016px #fff,
    1893px 1954px #fff, 267px 2244px #fff, 597px 2168px #fff, 1959px 179px #fff,
    2045px 1283px #fff, 798px 1489px #fff, 2039px 1857px #fff, 503px 265px #fff,
    1868px 190px #fff, 1066px 305px #fff, 1980px 1901px #fff, 1110px 1139px #fff,
    1278px 286px #fff, 1244px 566px #fff, 257px 497px #fff, 2074px 545px #fff,
    701px 427px #fff, 2106px 1852px #fff, 2293px 791px #fff, 973px 1670px #fff,
    1016px 255px #fff, 1983px 390px #fff, 2533px 1900px #fff, 1164px 1571px #fff,
    1386px 193px #fff, 686px 367px #fff, 2180px 2071px #fff, 1181px 2444px #fff,
    2531px 2089px #fff, 2304px 2104px #fff, 1798px 1068px #fff,
    1010px 658px #fff, 903px 2213px #fff, 1649px 2229px #fff, 1073px 2124px #fff,
    1137px 1779px #fff, 22px 955px #fff, 2552px 337px #fff, 1998px 23px #fff,
    832px 97px #fff, 1532px 1457px #fff, 920px 2539px #fff, 1287px 930px #fff,
    2548px 3px #fff, 998px 438px #fff, 2157px 2543px #fff, 1392px 1579px #fff,
    652px 1664px #fff, 62px 2135px #fff, 638px 783px #fff, 2348px 562px #fff,
    39px 2213px #fff, 1691px 395px #fff, 2349px 9px #fff, 273px 596px #fff,
    1832px 2285px #fff, 2010px 2150px #fff, 87px 2148px #fff, 67px 174px #fff,
    2463px 285px #fff, 816px 301px #fff, 1216px 1438px #fff, 1229px 422px #fff,
    1638px 1028px #fff, 1733px 2439px #fff, 259px 19px #fff, 2027px 1397px #fff,
    2502px 1961px #fff, 2085px 2115px #fff, 1898px 1586px #fff,
    936px 2193px #fff, 2298px 796px #fff, 1055px 57px #fff, 1477px 2333px #fff,
    2058px 694px #fff, 70px 825px #fff, 446px 415px #fff, 869px 546px #fff,
    427px 423px #fff, 2212px 1096px #fff, 1833px 672px #fff, 1845px 963px #fff,
    52px 2446px #fff, 1908px 1856px #fff, 293px 298px #fff, 1457px 1564px #fff,
    942px 2332px #fff, 492px 142px #fff, 258px 2066px #fff, 2510px 2370px #fff,
    354px 967px #fff, 2351px 1268px #fff, 1707px 510px #fff, 576px 1677px #fff,
    90px 2232px #fff, 957px 1184px #fff, 2359px 1797px #fff, 1216px 2230px #fff,
    525px 2504px #fff, 1673px 1158px #fff, 1571px 1995px #fff,
    1816px 1106px #fff, 2153px 1658px #fff, 1959px 2027px #fff,
    2450px 690px #fff, 659px 484px #fff, 1635px 2437px #fff, 1477px 871px #fff,
    1985px 1055px #fff, 428px 1332px #fff, 2239px 1759px #fff,
    2221px 1010px #fff, 1665px 1141px #fff, 2509px 2225px #fff,
    533px 2112px #fff, 1072px 573px #fff, 1728px 1297px #fff, 178px 326px #fff,
    850px 560px #fff, 34px 1565px #fff, 1124px 815px #fff, 2023px 126px #fff,
    129px 686px #fff, 400px 23px #fff, 77px 1472px #fff, 2374px 2080px #fff,
    2165px 1669px #fff, 922px 2502px #fff, 1461px 2555px #fff, 371px 839px #fff,
    1179px 1210px #fff, 213px 932px #fff, 2262px 2508px #fff, 57px 2270px #fff,
    1411px 2149px #fff, 423px 876px #fff, 175px 2039px #fff, 2539px 1110px #fff,
    2465px 587px #fff, 1891px 1173px #fff, 1147px 564px #fff, 965px 1137px #fff,
    985px 453px #fff, 1317px 1691px #fff, 1413px 954px #fff, 162px 497px #fff,
    2106px 1615px #fff, 1535px 1662px #fff, 1733px 1649px #fff, 280px 323px #fff,
    18px 1985px #fff, 673px 783px #fff, 2311px 2554px #fff, 1437px 651px #fff,
    352px 924px #fff, 1148px 1655px #fff, 2370px 1861px #fff, 2409px 1451px #fff,
    1560px 661px #fff, 893px 1984px #fff, 2145px 1681px #fff, 1703px 1467px #fff,
    158px 195px #fff, 1801px 2290px #fff, 439px 1155px #fff, 562px 2507px #fff,
    525px 2030px #fff, 1249px 1671px #fff, 1032px 71px #fff, 728px 2042px #fff,
    1731px 287px #fff, 1587px 692px #fff, 1151px 2349px #fff, 191px 515px #fff,
    2298px 890px #fff, 1577px 1378px #fff, 2341px 1030px #fff, 1221px 365px #fff,
    2538px 288px #fff, 2160px 1713px #fff, 1232px 1614px #fff,
    2494px 1514px #fff, 1702px 1082px #fff, 2069px 1277px #fff,
    1407px 651px #fff, 2014px 257px #fff, 2233px 204px #fff, 1719px 501px #fff,
    2191px 2000px #fff, 1883px 1685px #fff, 576px 1553px #fff,
    1972px 2075px #fff, 332px 3px #fff, 867px 2380px #fff, 606px 1621px #fff,
    107px 2270px #fff, 1514px 2040px #fff, 2550px 224px #fff, 2005px 854px #fff,
    434px 1457px #fff, 1507px 2287px #fff, 2512px 646px #fff, 886px 1044px #fff,
    2262px 446px #fff, 1084px 2207px #fff, 988px 1961px #fff, 1160px 859px #fff,
    54px 155px #fff, 2139px 550px #fff, 1442px 80px #fff, 770px 1753px #fff,
    1194px 267px #fff, 2048px 2169px #fff, 711px 2291px #fff;
}
#starsLightMode:after {
  z-index: 1;

  -webkit-box-shadow: 0 0 #fff, 59px 1384px #fff, 1167px 2088px #fff,
    471px 1238px #fff, 1696px 220px #fff, 1097px 215px #fff, 253px 415px #fff,
    1023px 700px #fff, 1145px 1587px #fff, 2502px 1354px #fff, 630px 1362px #fff,
    1888px 2201px #fff, 1822px 308px #fff, 2223px 928px #fff, 1233px 1816px #fff,
    1367px 2139px #fff, 597px 2189px #fff, 1530px 929px #fff, 752px 1781px #fff,
    2467px 1193px #fff, 787px 801px #fff, 2447px 581px #fff, 1417px 1633px #fff,
    2537px 2472px #fff, 2387px 2344px #fff, 2366px 381px #fff,
    2405px 2139px #fff, 1523px 1236px #fff, 2171px 1242px #fff,
    2384px 2263px #fff, 2436px 1304px #fff, 1295px 2238px #fff, 463px 451px #fff,
    1509px 1022px #fff, 1489px 951px #fff, 1319px 1471px #fff,
    2528px 2550px #fff, 2161px 2429px #fff, 1734px 316px #fff, 306px 854px #fff,
    2002px 2356px #fff, 1412px 2382px #fff, 2124px 2486px #fff, 808px 223px #fff,
    498px 2503px #fff, 571px 2190px #fff, 1811px 2503px #fff, 31px 16px #fff,
    412px 466px #fff, 1181px 1833px #fff, 844px 4px #fff, 1695px 524px #fff,
    951px 1848px #fff, 1264px 35px #fff, 1461px 1619px #fff, 1608px 1909px #fff,
    1929px 115px #fff, 1882px 886px #fff, 440px 1905px #fff, 573px 1919px #fff,
    287px 2540px #fff, 2031px 1172px #fff, 851px 894px #fff, 2403px 1960px #fff,
    1508px 967px #fff, 666px 360px #fff, 2126px 2386px #fff, 1945px 1418px #fff,
    813px 2359px #fff, 124px 542px #fff, 206px 581px #fff, 1639px 369px #fff,
    2453px 1188px #fff, 713px 595px #fff, 731px 1870px #fff, 485px 239px #fff,
    2056px 2497px #fff, 49px 734px #fff, 1730px 2214px #fff, 1913px 121px #fff,
    2040px 450px #fff, 1290px 2025px #fff, 651px 2535px #fff, 1657px 1124px #fff,
    1484px 1998px #fff, 50px 22px #fff, 1779px 2423px #fff, 2026px 2353px #fff,
    2407px 1364px #fff, 1152px 498px #fff, 382px 1274px #fff, 415px 701px #fff,
    2364px 18px #fff, 805px 1502px #fff, 601px 1702px #fff, 50px 1408px #fff,
    1220px 1092px #fff, 1392px 1740px #fff, 808px 1466px #fff, 1996px 429px #fff,
    827px 1529px #fff, 111px 1899px #fff, 709px 616px #fff, 2423px 1984px #fff,
    1959px 2507px #fff, 842px 578px #fff, 35px 1310px #fff, 2033px 287px #fff,
    2076px 1157px #fff, 1295px 1199px #fff, 798px 1106px #fff, 2022px 670px #fff,
    1614px 1150px #fff, 1152px 1094px #fff, 1831px 1681px #fff,
    621px 1182px #fff, 1094px 2190px #fff, 1286px 1410px #fff, 516px 104px #fff,
    1847px 818px #fff, 1618px 965px #fff, 290px 524px #fff, 204px 1372px #fff,
    1001px 2366px #fff, 373px 1750px #fff, 2288px 371px #fff, 411px 652px #fff,
    2200px 1338px #fff, 862px 819px #fff, 1449px 2291px #fff, 1936px 72px #fff,
    1345px 1176px #fff, 2425px 700px #fff, 1967px 119px #fff, 2083px 1619px #fff,
    823px 608px #fff, 1319px 1485px #fff, 411px 2285px #fff, 1069px 1297px #fff,
    1618px 1658px #fff, 1636px 1676px #fff, 1824px 378px #fff, 1528px 693px #fff,
    163px 367px #fff, 379px 732px #fff, 678px 1179px #fff, 2481px 844px #fff,
    878px 1672px #fff, 778px 725px #fff, 661px 1635px #fff, 15px 761px #fff,
    383px 1552px #fff, 1827px 2067px #fff, 1239px 304px #fff, 1101px 33px #fff,
    1972px 2130px #fff, 1042px 2391px #fff, 1354px 795px #fff, 30px 1960px #fff,
    2290px 686px #fff, 1006px 1557px #fff, 441px 1155px #fff, 1749px 951px #fff,
    2343px 238px #fff, 949px 142px #fff, 2423px 2285px #fff, 1484px 2077px #fff,
    913px 2232px #fff, 358px 2235px #fff, 630px 154px #fff, 1792px 2492px #fff,
    999px 2381px #fff, 1722px 2180px #fff, 454px 736px #fff, 2250px 20px #fff,
    833px 572px #fff, 371px 188px #fff, 2157px 1659px #fff, 2492px 1808px #fff,
    2391px 1003px #fff, 2061px 1398px #fff, 238px 2041px #fff, 1865px 313px #fff,
    675px 318px #fff, 1989px 1710px #fff, 2207px 702px #fff, 2510px 323px #fff,
    1946px 1569px #fff, 43px 1025px #fff, 88px 247px #fff, 1640px 548px #fff,
    858px 1057px #fff, 602px 569px #fff, 1444px 1195px #fff, 1285px 238px #fff,
    1432px 1773px #fff, 2549px 1269px #fff, 410px 1230px #fff, 122px 1340px #fff,
    2532px 1160px #fff, 1192px 2449px #fff, 1558px 1782px #fff,
    241px 1717px #fff, 234px 2551px #fff, 2004px 1361px #fff, 1945px 2201px #fff,
    1861px 9px #fff, 623px 826px #fff, 2061px 1819px #fff, 1089px 1867px #fff,
    2048px 2336px #fff, 2105px 1834px #fff, 519px 1693px #fff, 925px 70px #fff,
    1175px 1512px #fff, 1685px 1561px #fff, 1694px 2560px #fff,
    2480px 1313px #fff, 1003px 1504px #fff, 2017px 2261px #fff, 824px 605px #fff,
    104px 2313px #fff, 1338px 2491px #fff, 2339px 2030px #fff, 208px 675px #fff,
    103px 2340px #fff, 1914px 2121px #fff, 746px 811px #fff, 1811px 1220px #fff,
    1210px 1037px #fff, 1532px 1044px #fff, 1720px 1817px #fff,
    2440px 2126px #fff, 1722px 203px #fff, 39px 358px #fff, 981px 1921px #fff,
    2538px 1618px #fff, 125px 2319px #fff, 744px 1899px #fff, 2230px 480px #fff,
    643px 2278px #fff, 149px 1264px #fff, 1298px 291px #fff, 2252px 291px #fff,
    616px 2090px #fff, 1633px 64px #fff, 2116px 2548px #fff, 389px 1576px #fff,
    1227px 2520px #fff, 2031px 1221px #fff, 775px 83px #fff, 977px 1629px #fff,
    959px 2416px #fff, 438px 2342px #fff, 2144px 1911px #fff, 2199px 2343px #fff,
    1837px 1799px #fff, 1011px 1833px #fff, 1645px 1450px #fff,
    948px 1827px #fff, 2458px 868px #fff, 475px 1625px #fff, 1149px 649px #fff,
    1771px 1874px #fff, 1801px 67px #fff, 772px 1741px #fff, 1003px 48px #fff,
    869px 813px #fff, 2428px 1812px #fff, 1461px 433px #fff, 947px 607px #fff,
    1438px 132px #fff, 1070px 1469px #fff, 2090px 547px #fff, 2500px 1856px #fff,
    1489px 1688px #fff, 1840px 1602px #fff, 693px 685px #fff, 1159px 2497px #fff,
    1694px 2289px #fff, 21px 992px #fff, 2285px 1305px #fff, 1061px 1073px #fff,
    2335px 999px #fff, 2288px 249px #fff, 1661px 2540px #fff, 2263px 2099px #fff,
    1836px 386px #fff, 1559px 1131px #fff, 743px 2017px #fff, 17px 233px #fff,
    2390px 1414px #fff, 835px 1421px #fff, 696px 2237px #fff, 2454px 687px #fff,
    1442px 1793px #fff, 1175px 1180px #fff, 1315px 349px #fff,
    2033px 1441px #fff, 728px 976px #fff, 1347px 1293px #fff, 1295px 2356px #fff,
    400px 344px #fff, 890px 718px #fff, 581px 2050px #fff, 1858px 2351px #fff,
    2436px 1097px #fff, 1072px 2513px #fff, 2467px 951px #fff, 321px 1288px #fff,
    426px 2128px #fff, 1631px 799px #fff, 1107px 1546px #fff, 244px 2314px #fff,
    2247px 2333px #fff, 2407px 906px #fff, 1821px 444px #fff, 93px 34px #fff,
    621px 2297px #fff, 782px 1309px #fff, 2324px 1776px #fff, 834px 230px #fff,
    1023px 1731px #fff, 2334px 2267px #fff, 1329px 2050px #fff,
    1234px 1580px #fff, 2305px 2316px #fff, 440px 199px #fff, 460px 2475px #fff,
    1602px 733px #fff, 1574px 121px #fff, 537px 1645px #fff, 1723px 1631px #fff,
    1862px 306px #fff, 1288px 261px #fff, 1163px 1806px #fff, 1175px 346px #fff,
    568px 1507px #fff, 543px 2202px #fff, 2339px 999px #fff, 1877px 2164px #fff,
    205px 2257px #fff, 2510px 271px #fff, 848px 655px #fff, 536px 463px #fff,
    1577px 1425px #fff, 2550px 1023px #fff, 1467px 972px #fff, 1550px 844px #fff,
    1297px 869px #fff, 2207px 1043px #fff, 2282px 104px #fff, 203px 39px #fff,
    916px 785px #fff, 2439px 2238px #fff, 1780px 486px #fff, 919px 287px #fff,
    226px 1948px #fff, 918px 1644px #fff, 998px 507px #fff, 1268px 1073px #fff,
    935px 500px #fff, 1505px 300px #fff, 1068px 58px #fff, 2442px 312px #fff,
    223px 1849px #fff, 1251px 991px #fff, 1186px 472px #fff, 2110px 457px #fff,
    1027px 1331px #fff, 248px 913px #fff, 2157px 291px #fff, 498px 4px #fff,
    95px 2538px #fff, 911px 2281px #fff, 69px 726px #fff, 18px 1451px #fff,
    2194px 1883px #fff, 1493px 1410px #fff, 1969px 964px #fff,
    1131px 1497px #fff, 927px 1677px #fff, 2330px 1887px #fff,
    1942px 2338px #fff, 2222px 1135px #fff, 327px 692px #fff, 2174px 545px #fff,
    2535px 663px #fff, 921px 1570px #fff, 866px 849px #fff, 1803px 769px #fff,
    1008px 196px #fff, 439px 918px #fff, 2143px 848px #fff, 1752px 1431px #fff,
    2189px 160px #fff, 423px 659px #fff, 1733px 1856px #fff, 1499px 1435px #fff,
    486px 933px #fff, 329px 908px #fff, 789px 311px #fff, 2030px 395px #fff,
    1659px 1401px #fff, 967px 2287px #fff, 2404px 443px #fff, 1506px 575px #fff,
    1241px 1777px #fff, 736px 624px #fff, 1531px 308px #fff, 2022px 1079px #fff,
    2273px 1114px #fff, 51px 1349px #fff, 987px 225px #fff, 189px 1518px #fff,
    252px 2478px #fff, 2213px 2232px #fff, 1056px 1134px #fff,
    1164px 2330px #fff, 686px 18px #fff, 1839px 1513px #fff, 690px 2176px #fff,
    1464px 2197px #fff, 1185px 958px #fff, 1158px 1477px #fff,
    1176px 1474px #fff, 1544px 592px #fff, 1599px 2521px #fff, 1827px 152px #fff,
    441px 1694px #fff, 379px 1250px #fff, 2074px 1076px #fff, 737px 1656px #fff,
    983px 109px #fff, 1749px 149px #fff, 1980px 118px #fff, 256px 1261px #fff,
    41px 1045px #fff, 1104px 451px #fff, 59px 737px #fff, 111px 1239px #fff,
    1016px 1289px #fff, 121px 1290px #fff, 1353px 1835px #fff, 869px 1256px #fff,
    90px 1318px #fff, 2106px 1297px #fff, 597px 1532px #fff, 1815px 646px #fff,
    1454px 2314px #fff, 645px 393px #fff, 2238px 51px #fff, 2514px 1773px #fff,
    952px 287px #fff, 254px 942px #fff, 1698px 751px #fff, 637px 2313px #fff,
    668px 2523px #fff, 1429px 1234px #fff, 1845px 635px #fff, 1029px 2271px #fff,
    1313px 20px #fff, 2316px 621px #fff, 2042px 499px #fff, 98px 2526px #fff,
    1351px 1173px #fff, 1246px 1953px #fff, 1054px 1413px #fff,
    1156px 2184px #fff, 101px 2548px #fff, 898px 2474px #fff, 214px 1899px #fff,
    785px 970px #fff, 2505px 1927px #fff, 1591px 1341px #fff, 2193px 1664px #fff,
    2296px 1416px #fff, 2160px 2512px #fff, 2222px 1901px #fff,
    2515px 1695px #fff, 562px 919px #fff, 1846px 2112px #fff, 2118px 2112px #fff,
    2329px 2238px #fff, 725px 1252px #fff, 364px 1699px #fff, 409px 418px #fff,
    1563px 2049px #fff, 2379px 436px #fff, 2032px 2284px #fff,
    1967px 1740px #fff, 539px 1484px #fff, 2520px 1731px #fff,
    2253px 2191px #fff, 1322px 1006px #fff, 1767px 2010px #fff,
    606px 2048px #fff, 2403px 2408px #fff, 2078px 1297px #fff, 843px 834px #fff,
    671px 953px #fff, 1584px 1226px #fff, 1330px 2390px #fff, 801px 596px #fff,
    689px 1402px #fff, 1437px 590px #fff, 562px 2248px #fff, 2210px 1132px #fff,
    641px 641px #fff, 153px 523px #fff, 672px 789px #fff, 763px 2260px #fff,
    2299px 28px #fff, 1460px 1613px #fff, 830px 1255px #fff, 1117px 2555px #fff,
    857px 770px #fff, 1049px 663px #fff, 1323px 924px #fff, 1646px 1731px #fff,
    961px 2366px #fff, 167px 1555px #fff, 458px 144px #fff, 1095px 910px #fff,
    2309px 724px #fff, 1905px 21px #fff, 689px 90px #fff, 579px 1030px #fff,
    2066px 2062px #fff, 1707px 1590px #fff, 1781px 2052px #fff,
    829px 2398px #fff, 625px 832px #fff, 1994px 2049px #fff, 1324px 1582px #fff,
    893px 1172px #fff, 2080px 2302px #fff, 1370px 1892px #fff, 926px 1719px #fff,
    696px 1334px #fff, 1963px 2025px #fff, 555px 1866px #fff, 761px 2175px #fff,
    525px 2209px #fff, 434px 1914px #fff, 261px 481px #fff, 1625px 2505px #fff,
    2206px 1795px #fff, 1412px 777px #fff, 2532px 1122px #fff,
    1799px 1039px #fff, 1861px 1145px #fff, 1397px 1703px #fff,
    1201px 1187px #fff, 391px 1477px #fff, 117px 2160px #fff, 731px 2032px #fff,
    646px 186px #fff, 2088px 538px #fff, 404px 1589px #fff, 573px 1607px #fff,
    49px 99px #fff, 805px 875px #fff, 796px 495px #fff, 1673px 896px #fff,
    459px 2106px #fff, 1774px 405px #fff, 471px 1776px #fff, 1966px 955px #fff,
    2366px 210px #fff, 1113px 1825px #fff, 1069px 2070px #fff,
    1731px 2532px #fff, 2232px 1665px #fff, 2016px 334px #fff, 2058px 269px #fff,
    1507px 541px #fff, 1426px 2262px #fff, 2016px 1590px #fff, 1217px 352px #fff,
    1890px 2156px #fff, 1125px 2205px #fff, 2418px 1173px #fff, 521px 643px #fff,
    92px 212px #fff, 431px 1590px #fff, 1278px 581px #fff, 2534px 2066px #fff,
    1702px 964px #fff, 199px 1841px #fff, 1170px 1266px #fff, 1357px 324px #fff,
    975px 474px #fff, 2372px 1276px #fff, 686px 1599px #fff, 2517px 1155px #fff,
    904px 1844px #fff, 1227px 964px #fff, 1190px 962px #fff, 343px 20px #fff,
    956px 1363px #fff;
  box-shadow: 0 0 #fff, 59px 1384px #fff, 1167px 2088px #fff, 471px 1238px #fff,
    1696px 220px #fff, 1097px 215px #fff, 253px 415px #fff, 1023px 700px #fff,
    1145px 1587px #fff, 2502px 1354px #fff, 630px 1362px #fff,
    1888px 2201px #fff, 1822px 308px #fff, 2223px 928px #fff, 1233px 1816px #fff,
    1367px 2139px #fff, 597px 2189px #fff, 1530px 929px #fff, 752px 1781px #fff,
    2467px 1193px #fff, 787px 801px #fff, 2447px 581px #fff, 1417px 1633px #fff,
    2537px 2472px #fff, 2387px 2344px #fff, 2366px 381px #fff,
    2405px 2139px #fff, 1523px 1236px #fff, 2171px 1242px #fff,
    2384px 2263px #fff, 2436px 1304px #fff, 1295px 2238px #fff, 463px 451px #fff,
    1509px 1022px #fff, 1489px 951px #fff, 1319px 1471px #fff,
    2528px 2550px #fff, 2161px 2429px #fff, 1734px 316px #fff, 306px 854px #fff,
    2002px 2356px #fff, 1412px 2382px #fff, 2124px 2486px #fff, 808px 223px #fff,
    498px 2503px #fff, 571px 2190px #fff, 1811px 2503px #fff, 31px 16px #fff,
    412px 466px #fff, 1181px 1833px #fff, 844px 4px #fff, 1695px 524px #fff,
    951px 1848px #fff, 1264px 35px #fff, 1461px 1619px #fff, 1608px 1909px #fff,
    1929px 115px #fff, 1882px 886px #fff, 440px 1905px #fff, 573px 1919px #fff,
    287px 2540px #fff, 2031px 1172px #fff, 851px 894px #fff, 2403px 1960px #fff,
    1508px 967px #fff, 666px 360px #fff, 2126px 2386px #fff, 1945px 1418px #fff,
    813px 2359px #fff, 124px 542px #fff, 206px 581px #fff, 1639px 369px #fff,
    2453px 1188px #fff, 713px 595px #fff, 731px 1870px #fff, 485px 239px #fff,
    2056px 2497px #fff, 49px 734px #fff, 1730px 2214px #fff, 1913px 121px #fff,
    2040px 450px #fff, 1290px 2025px #fff, 651px 2535px #fff, 1657px 1124px #fff,
    1484px 1998px #fff, 50px 22px #fff, 1779px 2423px #fff, 2026px 2353px #fff,
    2407px 1364px #fff, 1152px 498px #fff, 382px 1274px #fff, 415px 701px #fff,
    2364px 18px #fff, 805px 1502px #fff, 601px 1702px #fff, 50px 1408px #fff,
    1220px 1092px #fff, 1392px 1740px #fff, 808px 1466px #fff, 1996px 429px #fff,
    827px 1529px #fff, 111px 1899px #fff, 709px 616px #fff, 2423px 1984px #fff,
    1959px 2507px #fff, 842px 578px #fff, 35px 1310px #fff, 2033px 287px #fff,
    2076px 1157px #fff, 1295px 1199px #fff, 798px 1106px #fff, 2022px 670px #fff,
    1614px 1150px #fff, 1152px 1094px #fff, 1831px 1681px #fff,
    621px 1182px #fff, 1094px 2190px #fff, 1286px 1410px #fff, 516px 104px #fff,
    1847px 818px #fff, 1618px 965px #fff, 290px 524px #fff, 204px 1372px #fff,
    1001px 2366px #fff, 373px 1750px #fff, 2288px 371px #fff, 411px 652px #fff,
    2200px 1338px #fff, 862px 819px #fff, 1449px 2291px #fff, 1936px 72px #fff,
    1345px 1176px #fff, 2425px 700px #fff, 1967px 119px #fff, 2083px 1619px #fff,
    823px 608px #fff, 1319px 1485px #fff, 411px 2285px #fff, 1069px 1297px #fff,
    1618px 1658px #fff, 1636px 1676px #fff, 1824px 378px #fff, 1528px 693px #fff,
    163px 367px #fff, 379px 732px #fff, 678px 1179px #fff, 2481px 844px #fff,
    878px 1672px #fff, 778px 725px #fff, 661px 1635px #fff, 15px 761px #fff,
    383px 1552px #fff, 1827px 2067px #fff, 1239px 304px #fff, 1101px 33px #fff,
    1972px 2130px #fff, 1042px 2391px #fff, 1354px 795px #fff, 30px 1960px #fff,
    2290px 686px #fff, 1006px 1557px #fff, 441px 1155px #fff, 1749px 951px #fff,
    2343px 238px #fff, 949px 142px #fff, 2423px 2285px #fff, 1484px 2077px #fff,
    913px 2232px #fff, 358px 2235px #fff, 630px 154px #fff, 1792px 2492px #fff,
    999px 2381px #fff, 1722px 2180px #fff, 454px 736px #fff, 2250px 20px #fff,
    833px 572px #fff, 371px 188px #fff, 2157px 1659px #fff, 2492px 1808px #fff,
    2391px 1003px #fff, 2061px 1398px #fff, 238px 2041px #fff, 1865px 313px #fff,
    675px 318px #fff, 1989px 1710px #fff, 2207px 702px #fff, 2510px 323px #fff,
    1946px 1569px #fff, 43px 1025px #fff, 88px 247px #fff, 1640px 548px #fff,
    858px 1057px #fff, 602px 569px #fff, 1444px 1195px #fff, 1285px 238px #fff,
    1432px 1773px #fff, 2549px 1269px #fff, 410px 1230px #fff, 122px 1340px #fff,
    2532px 1160px #fff, 1192px 2449px #fff, 1558px 1782px #fff,
    241px 1717px #fff, 234px 2551px #fff, 2004px 1361px #fff, 1945px 2201px #fff,
    1861px 9px #fff, 623px 826px #fff, 2061px 1819px #fff, 1089px 1867px #fff,
    2048px 2336px #fff, 2105px 1834px #fff, 519px 1693px #fff, 925px 70px #fff,
    1175px 1512px #fff, 1685px 1561px #fff, 1694px 2560px #fff,
    2480px 1313px #fff, 1003px 1504px #fff, 2017px 2261px #fff, 824px 605px #fff,
    104px 2313px #fff, 1338px 2491px #fff, 2339px 2030px #fff, 208px 675px #fff,
    103px 2340px #fff, 1914px 2121px #fff, 746px 811px #fff, 1811px 1220px #fff,
    1210px 1037px #fff, 1532px 1044px #fff, 1720px 1817px #fff,
    2440px 2126px #fff, 1722px 203px #fff, 39px 358px #fff, 981px 1921px #fff,
    2538px 1618px #fff, 125px 2319px #fff, 744px 1899px #fff, 2230px 480px #fff,
    643px 2278px #fff, 149px 1264px #fff, 1298px 291px #fff, 2252px 291px #fff,
    616px 2090px #fff, 1633px 64px #fff, 2116px 2548px #fff, 389px 1576px #fff,
    1227px 2520px #fff, 2031px 1221px #fff, 775px 83px #fff, 977px 1629px #fff,
    959px 2416px #fff, 438px 2342px #fff, 2144px 1911px #fff, 2199px 2343px #fff,
    1837px 1799px #fff, 1011px 1833px #fff, 1645px 1450px #fff,
    948px 1827px #fff, 2458px 868px #fff, 475px 1625px #fff, 1149px 649px #fff,
    1771px 1874px #fff, 1801px 67px #fff, 772px 1741px #fff, 1003px 48px #fff,
    869px 813px #fff, 2428px 1812px #fff, 1461px 433px #fff, 947px 607px #fff,
    1438px 132px #fff, 1070px 1469px #fff, 2090px 547px #fff, 2500px 1856px #fff,
    1489px 1688px #fff, 1840px 1602px #fff, 693px 685px #fff, 1159px 2497px #fff,
    1694px 2289px #fff, 21px 992px #fff, 2285px 1305px #fff, 1061px 1073px #fff,
    2335px 999px #fff, 2288px 249px #fff, 1661px 2540px #fff, 2263px 2099px #fff,
    1836px 386px #fff, 1559px 1131px #fff, 743px 2017px #fff, 17px 233px #fff,
    2390px 1414px #fff, 835px 1421px #fff, 696px 2237px #fff, 2454px 687px #fff,
    1442px 1793px #fff, 1175px 1180px #fff, 1315px 349px #fff,
    2033px 1441px #fff, 728px 976px #fff, 1347px 1293px #fff, 1295px 2356px #fff,
    400px 344px #fff, 890px 718px #fff, 581px 2050px #fff, 1858px 2351px #fff,
    2436px 1097px #fff, 1072px 2513px #fff, 2467px 951px #fff, 321px 1288px #fff,
    426px 2128px #fff, 1631px 799px #fff, 1107px 1546px #fff, 244px 2314px #fff,
    2247px 2333px #fff, 2407px 906px #fff, 1821px 444px #fff, 93px 34px #fff,
    621px 2297px #fff, 782px 1309px #fff, 2324px 1776px #fff, 834px 230px #fff,
    1023px 1731px #fff, 2334px 2267px #fff, 1329px 2050px #fff,
    1234px 1580px #fff, 2305px 2316px #fff, 440px 199px #fff, 460px 2475px #fff,
    1602px 733px #fff, 1574px 121px #fff, 537px 1645px #fff, 1723px 1631px #fff,
    1862px 306px #fff, 1288px 261px #fff, 1163px 1806px #fff, 1175px 346px #fff,
    568px 1507px #fff, 543px 2202px #fff, 2339px 999px #fff, 1877px 2164px #fff,
    205px 2257px #fff, 2510px 271px #fff, 848px 655px #fff, 536px 463px #fff,
    1577px 1425px #fff, 2550px 1023px #fff, 1467px 972px #fff, 1550px 844px #fff,
    1297px 869px #fff, 2207px 1043px #fff, 2282px 104px #fff, 203px 39px #fff,
    916px 785px #fff, 2439px 2238px #fff, 1780px 486px #fff, 919px 287px #fff,
    226px 1948px #fff, 918px 1644px #fff, 998px 507px #fff, 1268px 1073px #fff,
    935px 500px #fff, 1505px 300px #fff, 1068px 58px #fff, 2442px 312px #fff,
    223px 1849px #fff, 1251px 991px #fff, 1186px 472px #fff, 2110px 457px #fff,
    1027px 1331px #fff, 248px 913px #fff, 2157px 291px #fff, 498px 4px #fff,
    95px 2538px #fff, 911px 2281px #fff, 69px 726px #fff, 18px 1451px #fff,
    2194px 1883px #fff, 1493px 1410px #fff, 1969px 964px #fff,
    1131px 1497px #fff, 927px 1677px #fff, 2330px 1887px #fff,
    1942px 2338px #fff, 2222px 1135px #fff, 327px 692px #fff, 2174px 545px #fff,
    2535px 663px #fff, 921px 1570px #fff, 866px 849px #fff, 1803px 769px #fff,
    1008px 196px #fff, 439px 918px #fff, 2143px 848px #fff, 1752px 1431px #fff,
    2189px 160px #fff, 423px 659px #fff, 1733px 1856px #fff, 1499px 1435px #fff,
    486px 933px #fff, 329px 908px #fff, 789px 311px #fff, 2030px 395px #fff,
    1659px 1401px #fff, 967px 2287px #fff, 2404px 443px #fff, 1506px 575px #fff,
    1241px 1777px #fff, 736px 624px #fff, 1531px 308px #fff, 2022px 1079px #fff,
    2273px 1114px #fff, 51px 1349px #fff, 987px 225px #fff, 189px 1518px #fff,
    252px 2478px #fff, 2213px 2232px #fff, 1056px 1134px #fff,
    1164px 2330px #fff, 686px 18px #fff, 1839px 1513px #fff, 690px 2176px #fff,
    1464px 2197px #fff, 1185px 958px #fff, 1158px 1477px #fff,
    1176px 1474px #fff, 1544px 592px #fff, 1599px 2521px #fff, 1827px 152px #fff,
    441px 1694px #fff, 379px 1250px #fff, 2074px 1076px #fff, 737px 1656px #fff,
    983px 109px #fff, 1749px 149px #fff, 1980px 118px #fff, 256px 1261px #fff,
    41px 1045px #fff, 1104px 451px #fff, 59px 737px #fff, 111px 1239px #fff,
    1016px 1289px #fff, 121px 1290px #fff, 1353px 1835px #fff, 869px 1256px #fff,
    90px 1318px #fff, 2106px 1297px #fff, 597px 1532px #fff, 1815px 646px #fff,
    1454px 2314px #fff, 645px 393px #fff, 2238px 51px #fff, 2514px 1773px #fff,
    952px 287px #fff, 254px 942px #fff, 1698px 751px #fff, 637px 2313px #fff,
    668px 2523px #fff, 1429px 1234px #fff, 1845px 635px #fff, 1029px 2271px #fff,
    1313px 20px #fff, 2316px 621px #fff, 2042px 499px #fff, 98px 2526px #fff,
    1351px 1173px #fff, 1246px 1953px #fff, 1054px 1413px #fff,
    1156px 2184px #fff, 101px 2548px #fff, 898px 2474px #fff, 214px 1899px #fff,
    785px 970px #fff, 2505px 1927px #fff, 1591px 1341px #fff, 2193px 1664px #fff,
    2296px 1416px #fff, 2160px 2512px #fff, 2222px 1901px #fff,
    2515px 1695px #fff, 562px 919px #fff, 1846px 2112px #fff, 2118px 2112px #fff,
    2329px 2238px #fff, 725px 1252px #fff, 364px 1699px #fff, 409px 418px #fff,
    1563px 2049px #fff, 2379px 436px #fff, 2032px 2284px #fff,
    1967px 1740px #fff, 539px 1484px #fff, 2520px 1731px #fff,
    2253px 2191px #fff, 1322px 1006px #fff, 1767px 2010px #fff,
    606px 2048px #fff, 2403px 2408px #fff, 2078px 1297px #fff, 843px 834px #fff,
    671px 953px #fff, 1584px 1226px #fff, 1330px 2390px #fff, 801px 596px #fff,
    689px 1402px #fff, 1437px 590px #fff, 562px 2248px #fff, 2210px 1132px #fff,
    641px 641px #fff, 153px 523px #fff, 672px 789px #fff, 763px 2260px #fff,
    2299px 28px #fff, 1460px 1613px #fff, 830px 1255px #fff, 1117px 2555px #fff,
    857px 770px #fff, 1049px 663px #fff, 1323px 924px #fff, 1646px 1731px #fff,
    961px 2366px #fff, 167px 1555px #fff, 458px 144px #fff, 1095px 910px #fff,
    2309px 724px #fff, 1905px 21px #fff, 689px 90px #fff, 579px 1030px #fff,
    2066px 2062px #fff, 1707px 1590px #fff, 1781px 2052px #fff,
    829px 2398px #fff, 625px 832px #fff, 1994px 2049px #fff, 1324px 1582px #fff,
    893px 1172px #fff, 2080px 2302px #fff, 1370px 1892px #fff, 926px 1719px #fff,
    696px 1334px #fff, 1963px 2025px #fff, 555px 1866px #fff, 761px 2175px #fff,
    525px 2209px #fff, 434px 1914px #fff, 261px 481px #fff, 1625px 2505px #fff,
    2206px 1795px #fff, 1412px 777px #fff, 2532px 1122px #fff,
    1799px 1039px #fff, 1861px 1145px #fff, 1397px 1703px #fff,
    1201px 1187px #fff, 391px 1477px #fff, 117px 2160px #fff, 731px 2032px #fff,
    646px 186px #fff, 2088px 538px #fff, 404px 1589px #fff, 573px 1607px #fff,
    49px 99px #fff, 805px 875px #fff, 796px 495px #fff, 1673px 896px #fff,
    459px 2106px #fff, 1774px 405px #fff, 471px 1776px #fff, 1966px 955px #fff,
    2366px 210px #fff, 1113px 1825px #fff, 1069px 2070px #fff,
    1731px 2532px #fff, 2232px 1665px #fff, 2016px 334px #fff, 2058px 269px #fff,
    1507px 541px #fff, 1426px 2262px #fff, 2016px 1590px #fff, 1217px 352px #fff,
    1890px 2156px #fff, 1125px 2205px #fff, 2418px 1173px #fff, 521px 643px #fff,
    92px 212px #fff, 431px 1590px #fff, 1278px 581px #fff, 2534px 2066px #fff,
    1702px 964px #fff, 199px 1841px #fff, 1170px 1266px #fff, 1357px 324px #fff,
    975px 474px #fff, 2372px 1276px #fff, 686px 1599px #fff, 2517px 1155px #fff,
    904px 1844px #fff, 1227px 964px #fff, 1190px 962px #fff, 343px 20px #fff,
    956px 1363px #fff;
}
#starsLightMode2 {
  z-index: 1;

  -webkit-animation: animParticle 120s linear infinite;
  animation: animParticle 120s linear infinite;
  -webkit-box-shadow: 0 0 #fff, 1360px 1208px #fff, 2248px 1745px #fff,
    1534px 2351px #fff, 2047px 2203px #fff, 480px 1109px #fff, 609px 955px #fff,
    1888px 1198px #fff, 1475px 269px #fff, 1661px 802px #fff, 1939px 2527px #fff,
    1181px 192px #fff, 1731px 1082px #fff, 968px 910px #fff, 1583px 2109px #fff,
    1164px 949px #fff, 1614px 1130px #fff, 2506px 675px #fff, 1332px 1415px #fff,
    175px 700px #fff, 1055px 713px #fff, 1947px 312px #fff, 290px 2231px #fff,
    1473px 2064px #fff, 609px 1158px #fff, 1874px 481px #fff, 2385px 2441px #fff,
    452px 235px #fff, 2388px 2438px #fff, 1114px 312px #fff, 1945px 2204px #fff,
    1918px 1972px #fff, 2460px 280px #fff, 2041px 782px #fff, 854px 1735px #fff,
    2330px 716px #fff, 2495px 593px #fff, 1669px 1617px #fff, 2199px 452px #fff,
    2450px 2013px #fff, 2232px 1685px #fff, 2154px 858px #fff,
    2391px 1903px #fff, 1866px 1171px #fff, 963px 2075px #fff, 1480px 548px #fff,
    1736px 1798px #fff, 1647px 195px #fff, 2022px 131px #fff, 259px 2334px #fff,
    701px 519px #fff, 1116px 717px #fff, 287px 1700px #fff, 495px 1241px #fff,
    2321px 2299px #fff, 1679px 1955px #fff, 222px 2063px #fff,
    2222px 1293px #fff, 1886px 641px #fff, 83px 1338px #fff, 208px 2235px #fff,
    2135px 1484px #fff, 1818px 2548px #fff, 293px 660px #fff, 2172px 608px #fff,
    1390px 317px #fff, 2375px 509px #fff, 209px 1962px #fff, 2128px 1074px #fff,
    2020px 1673px #fff, 136px 1938px #fff, 350px 1190px #fff, 505px 2295px #fff,
    951px 444px #fff, 903px 268px #fff, 311px 18px #fff, 135px 2493px #fff,
    2523px 1178px #fff, 431px 1785px #fff, 1174px 453px #fff, 2514px 990px #fff,
    1629px 850px #fff, 19px 2105px #fff, 640px 1412px #fff, 1149px 664px #fff,
    943px 1353px #fff, 2424px 1337px #fff, 2120px 550px #fff, 747px 1298px #fff,
    419px 126px #fff, 663px 453px #fff, 2177px 609px #fff, 427px 1942px #fff,
    1921px 40px #fff, 1012px 924px #fff, 178px 2087px #fff, 2191px 2322px #fff,
    843px 1096px #fff, 1930px 2224px #fff, 2303px 756px #fff, 777px 111px #fff,
    1090px 2223px #fff, 356px 445px #fff, 2178px 2201px #fff, 579px 1961px #fff,
    2423px 110px #fff, 1133px 1861px #fff, 67px 769px #fff, 597px 1723px #fff,
    586px 390px #fff, 2068px 240px #fff, 56px 1874px #fff, 2553px 935px #fff,
    1073px 1985px #fff, 2369px 1933px #fff, 687px 222px #fff, 2353px 2550px #fff,
    575px 2467px #fff, 617px 1267px #fff, 178px 1534px #fff, 1263px 2180px #fff,
    867px 2115px #fff, 1003px 495px #fff, 674px 1332px #fff, 868px 651px #fff,
    417px 2223px #fff, 2289px 1753px #fff, 994px 447px #fff, 2485px 1692px #fff,
    975px 2377px #fff, 1489px 1016px #fff, 1645px 548px #fff, 2046px 512px #fff,
    661px 232px #fff, 2131px 573px #fff, 2081px 399px #fff, 2508px 1683px #fff,
    2383px 1749px #fff, 1457px 1871px #fff, 195px 2504px #fff, 504px 2410px #fff,
    1322px 1063px #fff, 155px 491px #fff, 1503px 919px #fff, 2138px 2280px #fff,
    1303px 2113px #fff, 2547px 585px #fff, 334px 1363px #fff, 2340px 1482px #fff,
    277px 633px #fff, 1905px 877px #fff, 2207px 147px #fff, 905px 719px #fff,
    289px 1657px #fff, 2366px 2031px #fff, 1327px 662px #fff, 1015px 942px #fff,
    519px 779px #fff, 1779px 798px #fff, 1872px 1668px #fff, 8px 1838px #fff,
    155px 34px #fff, 1494px 1759px #fff, 1276px 1181px #fff, 2461px 891px #fff,
    430px 2223px #fff, 1375px 2062px #fff, 1909px 2077px #fff, 377px 2377px #fff,
    1651px 1632px #fff, 406px 517px #fff, 862px 1180px #fff, 1771px 1985px #fff,
    210px 1909px #fff, 1486px 1395px #fff, 1835px 347px #fff, 1438px 1161px #fff,
    2461px 1898px #fff, 1161px 1793px #fff, 2163px 1553px #fff,
    352px 1308px #fff, 293px 564px #fff, 2320px 122px #fff, 1169px 956px #fff,
    1776px 1064px #fff, 718px 1074px #fff, 726px 2162px #fff, 778px 179px #fff,
    1745px 1070px #fff, 2145px 2413px #fff, 1879px 2309px #fff,
    2534px 2115px #fff, 429px 501px #fff, 828px 1832px #fff, 2343px 1618px #fff,
    1085px 1544px #fff, 1990px 2238px #fff, 725px 1677px #fff, 174px 454px #fff,
    1713px 1375px #fff, 1809px 1905px #fff;
  box-shadow: 0 0 #fff, 1360px 1208px #fff, 2248px 1745px #fff,
    1534px 2351px #fff, 2047px 2203px #fff, 480px 1109px #fff, 609px 955px #fff,
    1888px 1198px #fff, 1475px 269px #fff, 1661px 802px #fff, 1939px 2527px #fff,
    1181px 192px #fff, 1731px 1082px #fff, 968px 910px #fff, 1583px 2109px #fff,
    1164px 949px #fff, 1614px 1130px #fff, 2506px 675px #fff, 1332px 1415px #fff,
    175px 700px #fff, 1055px 713px #fff, 1947px 312px #fff, 290px 2231px #fff,
    1473px 2064px #fff, 609px 1158px #fff, 1874px 481px #fff, 2385px 2441px #fff,
    452px 235px #fff, 2388px 2438px #fff, 1114px 312px #fff, 1945px 2204px #fff,
    1918px 1972px #fff, 2460px 280px #fff, 2041px 782px #fff, 854px 1735px #fff,
    2330px 716px #fff, 2495px 593px #fff, 1669px 1617px #fff, 2199px 452px #fff,
    2450px 2013px #fff, 2232px 1685px #fff, 2154px 858px #fff,
    2391px 1903px #fff, 1866px 1171px #fff, 963px 2075px #fff, 1480px 548px #fff,
    1736px 1798px #fff, 1647px 195px #fff, 2022px 131px #fff, 259px 2334px #fff,
    701px 519px #fff, 1116px 717px #fff, 287px 1700px #fff, 495px 1241px #fff,
    2321px 2299px #fff, 1679px 1955px #fff, 222px 2063px #fff,
    2222px 1293px #fff, 1886px 641px #fff, 83px 1338px #fff, 208px 2235px #fff,
    2135px 1484px #fff, 1818px 2548px #fff, 293px 660px #fff, 2172px 608px #fff,
    1390px 317px #fff, 2375px 509px #fff, 209px 1962px #fff, 2128px 1074px #fff,
    2020px 1673px #fff, 136px 1938px #fff, 350px 1190px #fff, 505px 2295px #fff,
    951px 444px #fff, 903px 268px #fff, 311px 18px #fff, 135px 2493px #fff,
    2523px 1178px #fff, 431px 1785px #fff, 1174px 453px #fff, 2514px 990px #fff,
    1629px 850px #fff, 19px 2105px #fff, 640px 1412px #fff, 1149px 664px #fff,
    943px 1353px #fff, 2424px 1337px #fff, 2120px 550px #fff, 747px 1298px #fff,
    419px 126px #fff, 663px 453px #fff, 2177px 609px #fff, 427px 1942px #fff,
    1921px 40px #fff, 1012px 924px #fff, 178px 2087px #fff, 2191px 2322px #fff,
    843px 1096px #fff, 1930px 2224px #fff, 2303px 756px #fff, 777px 111px #fff,
    1090px 2223px #fff, 356px 445px #fff, 2178px 2201px #fff, 579px 1961px #fff,
    2423px 110px #fff, 1133px 1861px #fff, 67px 769px #fff, 597px 1723px #fff,
    586px 390px #fff, 2068px 240px #fff, 56px 1874px #fff, 2553px 935px #fff,
    1073px 1985px #fff, 2369px 1933px #fff, 687px 222px #fff, 2353px 2550px #fff,
    575px 2467px #fff, 617px 1267px #fff, 178px 1534px #fff, 1263px 2180px #fff,
    867px 2115px #fff, 1003px 495px #fff, 674px 1332px #fff, 868px 651px #fff,
    417px 2223px #fff, 2289px 1753px #fff, 994px 447px #fff, 2485px 1692px #fff,
    975px 2377px #fff, 1489px 1016px #fff, 1645px 548px #fff, 2046px 512px #fff,
    661px 232px #fff, 2131px 573px #fff, 2081px 399px #fff, 2508px 1683px #fff,
    2383px 1749px #fff, 1457px 1871px #fff, 195px 2504px #fff, 504px 2410px #fff,
    1322px 1063px #fff, 155px 491px #fff, 1503px 919px #fff, 2138px 2280px #fff,
    1303px 2113px #fff, 2547px 585px #fff, 334px 1363px #fff, 2340px 1482px #fff,
    277px 633px #fff, 1905px 877px #fff, 2207px 147px #fff, 905px 719px #fff,
    289px 1657px #fff, 2366px 2031px #fff, 1327px 662px #fff, 1015px 942px #fff,
    519px 779px #fff, 1779px 798px #fff, 1872px 1668px #fff, 8px 1838px #fff,
    155px 34px #fff, 1494px 1759px #fff, 1276px 1181px #fff, 2461px 891px #fff,
    430px 2223px #fff, 1375px 2062px #fff, 1909px 2077px #fff, 377px 2377px #fff,
    1651px 1632px #fff, 406px 517px #fff, 862px 1180px #fff, 1771px 1985px #fff,
    210px 1909px #fff, 1486px 1395px #fff, 1835px 347px #fff, 1438px 1161px #fff,
    2461px 1898px #fff, 1161px 1793px #fff, 2163px 1553px #fff,
    352px 1308px #fff, 293px 564px #fff, 2320px 122px #fff, 1169px 956px #fff,
    1776px 1064px #fff, 718px 1074px #fff, 726px 2162px #fff, 778px 179px #fff,
    1745px 1070px #fff, 2145px 2413px #fff, 1879px 2309px #fff,
    2534px 2115px #fff, 429px 501px #fff, 828px 1832px #fff, 2343px 1618px #fff,
    1085px 1544px #fff, 1990px 2238px #fff, 725px 1677px #fff, 174px 454px #fff,
    1713px 1375px #fff, 1809px 1905px #fff;
  height: 1px;
  width: 1px;
}
#starsLightMode2:after {
  z-index: 1;

  -webkit-box-shadow: 0 0 #fff, 103px 2064px #fff, 2080px 1653px #fff,
    2490px 1265px #fff, 387px 2545px #fff, 1638px 2069px #fff, 814px 836px #fff,
    1817px 1376px #fff, 2532px 1336px #fff, 1337px 516px #fff,
    1673px 1612px #fff, 2256px 478px #fff, 456px 764px #fff, 146px 2503px #fff,
    603px 731px #fff, 2032px 141px #fff, 660px 249px #fff, 247px 1515px #fff,
    2518px 842px #fff, 132px 671px #fff, 1741px 1929px #fff, 320px 1965px #fff,
    968px 2525px #fff, 1344px 348px #fff, 698px 1918px #fff, 861px 154px #fff,
    514px 943px #fff, 1260px 389px #fff, 2438px 1706px #fff, 1510px 2238px #fff,
    54px 992px #fff, 2233px 900px #fff, 2530px 816px #fff, 866px 1751px #fff,
    2109px 232px #fff, 1478px 2559px #fff, 250px 1991px #fff, 267px 926px #fff,
    1054px 1362px #fff, 1837px 2417px #fff, 2270px 1484px #fff,
    1062px 1725px #fff, 915px 738px #fff, 600px 660px #fff, 1774px 467px #fff,
    1959px 436px #fff, 1966px 1002px #fff, 1574px 348px #fff, 1833px 1206px #fff,
    570px 634px #fff, 2368px 1008px #fff, 1224px 1985px #fff, 1607px 391px #fff,
    890px 2477px #fff, 1861px 2421px #fff, 1828px 1275px #fff, 1801px 5px #fff,
    2121px 2297px #fff, 2437px 1979px #fff, 1677px 1952px #fff, 67px 1333px #fff,
    2100px 114px #fff, 1px 2054px #fff, 1417px 1351px #fff, 951px 506px #fff,
    1613px 1857px #fff, 679px 1327px #fff, 1068px 1025px #fff, 2299px 908px #fff,
    832px 2397px #fff, 1668px 511px #fff, 1387px 538px #fff, 1785px 1961px #fff,
    2533px 496px #fff, 2007px 1510px #fff, 623px 1608px #fff, 2085px 801px #fff,
    703px 2462px #fff, 2507px 141px #fff, 1335px 1514px #fff, 1428px 930px #fff,
    1106px 582px #fff, 2547px 1042px #fff, 822px 2512px #fff, 1665px 393px #fff,
    1437px 1157px #fff, 2256px 1913px #fff, 2493px 348px #fff,
    1800px 1898px #fff, 593px 1847px #fff, 1648px 867px #fff, 2441px 294px #fff,
    1131px 489px #fff, 2367px 1185px #fff, 1033px 2068px #fff,
    2416px 1026px #fff, 2341px 2042px #fff, 72px 838px #fff, 2149px 1962px #fff,
    968px 1697px #fff, 2243px 539px #fff, 220px 802px #fff, 1057px 1941px #fff,
    2421px 1383px #fff, 41px 1252px #fff, 1368px 1937px #fff, 2414px 2384px #fff,
    2553px 1362px #fff, 2159px 1082px #fff, 1362px 879px #fff,
    1571px 1080px #fff, 2459px 991px #fff, 73px 520px #fff, 2175px 867px #fff,
    528px 2027px #fff, 398px 580px #fff, 363px 855px #fff, 2217px 1658px #fff,
    2065px 376px #fff, 1189px 1563px #fff, 1112px 981px #fff, 361px 1799px #fff,
    1304px 1264px #fff, 1392px 1004px #fff, 2362px 2061px #fff,
    1223px 778px #fff, 1413px 2535px #fff, 380px 2483px #fff, 2012px 964px #fff,
    2557px 893px #fff, 2172px 2344px #fff, 369px 1884px #fff, 681px 1326px #fff,
    354px 1928px #fff, 1089px 777px #fff, 2256px 1234px #fff, 2000px 700px #fff,
    1134px 1837px #fff, 1955px 235px #fff, 913px 2339px #fff, 1909px 601px #fff,
    2134px 1019px #fff, 1188px 1954px #fff, 188px 1590px #fff, 1356px 872px #fff,
    1964px 151px #fff, 424px 1408px #fff, 1247px 1062px #fff, 2311px 905px #fff,
    1225px 1360px #fff, 1124px 902px #fff, 268px 1846px #fff, 36px 1259px #fff,
    302px 944px #fff, 2438px 1659px #fff, 1985px 285px #fff, 916px 2486px #fff,
    1062px 1443px #fff, 1098px 732px #fff, 2094px 556px #fff, 1575px 826px #fff,
    1518px 1685px #fff, 127px 829px #fff, 995px 1005px #fff, 490px 1032px #fff,
    2266px 442px #fff, 1636px 599px #fff, 313px 2555px #fff, 2096px 1726px #fff,
    1948px 2096px #fff, 1268px 2205px #fff, 620px 1141px #fff, 1077px 700px #fff,
    1756px 1799px #fff, 2503px 1474px #fff, 1999px 556px #fff, 2132px 146px #fff,
    1683px 1596px #fff, 1140px 231px #fff, 416px 1184px #fff, 598px 571px #fff,
    938px 595px #fff, 1657px 2117px #fff, 1443px 1226px #fff, 1969px 2448px #fff,
    413px 2243px #fff, 790px 2032px #fff, 746px 51px #fff, 28px 828px #fff,
    393px 1508px #fff, 1385px 2050px #fff, 517px 1774px #fff, 2543px 1637px #fff,
    2036px 1990px #fff, 2549px 505px #fff, 860px 304px #fff, 963px 2070px #fff,
    2034px 1818px #fff, 199px 317px #fff, 851px 1728px #fff, 2058px 1613px #fff;
  box-shadow: 0 0 #fff, 103px 2064px #fff, 2080px 1653px #fff,
    2490px 1265px #fff, 387px 2545px #fff, 1638px 2069px #fff, 814px 836px #fff,
    1817px 1376px #fff, 2532px 1336px #fff, 1337px 516px #fff,
    1673px 1612px #fff, 2256px 478px #fff, 456px 764px #fff, 146px 2503px #fff,
    603px 731px #fff, 2032px 141px #fff, 660px 249px #fff, 247px 1515px #fff,
    2518px 842px #fff, 132px 671px #fff, 1741px 1929px #fff, 320px 1965px #fff,
    968px 2525px #fff, 1344px 348px #fff, 698px 1918px #fff, 861px 154px #fff,
    514px 943px #fff, 1260px 389px #fff, 2438px 1706px #fff, 1510px 2238px #fff,
    54px 992px #fff, 2233px 900px #fff, 2530px 816px #fff, 866px 1751px #fff,
    2109px 232px #fff, 1478px 2559px #fff, 250px 1991px #fff, 267px 926px #fff,
    1054px 1362px #fff, 1837px 2417px #fff, 2270px 1484px #fff,
    1062px 1725px #fff, 915px 738px #fff, 600px 660px #fff, 1774px 467px #fff,
    1959px 436px #fff, 1966px 1002px #fff, 1574px 348px #fff, 1833px 1206px #fff,
    570px 634px #fff, 2368px 1008px #fff, 1224px 1985px #fff, 1607px 391px #fff,
    890px 2477px #fff, 1861px 2421px #fff, 1828px 1275px #fff, 1801px 5px #fff,
    2121px 2297px #fff, 2437px 1979px #fff, 1677px 1952px #fff, 67px 1333px #fff,
    2100px 114px #fff, 1px 2054px #fff, 1417px 1351px #fff, 951px 506px #fff,
    1613px 1857px #fff, 679px 1327px #fff, 1068px 1025px #fff, 2299px 908px #fff,
    832px 2397px #fff, 1668px 511px #fff, 1387px 538px #fff, 1785px 1961px #fff,
    2533px 496px #fff, 2007px 1510px #fff, 623px 1608px #fff, 2085px 801px #fff,
    703px 2462px #fff, 2507px 141px #fff, 1335px 1514px #fff, 1428px 930px #fff,
    1106px 582px #fff, 2547px 1042px #fff, 822px 2512px #fff, 1665px 393px #fff,
    1437px 1157px #fff, 2256px 1913px #fff, 2493px 348px #fff,
    1800px 1898px #fff, 593px 1847px #fff, 1648px 867px #fff, 2441px 294px #fff,
    1131px 489px #fff, 2367px 1185px #fff, 1033px 2068px #fff,
    2416px 1026px #fff, 2341px 2042px #fff, 72px 838px #fff, 2149px 1962px #fff,
    968px 1697px #fff, 2243px 539px #fff, 220px 802px #fff, 1057px 1941px #fff,
    2421px 1383px #fff, 41px 1252px #fff, 1368px 1937px #fff, 2414px 2384px #fff,
    2553px 1362px #fff, 2159px 1082px #fff, 1362px 879px #fff,
    1571px 1080px #fff, 2459px 991px #fff, 73px 520px #fff, 2175px 867px #fff,
    528px 2027px #fff, 398px 580px #fff, 363px 855px #fff, 2217px 1658px #fff,
    2065px 376px #fff, 1189px 1563px #fff, 1112px 981px #fff, 361px 1799px #fff,
    1304px 1264px #fff, 1392px 1004px #fff, 2362px 2061px #fff,
    1223px 778px #fff, 1413px 2535px #fff, 380px 2483px #fff, 2012px 964px #fff,
    2557px 893px #fff, 2172px 2344px #fff, 369px 1884px #fff, 681px 1326px #fff,
    354px 1928px #fff, 1089px 777px #fff, 2256px 1234px #fff, 2000px 700px #fff,
    1134px 1837px #fff, 1955px 235px #fff, 913px 2339px #fff, 1909px 601px #fff,
    2134px 1019px #fff, 1188px 1954px #fff, 188px 1590px #fff, 1356px 872px #fff,
    1964px 151px #fff, 424px 1408px #fff, 1247px 1062px #fff, 2311px 905px #fff,
    1225px 1360px #fff, 1124px 902px #fff, 268px 1846px #fff, 36px 1259px #fff,
    302px 944px #fff, 2438px 1659px #fff, 1985px 285px #fff, 916px 2486px #fff,
    1062px 1443px #fff, 1098px 732px #fff, 2094px 556px #fff, 1575px 826px #fff,
    1518px 1685px #fff, 127px 829px #fff, 995px 1005px #fff, 490px 1032px #fff,
    2266px 442px #fff, 1636px 599px #fff, 313px 2555px #fff, 2096px 1726px #fff,
    1948px 2096px #fff, 1268px 2205px #fff, 620px 1141px #fff, 1077px 700px #fff,
    1756px 1799px #fff, 2503px 1474px #fff, 1999px 556px #fff, 2132px 146px #fff,
    1683px 1596px #fff, 1140px 231px #fff, 416px 1184px #fff, 598px 571px #fff,
    938px 595px #fff, 1657px 2117px #fff, 1443px 1226px #fff, 1969px 2448px #fff,
    413px 2243px #fff, 790px 2032px #fff, 746px 51px #fff, 28px 828px #fff,
    393px 1508px #fff, 1385px 2050px #fff, 517px 1774px #fff, 2543px 1637px #fff,
    2036px 1990px #fff, 2549px 505px #fff, 860px 304px #fff, 963px 2070px #fff,
    2034px 1818px #fff, 199px 317px #fff, 851px 1728px #fff, 2058px 1613px #fff;
  height: 1px;
  width: 1px;
}
#starsLightMode3 {
  z-index: 1;

  -webkit-animation: animParticle 180s linear infinite;
  animation: animParticle 180s linear infinite;
  -webkit-box-shadow: 0 0 #fff, 608px 1003px #fff, 1498px 218px #fff,
    693px 2484px #fff, 1072px 2267px #fff, 1397px 692px #fff, 1832px 38px #fff,
    1596px 1193px #fff, 1161px 76px #fff, 334px 1686px #fff, 1963px 812px #fff,
    1724px 1455px #fff, 2052px 12px #fff, 1031px 1812px #fff, 1806px 584px #fff,
    600px 159px #fff, 2289px 501px #fff, 1704px 1034px #fff, 2547px 2327px #fff,
    2335px 851px #fff, 898px 576px #fff, 383px 76px #fff, 2010px 18px #fff,
    1263px 715px #fff, 44px 81px #fff, 1630px 893px #fff, 1977px 1165px #fff,
    1680px 1516px #fff, 1430px 631px #fff, 1660px 50px #fff, 1164px 2419px #fff,
    1287px 1591px #fff, 1153px 1580px #fff, 1924px 29px #fff, 2312px 1199px #fff,
    942px 1641px #fff, 911px 442px #fff, 1043px 379px #fff, 865px 772px #fff,
    2204px 1382px #fff, 1100px 1222px #fff, 481px 2140px #fff,
    1414px 1738px #fff, 190px 957px #fff, 1473px 317px #fff, 2526px 2342px #fff,
    2212px 554px #fff, 2254px 809px #fff, 2016px 1451px #fff, 1601px 1667px #fff,
    838px 1202px #fff, 1649px 269px #fff, 1982px 1525px #fff, 2368px 1074px #fff,
    15px 219px #fff, 1212px 2099px #fff, 716px 308px #fff, 242px 498px #fff,
    2339px 1583px #fff, 1577px 2469px #fff, 883px 1747px #fff, 1693px 114px #fff,
    289px 2217px #fff, 2049px 2279px #fff, 1167px 1655px #fff, 662px 1284px #fff,
    2557px 1256px #fff, 1134px 2422px #fff, 822px 647px #fff, 1758px 1188px #fff,
    1088px 1329px #fff, 1969px 941px #fff, 2522px 2062px #fff, 457px 1065px #fff,
    430px 2437px #fff, 1069px 1085px #fff, 2108px 660px #fff, 649px 78px #fff,
    404px 395px #fff, 2275px 1981px #fff, 2226px 2163px #fff, 1855px 1538px #fff,
    1387px 646px #fff, 644px 1305px #fff, 799px 1143px #fff, 1809px 190px #fff,
    744px 1873px #fff, 1374px 821px #fff, 2128px 1646px #fff, 1162px 1297px #fff,
    1036px 111px #fff, 434px 498px #fff, 282px 1432px #fff, 12px 1444px #fff,
    354px 2130px #fff, 660px 1453px #fff, 52px 1076px #fff, 684px 1310px #fff,
    742px 944px #fff, 1800px 835px #fff, 1895px 188px #fff;
  box-shadow: 0 0 #fff, 608px 1003px #fff, 1498px 218px #fff, 693px 2484px #fff,
    1072px 2267px #fff, 1397px 692px #fff, 1832px 38px #fff, 1596px 1193px #fff,
    1161px 76px #fff, 334px 1686px #fff, 1963px 812px #fff, 1724px 1455px #fff,
    2052px 12px #fff, 1031px 1812px #fff, 1806px 584px #fff, 600px 159px #fff,
    2289px 501px #fff, 1704px 1034px #fff, 2547px 2327px #fff, 2335px 851px #fff,
    898px 576px #fff, 383px 76px #fff, 2010px 18px #fff, 1263px 715px #fff,
    44px 81px #fff, 1630px 893px #fff, 1977px 1165px #fff, 1680px 1516px #fff,
    1430px 631px #fff, 1660px 50px #fff, 1164px 2419px #fff, 1287px 1591px #fff,
    1153px 1580px #fff, 1924px 29px #fff, 2312px 1199px #fff, 942px 1641px #fff,
    911px 442px #fff, 1043px 379px #fff, 865px 772px #fff, 2204px 1382px #fff,
    1100px 1222px #fff, 481px 2140px #fff, 1414px 1738px #fff, 190px 957px #fff,
    1473px 317px #fff, 2526px 2342px #fff, 2212px 554px #fff, 2254px 809px #fff,
    2016px 1451px #fff, 1601px 1667px #fff, 838px 1202px #fff, 1649px 269px #fff,
    1982px 1525px #fff, 2368px 1074px #fff, 15px 219px #fff, 1212px 2099px #fff,
    716px 308px #fff, 242px 498px #fff, 2339px 1583px #fff, 1577px 2469px #fff,
    883px 1747px #fff, 1693px 114px #fff, 289px 2217px #fff, 2049px 2279px #fff,
    1167px 1655px #fff, 662px 1284px #fff, 2557px 1256px #fff,
    1134px 2422px #fff, 822px 647px #fff, 1758px 1188px #fff, 1088px 1329px #fff,
    1969px 941px #fff, 2522px 2062px #fff, 457px 1065px #fff, 430px 2437px #fff,
    1069px 1085px #fff, 2108px 660px #fff, 649px 78px #fff, 404px 395px #fff,
    2275px 1981px #fff, 2226px 2163px #fff, 1855px 1538px #fff,
    1387px 646px #fff, 644px 1305px #fff, 799px 1143px #fff, 1809px 190px #fff,
    744px 1873px #fff, 1374px 821px #fff, 2128px 1646px #fff, 1162px 1297px #fff,
    1036px 111px #fff, 434px 498px #fff, 282px 1432px #fff, 12px 1444px #fff,
    354px 2130px #fff, 660px 1453px #fff, 52px 1076px #fff, 684px 1310px #fff,
    742px 944px #fff, 1800px 835px #fff, 1895px 188px #fff;
  height: 3px;
  width: 3px;
}
#starsLightMode3:after {
  -webkit-box-shadow: 0 0 #fff, 2035px 1710px #fff, 2220px 12px #fff,
    2423px 308px #fff, 596px 1855px #fff, 600px 1667px #fff, 2086px 1525px #fff,
    743px 1557px #fff, 1037px 1455px #fff, 772px 2480px #fff, 537px 353px #fff,
    1930px 135px #fff, 1962px 1596px #fff, 1552px 1319px #fff, 1889px 134px #fff,
    550px 983px #fff, 1337px 2285px #fff, 361px 2039px #fff, 2379px 2171px #fff,
    2337px 999px #fff, 1675px 417px #fff, 1022px 856px #fff, 181px 311px #fff,
    819px 1129px #fff, 471px 1806px #fff, 2030px 188px #fff, 1582px 2381px #fff,
    2071px 977px #fff, 1500px 935px #fff, 848px 1578px #fff, 1715px 2344px #fff,
    1095px 274px #fff, 464px 1570px #fff, 906px 1146px #fff, 1266px 1079px #fff,
    1894px 1160px #fff, 2478px 828px #fff, 361px 573px #fff, 1263px 2066px #fff,
    166px 1856px #fff, 1833px 616px #fff, 1070px 2354px #fff, 1687px 882px #fff,
    1698px 1641px #fff, 447px 1128px #fff, 1180px 1739px #fff, 308px 2156px #fff,
    391px 989px #fff, 2440px 288px #fff, 2446px 2445px #fff, 110px 1947px #fff,
    1308px 203px #fff, 1476px 1246px #fff, 1055px 638px #fff, 2079px 533px #fff,
    1350px 1646px #fff, 1491px 317px #fff, 2317px 1882px #fff,
    1566px 1863px #fff, 1570px 1345px #fff, 411px 1780px #fff, 1737px 235px #fff,
    506px 225px #fff, 1343px 1456px #fff, 479px 1239px #fff, 756px 1775px #fff,
    1792px 2237px #fff, 1741px 816px #fff, 942px 1962px #fff, 85px 2430px #fff,
    1998px 375px #fff, 1884px 1036px #fff, 1084px 2457px #fff,
    1069px 1406px #fff, 1111px 953px #fff, 316px 2070px #fff, 2298px 2121px #fff,
    229px 401px #fff, 1008px 979px #fff, 1729px 1363px #fff, 2131px 1084px #fff,
    868px 2453px #fff, 1080px 1768px #fff, 1508px 92px #fff, 2036px 117px #fff,
    2196px 621px #fff, 281px 83px #fff, 1949px 2212px #fff, 354px 1794px #fff,
    1834px 730px #fff, 1057px 898px #fff, 2219px 2506px #fff, 1371px 1251px #fff,
    1657px 935px #fff, 1981px 1699px #fff, 2211px 1456px #fff, 438px 476px #fff,
    146px 2485px #fff, 1345px 415px #fff, 1759px 1086px #fff, 1933px 322px #fff;
  box-shadow: 0 0 #fff, 2035px 1710px #fff, 2220px 12px #fff, 2423px 308px #fff,
    596px 1855px #fff, 600px 1667px #fff, 2086px 1525px #fff, 743px 1557px #fff,
    1037px 1455px #fff, 772px 2480px #fff, 537px 353px #fff, 1930px 135px #fff,
    1962px 1596px #fff, 1552px 1319px #fff, 1889px 134px #fff, 550px 983px #fff,
    1337px 2285px #fff, 361px 2039px #fff, 2379px 2171px #fff, 2337px 999px #fff,
    1675px 417px #fff, 1022px 856px #fff, 181px 311px #fff, 819px 1129px #fff,
    471px 1806px #fff, 2030px 188px #fff, 1582px 2381px #fff, 2071px 977px #fff,
    1500px 935px #fff, 848px 1578px #fff, 1715px 2344px #fff, 1095px 274px #fff,
    464px 1570px #fff, 906px 1146px #fff, 1266px 1079px #fff, 1894px 1160px #fff,
    2478px 828px #fff, 361px 573px #fff, 1263px 2066px #fff, 166px 1856px #fff,
    1833px 616px #fff, 1070px 2354px #fff, 1687px 882px #fff, 1698px 1641px #fff,
    447px 1128px #fff, 1180px 1739px #fff, 308px 2156px #fff, 391px 989px #fff,
    2440px 288px #fff, 2446px 2445px #fff, 110px 1947px #fff, 1308px 203px #fff,
    1476px 1246px #fff, 1055px 638px #fff, 2079px 533px #fff, 1350px 1646px #fff,
    1491px 317px #fff, 2317px 1882px #fff, 1566px 1863px #fff,
    1570px 1345px #fff, 411px 1780px #fff, 1737px 235px #fff, 506px 225px #fff,
    1343px 1456px #fff, 479px 1239px #fff, 756px 1775px #fff, 1792px 2237px #fff,
    1741px 816px #fff, 942px 1962px #fff, 85px 2430px #fff, 1998px 375px #fff,
    1884px 1036px #fff, 1084px 2457px #fff, 1069px 1406px #fff,
    1111px 953px #fff, 316px 2070px #fff, 2298px 2121px #fff, 229px 401px #fff,
    1008px 979px #fff, 1729px 1363px #fff, 2131px 1084px #fff, 868px 2453px #fff,
    1080px 1768px #fff, 1508px 92px #fff, 2036px 117px #fff, 2196px 621px #fff,
    281px 83px #fff, 1949px 2212px #fff, 354px 1794px #fff, 1834px 730px #fff,
    1057px 898px #fff, 2219px 2506px #fff, 1371px 1251px #fff, 1657px 935px #fff,
    1981px 1699px #fff, 2211px 1456px #fff, 438px 476px #fff, 146px 2485px #fff,
    1345px 415px #fff, 1759px 1086px #fff, 1933px 322px #fff;
  height: 1px;
  width: 1px;
  z-index: 1;
}

#stars,
#stars:after {
  height: 1px;
  width: 1px;
}
.icon {
  display: inline-block;
  font-size: 40px;
}
.feature {
  margin-top: 20px;
}
#stars {
  -webkit-animation: animParticle 60s linear infinite;
  animation: animParticle 60s linear infinite;
  -webkit-box-shadow: 0 0 #00d9ef, 2218px 302px #00d9ef, 2116px 1445px #00d9ef,
    2287px 1704px #00d9ef, 1907px 352px #00d9ef, 639px 1726px #00d9ef,
    1976px 2470px #00d9ef, 2485px 1154px #00d9ef, 2343px 539px #00d9ef,
    1942px 2411px #00d9ef, 497px 1674px #00d9ef, 1778px 2496px #00d9ef,
    1452px 263px #00d9ef, 959px 293px #00d9ef, 1905px 2329px #00d9ef,
    2041px 276px #00d9ef, 2132px 1146px #00d9ef, 2413px 294px #00d9ef,
    2498px 1306px #00d9ef, 464px 1999px #00d9ef, 1px 606px #00d9ef,
    172px 1370px #00d9ef, 1922px 374px #00d9ef, 482px 2452px #00d9ef,
    2476px 1961px #00d9ef, 2086px 775px #00d9ef, 2559px 1619px #00d9ef,
    390px 1998px #00d9ef, 438px 2020px #00d9ef, 1441px 471px #00d9ef,
    2507px 81px #00d9ef, 1270px 942px #00d9ef, 947px 111px #00d9ef,
    1076px 1848px #00d9ef, 226px 2237px #00d9ef, 1183px 1386px #00d9ef,
    1115px 1515px #00d9ef, 1383px 1464px #00d9ef, 793px 335px #00d9ef,
    1716px 406px #00d9ef, 1455px 32px #00d9ef, 2371px 355px #00d9ef,
    85px 1994px #00d9ef, 557px 94px #00d9ef, 989px 690px #00d9ef,
    1318px 1325px #00d9ef, 808px 2162px #00d9ef, 2358px 2383px #00d9ef,
    1296px 1037px #00d9ef, 1510px 21px #00d9ef, 262px 1789px #00d9ef,
    1966px 1295px #00d9ef, 1903px 2352px #00d9ef, 1799px 1797px #00d9ef,
    1354px 1864px #00d9ef, 267px 1567px #00d9ef, 2005px 384px #00d9ef,
    1432px 2138px #00d9ef, 2093px 2228px #00d9ef, 842px 2061px #00d9ef,
    2051px 2387px #00d9ef, 1583px 861px #00d9ef, 472px 2222px #00d9ef,
    2521px 1168px #00d9ef, 1825px 2469px #00d9ef, 1353px 2391px #00d9ef,
    503px 573px #00d9ef, 788px 178px #00d9ef, 279px 666px #00d9ef,
    1199px 1839px #00d9ef, 403px 1919px #00d9ef, 1862px 786px #00d9ef,
    1951px 1369px #00d9ef, 2227px 2161px #00d9ef, 1227px 1240px #00d9ef,
    2021px 1656px #00d9ef, 807px 770px #00d9ef, 779px 402px #00d9ef,
    1077px 224px #00d9ef, 2205px 1676px #00d9ef, 407px 2036px #00d9ef,
    2131px 475px #00d9ef, 1458px 1626px #00d9ef, 2159px 549px #00d9ef,
    1905px 2341px #00d9ef, 1084px 774px #00d9ef, 1080px 1434px #00d9ef,
    1856px 1914px #00d9ef, 1963px 1171px #00d9ef, 784px 2553px #00d9ef,
    1427px 1497px #00d9ef, 1134px 414px #00d9ef, 1062px 451px #00d9ef,
    594px 1775px #00d9ef, 571px 2367px #00d9ef, 644px 1209px #00d9ef,
    1974px 1869px #00d9ef, 887px 167px #00d9ef, 646px 117px #00d9ef,
    1818px 337px #00d9ef, 959px 1224px #00d9ef, 224px 1420px #00d9ef,
    1266px 1286px #00d9ef, 2302px 1834px #00d9ef, 1633px 1572px #00d9ef,
    716px 708px #00d9ef, 2415px 1731px #00d9ef, 742px 2127px #00d9ef,
    1624px 1458px #00d9ef, 288px 1102px #00d9ef, 1917px 1363px #00d9ef,
    1217px 311px #00d9ef, 844px 1134px #00d9ef, 1038px 1539px #00d9ef,
    1981px 1265px #00d9ef, 2517px 1142px #00d9ef, 2127px 1121px #00d9ef,
    1995px 1366px #00d9ef, 1025px 410px #00d9ef, 2476px 2426px #00d9ef,
    2330px 2451px #00d9ef, 984px 882px #00d9ef, 254px 2546px #00d9ef,
    1746px 70px #00d9ef, 1711px 1713px #00d9ef, 1502px 940px #00d9ef,
    1656px 1775px #00d9ef, 1775px 448px #00d9ef, 1188px 1804px #00d9ef,
    254px 2538px #00d9ef, 814px 2493px #00d9ef, 2452px 845px #00d9ef,
    2309px 2385px #00d9ef, 1555px 2399px #00d9ef, 581px 506px #00d9ef,
    546px 955px #00d9ef, 13px 593px #00d9ef, 2458px 350px #00d9ef,
    2172px 1632px #00d9ef, 1240px 479px #00d9ef, 1485px 1377px #00d9ef,
    1028px 625px #00d9ef, 2037px 1483px #00d9ef, 2381px 981px #00d9ef,
    267px 2201px #00d9ef, 1758px 167px #00d9ef, 1366px 2230px #00d9ef,
    1742px 2122px #00d9ef, 1915px 236px #00d9ef, 1633px 1499px #00d9ef,
    1174px 684px #00d9ef, 2163px 1180px #00d9ef, 800px 2481px #00d9ef,
    1830px 1611px #00d9ef, 369px 2119px #00d9ef, 1323px 1615px #00d9ef,
    558px 2377px #00d9ef, 2355px 1118px #00d9ef, 1543px 2352px #00d9ef,
    892px 31px #00d9ef, 189px 998px #00d9ef, 1385px 983px #00d9ef,
    1363px 2547px #00d9ef, 833px 281px #00d9ef, 1078px 2199px #00d9ef,
    1701px 1553px #00d9ef, 1681px 1155px #00d9ef, 471px 2114px #00d9ef,
    1765px 2081px #00d9ef, 615px 872px #00d9ef, 203px 1580px #00d9ef,
    828px 2547px #00d9ef, 115px 813px #00d9ef, 51px 336px #00d9ef,
    419px 1036px #00d9ef, 2000px 2215px #00d9ef, 1768px 2255px #00d9ef,
    580px 1899px #00d9ef, 2329px 2020px #00d9ef, 595px 2143px #00d9ef,
    2209px 1790px #00d9ef, 2510px 2038px #00d9ef, 1560px 370px #00d9ef,
    2534px 1476px #00d9ef, 1203px 2044px #00d9ef, 1747px 1217px #00d9ef,
    11px 1825px #00d9ef, 1375px 417px #00d9ef, 1852px 548px #00d9ef,
    2495px 2418px #00d9ef, 788px 2335px #00d9ef, 33px 317px #00d9ef,
    1790px 2116px #00d9ef, 1092px 1169px #00d9ef, 20px 560px #00d9ef,
    1999px 2135px #00d9ef, 1594px 2093px #00d9ef, 1360px 1809px #00d9ef,
    633px 48px #00d9ef, 2049px 886px #00d9ef, 776px 1988px #00d9ef,
    2291px 1706px #00d9ef, 1000px 2034px #00d9ef, 308px 105px #00d9ef,
    685px 949px #00d9ef, 1856px 2223px #00d9ef, 1198px 1155px #00d9ef,
    2559px 433px #00d9ef, 1106px 1733px #00d9ef, 1341px 2246px #00d9ef,
    1672px 1820px #00d9ef, 284px 1084px #00d9ef, 219px 2357px #00d9ef,
    2514px 1360px #00d9ef, 1969px 339px #00d9ef, 2214px 2057px #00d9ef,
    438px 38px #00d9ef, 402px 794px #00d9ef, 1395px 2307px #00d9ef,
    1595px 235px #00d9ef, 2430px 139px #00d9ef, 968px 2026px #00d9ef,
    441px 544px #00d9ef, 1949px 1113px #00d9ef, 1551px 1739px #00d9ef,
    478px 2314px #00d9ef, 1204px 88px #00d9ef, 2182px 1148px #00d9ef,
    1001px 828px #00d9ef, 1966px 285px #00d9ef, 864px 1958px #00d9ef,
    863px 1714px #00d9ef, 1724px 1390px #00d9ef, 2411px 507px #00d9ef,
    1292px 911px #00d9ef, 971px 619px #00d9ef, 1595px 748px #00d9ef,
    1852px 1422px #00d9ef, 843px 2500px #00d9ef, 533px 2113px #00d9ef,
    2067px 2211px #00d9ef, 1230px 1168px #00d9ef, 1739px 2472px #00d9ef,
    1244px 1733px #00d9ef, 2426px 1409px #00d9ef, 871px 511px #00d9ef,
    1980px 1149px #00d9ef, 1163px 1473px #00d9ef, 2191px 7px #00d9ef,
    2541px 1364px #00d9ef, 883px 411px #00d9ef, 1747px 1659px #00d9ef,
    645px 859px #00d9ef, 1393px 1764px #00d9ef, 2153px 1642px #00d9ef,
    851px 2045px #00d9ef, 1537px 1363px #00d9ef, 1454px 1780px #00d9ef,
    1854px 2096px #00d9ef, 1538px 2284px #00d9ef, 125px 316px #00d9ef,
    2181px 1643px #00d9ef, 1891px 1367px #00d9ef, 1213px 1054px #00d9ef,
    1167px 2190px #00d9ef, 1231px 1763px #00d9ef, 1635px 1759px #00d9ef,
    2525px 2366px #00d9ef, 1481px 794px #00d9ef, 2395px 2269px #00d9ef,
    561px 1786px #00d9ef, 399px 1176px #00d9ef, 1549px 1191px #00d9ef,
    1349px 2004px #00d9ef, 2334px 22px #00d9ef, 257px 1652px #00d9ef,
    2277px 2274px #00d9ef, 2472px 506px #00d9ef, 1439px 825px #00d9ef,
    764px 1010px #00d9ef, 1592px 164px #00d9ef, 658px 731px #00d9ef,
    2459px 2047px #00d9ef, 76px 1718px #00d9ef, 1778px 2395px #00d9ef,
    867px 1143px #00d9ef, 826px 539px #00d9ef, 1454px 2389px #00d9ef,
    1883px 746px #00d9ef, 915px 2033px #00d9ef, 1604px 1253px #00d9ef,
    2544px 449px #00d9ef, 2074px 1248px #00d9ef, 179px 2084px #00d9ef,
    1022px 721px #00d9ef, 2201px 2190px #00d9ef, 1366px 2059px #00d9ef,
    2194px 1463px #00d9ef, 1930px 864px #00d9ef, 435px 409px #00d9ef,
    348px 1184px #00d9ef, 1271px 243px #00d9ef, 1153px 1491px #00d9ef,
    1217px 2277px #00d9ef, 806px 961px #00d9ef, 696px 748px #00d9ef,
    1010px 384px #00d9ef, 1254px 1376px #00d9ef, 1593px 2414px #00d9ef,
    1923px 495px #00d9ef, 764px 2418px #00d9ef, 1447px 212px #00d9ef,
    360px 2445px #00d9ef, 1411px 366px #00d9ef, 2147px 503px #00d9ef,
    1130px 871px #00d9ef, 2px 2118px #00d9ef, 891px 2049px #00d9ef,
    651px 1153px #00d9ef, 1547px 2397px #00d9ef, 1444px 1231px #00d9ef,
    2074px 55px #00d9ef, 703px 2px #00d9ef, 1263px 2489px #00d9ef,
    1334px 2052px #00d9ef, 1525px 170px #00d9ef, 2494px 1016px #00d9ef,
    2142px 1201px #00d9ef, 70px 1299px #00d9ef, 2369px 1385px #00d9ef,
    2151px 1862px #00d9ef, 1691px 37px #00d9ef, 1188px 350px #00d9ef,
    2523px 60px #00d9ef, 1482px 2112px #00d9ef, 1588px 1020px #00d9ef,
    563px 1210px #00d9ef, 418px 263px #00d9ef, 2313px 1740px #00d9ef,
    1289px 1499px #00d9ef, 1393px 876px #00d9ef, 587px 1936px #00d9ef,
    1421px 2235px #00d9ef, 1585px 1542px #00d9ef, 1200px 248px #00d9ef,
    1604px 2295px #00d9ef, 2082px 2040px #00d9ef, 2312px 2283px #00d9ef,
    230px 1633px #00d9ef, 2034px 1549px #00d9ef, 69px 195px #00d9ef,
    129px 1441px #00d9ef, 1639px 1566px #00d9ef, 483px 2219px #00d9ef,
    1338px 2358px #00d9ef, 1202px 1488px #00d9ef, 148px 637px #00d9ef,
    2361px 2211px #00d9ef, 1722px 273px #00d9ef, 1995px 1320px #00d9ef,
    850px 32px #00d9ef, 2031px 91px #00d9ef, 2166px 837px #00d9ef,
    916px 385px #00d9ef, 446px 1519px #00d9ef, 212px 99px #00d9ef,
    725px 1812px #00d9ef, 1765px 910px #00d9ef, 1134px 481px #00d9ef,
    2154px 1037px #00d9ef, 2035px 978px #00d9ef, 420px 243px #00d9ef,
    388px 2143px #00d9ef, 1813px 1131px #00d9ef, 1134px 943px #00d9ef,
    2127px 2307px #00d9ef, 1133px 681px #00d9ef, 907px 442px #00d9ef,
    1072px 1028px #00d9ef, 727px 57px #00d9ef, 1842px 197px #00d9ef,
    1187px 1742px #00d9ef, 1210px 1343px #00d9ef, 443px 1306px #00d9ef,
    2102px 1739px #00d9ef, 1225px 2369px #00d9ef, 345px 2016px #00d9ef,
    1893px 1954px #00d9ef, 267px 2244px #00d9ef, 597px 2168px #00d9ef,
    1959px 179px #00d9ef, 2045px 1283px #00d9ef, 798px 1489px #00d9ef,
    2039px 1857px #00d9ef, 503px 265px #00d9ef, 1868px 190px #00d9ef,
    1066px 305px #00d9ef, 1980px 1901px #00d9ef, 1110px 1139px #00d9ef,
    1278px 286px #00d9ef, 1244px 566px #00d9ef, 257px 497px #00d9ef,
    2074px 545px #00d9ef, 701px 427px #00d9ef, 2106px 1852px #00d9ef,
    2293px 791px #00d9ef, 973px 1670px #00d9ef, 1016px 255px #00d9ef,
    1983px 390px #00d9ef, 2533px 1900px #00d9ef, 1164px 1571px #00d9ef,
    1386px 193px #00d9ef, 686px 367px #00d9ef, 2180px 2071px #00d9ef,
    1181px 2444px #00d9ef, 2531px 2089px #00d9ef, 2304px 2104px #00d9ef,
    1798px 1068px #00d9ef, 1010px 658px #00d9ef, 903px 2213px #00d9ef,
    1649px 2229px #00d9ef, 1073px 2124px #00d9ef, 1137px 1779px #00d9ef,
    22px 955px #00d9ef, 2552px 337px #00d9ef, 1998px 23px #00d9ef,
    832px 97px #00d9ef, 1532px 1457px #00d9ef, 920px 2539px #00d9ef,
    1287px 930px #00d9ef, 2548px 3px #00d9ef, 998px 438px #00d9ef,
    2157px 2543px #00d9ef, 1392px 1579px #00d9ef, 652px 1664px #00d9ef,
    62px 2135px #00d9ef, 638px 783px #00d9ef, 2348px 562px #00d9ef,
    39px 2213px #00d9ef, 1691px 395px #00d9ef, 2349px 9px #00d9ef,
    273px 596px #00d9ef, 1832px 2285px #00d9ef, 2010px 2150px #00d9ef,
    87px 2148px #00d9ef, 67px 174px #00d9ef, 2463px 285px #00d9ef,
    816px 301px #00d9ef, 1216px 1438px #00d9ef, 1229px 422px #00d9ef,
    1638px 1028px #00d9ef, 1733px 2439px #00d9ef, 259px 19px #00d9ef,
    2027px 1397px #00d9ef, 2502px 1961px #00d9ef, 2085px 2115px #00d9ef,
    1898px 1586px #00d9ef, 936px 2193px #00d9ef, 2298px 796px #00d9ef,
    1055px 57px #00d9ef, 1477px 2333px #00d9ef, 2058px 694px #00d9ef,
    70px 825px #00d9ef, 446px 415px #00d9ef, 869px 546px #00d9ef,
    427px 423px #00d9ef, 2212px 1096px #00d9ef, 1833px 672px #00d9ef,
    1845px 963px #00d9ef, 52px 2446px #00d9ef, 1908px 1856px #00d9ef,
    293px 298px #00d9ef, 1457px 1564px #00d9ef, 942px 2332px #00d9ef,
    492px 142px #00d9ef, 258px 2066px #00d9ef, 2510px 2370px #00d9ef,
    354px 967px #00d9ef, 2351px 1268px #00d9ef, 1707px 510px #00d9ef,
    576px 1677px #00d9ef, 90px 2232px #00d9ef, 957px 1184px #00d9ef,
    2359px 1797px #00d9ef, 1216px 2230px #00d9ef, 525px 2504px #00d9ef,
    1673px 1158px #00d9ef, 1571px 1995px #00d9ef, 1816px 1106px #00d9ef,
    2153px 1658px #00d9ef, 1959px 2027px #00d9ef, 2450px 690px #00d9ef,
    659px 484px #00d9ef, 1635px 2437px #00d9ef, 1477px 871px #00d9ef,
    1985px 1055px #00d9ef, 428px 1332px #00d9ef, 2239px 1759px #00d9ef,
    2221px 1010px #00d9ef, 1665px 1141px #00d9ef, 2509px 2225px #00d9ef,
    533px 2112px #00d9ef, 1072px 573px #00d9ef, 1728px 1297px #00d9ef,
    178px 326px #00d9ef, 850px 560px #00d9ef, 34px 1565px #00d9ef,
    1124px 815px #00d9ef, 2023px 126px #00d9ef, 129px 686px #00d9ef,
    400px 23px #00d9ef, 77px 1472px #00d9ef, 2374px 2080px #00d9ef,
    2165px 1669px #00d9ef, 922px 2502px #00d9ef, 1461px 2555px #00d9ef,
    371px 839px #00d9ef, 1179px 1210px #00d9ef, 213px 932px #00d9ef,
    2262px 2508px #00d9ef, 57px 2270px #00d9ef, 1411px 2149px #00d9ef,
    423px 876px #00d9ef, 175px 2039px #00d9ef, 2539px 1110px #00d9ef,
    2465px 587px #00d9ef, 1891px 1173px #00d9ef, 1147px 564px #00d9ef,
    965px 1137px #00d9ef, 985px 453px #00d9ef, 1317px 1691px #00d9ef,
    1413px 954px #00d9ef, 162px 497px #00d9ef, 2106px 1615px #00d9ef,
    1535px 1662px #00d9ef, 1733px 1649px #00d9ef, 280px 323px #00d9ef,
    18px 1985px #00d9ef, 673px 783px #00d9ef, 2311px 2554px #00d9ef,
    1437px 651px #00d9ef, 352px 924px #00d9ef, 1148px 1655px #00d9ef,
    2370px 1861px #00d9ef, 2409px 1451px #00d9ef, 1560px 661px #00d9ef,
    893px 1984px #00d9ef, 2145px 1681px #00d9ef, 1703px 1467px #00d9ef,
    158px 195px #00d9ef, 1801px 2290px #00d9ef, 439px 1155px #00d9ef,
    562px 2507px #00d9ef, 525px 2030px #00d9ef, 1249px 1671px #00d9ef,
    1032px 71px #00d9ef, 728px 2042px #00d9ef, 1731px 287px #00d9ef,
    1587px 692px #00d9ef, 1151px 2349px #00d9ef, 191px 515px #00d9ef,
    2298px 890px #00d9ef, 1577px 1378px #00d9ef, 2341px 1030px #00d9ef,
    1221px 365px #00d9ef, 2538px 288px #00d9ef, 2160px 1713px #00d9ef,
    1232px 1614px #00d9ef, 2494px 1514px #00d9ef, 1702px 1082px #00d9ef,
    2069px 1277px #00d9ef, 1407px 651px #00d9ef, 2014px 257px #00d9ef,
    2233px 204px #00d9ef, 1719px 501px #00d9ef, 2191px 2000px #00d9ef,
    1883px 1685px #00d9ef, 576px 1553px #00d9ef, 1972px 2075px #00d9ef,
    332px 3px #00d9ef, 867px 2380px #00d9ef, 606px 1621px #00d9ef,
    107px 2270px #00d9ef, 1514px 2040px #00d9ef, 2550px 224px #00d9ef,
    2005px 854px #00d9ef, 434px 1457px #00d9ef, 1507px 2287px #00d9ef,
    2512px 646px #00d9ef, 886px 1044px #00d9ef, 2262px 446px #00d9ef,
    1084px 2207px #00d9ef, 988px 1961px #00d9ef, 1160px 859px #00d9ef,
    54px 155px #00d9ef, 2139px 550px #00d9ef, 1442px 80px #00d9ef,
    770px 1753px #00d9ef, 1194px 267px #00d9ef, 2048px 2169px #00d9ef,
    711px 2291px #00d9ef;
  box-shadow: 0 0 #00d9ef, 2218px 302px #00d9ef, 2116px 1445px #00d9ef,
    2287px 1704px #00d9ef, 1907px 352px #00d9ef, 639px 1726px #00d9ef,
    1976px 2470px #00d9ef, 2485px 1154px #00d9ef, 2343px 539px #00d9ef,
    1942px 2411px #00d9ef, 497px 1674px #00d9ef, 1778px 2496px #00d9ef,
    1452px 263px #00d9ef, 959px 293px #00d9ef, 1905px 2329px #00d9ef,
    2041px 276px #00d9ef, 2132px 1146px #00d9ef, 2413px 294px #00d9ef,
    2498px 1306px #00d9ef, 464px 1999px #00d9ef, 1px 606px #00d9ef,
    172px 1370px #00d9ef, 1922px 374px #00d9ef, 482px 2452px #00d9ef,
    2476px 1961px #00d9ef, 2086px 775px #00d9ef, 2559px 1619px #00d9ef,
    390px 1998px #00d9ef, 438px 2020px #00d9ef, 1441px 471px #00d9ef,
    2507px 81px #00d9ef, 1270px 942px #00d9ef, 947px 111px #00d9ef,
    1076px 1848px #00d9ef, 226px 2237px #00d9ef, 1183px 1386px #00d9ef,
    1115px 1515px #00d9ef, 1383px 1464px #00d9ef, 793px 335px #00d9ef,
    1716px 406px #00d9ef, 1455px 32px #00d9ef, 2371px 355px #00d9ef,
    85px 1994px #00d9ef, 557px 94px #00d9ef, 989px 690px #00d9ef,
    1318px 1325px #00d9ef, 808px 2162px #00d9ef, 2358px 2383px #00d9ef,
    1296px 1037px #00d9ef, 1510px 21px #00d9ef, 262px 1789px #00d9ef,
    1966px 1295px #00d9ef, 1903px 2352px #00d9ef, 1799px 1797px #00d9ef,
    1354px 1864px #00d9ef, 267px 1567px #00d9ef, 2005px 384px #00d9ef,
    1432px 2138px #00d9ef, 2093px 2228px #00d9ef, 842px 2061px #00d9ef,
    2051px 2387px #00d9ef, 1583px 861px #00d9ef, 472px 2222px #00d9ef,
    2521px 1168px #00d9ef, 1825px 2469px #00d9ef, 1353px 2391px #00d9ef,
    503px 573px #00d9ef, 788px 178px #00d9ef, 279px 666px #00d9ef,
    1199px 1839px #00d9ef, 403px 1919px #00d9ef, 1862px 786px #00d9ef,
    1951px 1369px #00d9ef, 2227px 2161px #00d9ef, 1227px 1240px #00d9ef,
    2021px 1656px #00d9ef, 807px 770px #00d9ef, 779px 402px #00d9ef,
    1077px 224px #00d9ef, 2205px 1676px #00d9ef, 407px 2036px #00d9ef,
    2131px 475px #00d9ef, 1458px 1626px #00d9ef, 2159px 549px #00d9ef,
    1905px 2341px #00d9ef, 1084px 774px #00d9ef, 1080px 1434px #00d9ef,
    1856px 1914px #00d9ef, 1963px 1171px #00d9ef, 784px 2553px #00d9ef,
    1427px 1497px #00d9ef, 1134px 414px #00d9ef, 1062px 451px #00d9ef,
    594px 1775px #00d9ef, 571px 2367px #00d9ef, 644px 1209px #00d9ef,
    1974px 1869px #00d9ef, 887px 167px #00d9ef, 646px 117px #00d9ef,
    1818px 337px #00d9ef, 959px 1224px #00d9ef, 224px 1420px #00d9ef,
    1266px 1286px #00d9ef, 2302px 1834px #00d9ef, 1633px 1572px #00d9ef,
    716px 708px #00d9ef, 2415px 1731px #00d9ef, 742px 2127px #00d9ef,
    1624px 1458px #00d9ef, 288px 1102px #00d9ef, 1917px 1363px #00d9ef,
    1217px 311px #00d9ef, 844px 1134px #00d9ef, 1038px 1539px #00d9ef,
    1981px 1265px #00d9ef, 2517px 1142px #00d9ef, 2127px 1121px #00d9ef,
    1995px 1366px #00d9ef, 1025px 410px #00d9ef, 2476px 2426px #00d9ef,
    2330px 2451px #00d9ef, 984px 882px #00d9ef, 254px 2546px #00d9ef,
    1746px 70px #00d9ef, 1711px 1713px #00d9ef, 1502px 940px #00d9ef,
    1656px 1775px #00d9ef, 1775px 448px #00d9ef, 1188px 1804px #00d9ef,
    254px 2538px #00d9ef, 814px 2493px #00d9ef, 2452px 845px #00d9ef,
    2309px 2385px #00d9ef, 1555px 2399px #00d9ef, 581px 506px #00d9ef,
    546px 955px #00d9ef, 13px 593px #00d9ef, 2458px 350px #00d9ef,
    2172px 1632px #00d9ef, 1240px 479px #00d9ef, 1485px 1377px #00d9ef,
    1028px 625px #00d9ef, 2037px 1483px #00d9ef, 2381px 981px #00d9ef,
    267px 2201px #00d9ef, 1758px 167px #00d9ef, 1366px 2230px #00d9ef,
    1742px 2122px #00d9ef, 1915px 236px #00d9ef, 1633px 1499px #00d9ef,
    1174px 684px #00d9ef, 2163px 1180px #00d9ef, 800px 2481px #00d9ef,
    1830px 1611px #00d9ef, 369px 2119px #00d9ef, 1323px 1615px #00d9ef,
    558px 2377px #00d9ef, 2355px 1118px #00d9ef, 1543px 2352px #00d9ef,
    892px 31px #00d9ef, 189px 998px #00d9ef, 1385px 983px #00d9ef,
    1363px 2547px #00d9ef, 833px 281px #00d9ef, 1078px 2199px #00d9ef,
    1701px 1553px #00d9ef, 1681px 1155px #00d9ef, 471px 2114px #00d9ef,
    1765px 2081px #00d9ef, 615px 872px #00d9ef, 203px 1580px #00d9ef,
    828px 2547px #00d9ef, 115px 813px #00d9ef, 51px 336px #00d9ef,
    419px 1036px #00d9ef, 2000px 2215px #00d9ef, 1768px 2255px #00d9ef,
    580px 1899px #00d9ef, 2329px 2020px #00d9ef, 595px 2143px #00d9ef,
    2209px 1790px #00d9ef, 2510px 2038px #00d9ef, 1560px 370px #00d9ef,
    2534px 1476px #00d9ef, 1203px 2044px #00d9ef, 1747px 1217px #00d9ef,
    11px 1825px #00d9ef, 1375px 417px #00d9ef, 1852px 548px #00d9ef,
    2495px 2418px #00d9ef, 788px 2335px #00d9ef, 33px 317px #00d9ef,
    1790px 2116px #00d9ef, 1092px 1169px #00d9ef, 20px 560px #00d9ef,
    1999px 2135px #00d9ef, 1594px 2093px #00d9ef, 1360px 1809px #00d9ef,
    633px 48px #00d9ef, 2049px 886px #00d9ef, 776px 1988px #00d9ef,
    2291px 1706px #00d9ef, 1000px 2034px #00d9ef, 308px 105px #00d9ef,
    685px 949px #00d9ef, 1856px 2223px #00d9ef, 1198px 1155px #00d9ef,
    2559px 433px #00d9ef, 1106px 1733px #00d9ef, 1341px 2246px #00d9ef,
    1672px 1820px #00d9ef, 284px 1084px #00d9ef, 219px 2357px #00d9ef,
    2514px 1360px #00d9ef, 1969px 339px #00d9ef, 2214px 2057px #00d9ef,
    438px 38px #00d9ef, 402px 794px #00d9ef, 1395px 2307px #00d9ef,
    1595px 235px #00d9ef, 2430px 139px #00d9ef, 968px 2026px #00d9ef,
    441px 544px #00d9ef, 1949px 1113px #00d9ef, 1551px 1739px #00d9ef,
    478px 2314px #00d9ef, 1204px 88px #00d9ef, 2182px 1148px #00d9ef,
    1001px 828px #00d9ef, 1966px 285px #00d9ef, 864px 1958px #00d9ef,
    863px 1714px #00d9ef, 1724px 1390px #00d9ef, 2411px 507px #00d9ef,
    1292px 911px #00d9ef, 971px 619px #00d9ef, 1595px 748px #00d9ef,
    1852px 1422px #00d9ef, 843px 2500px #00d9ef, 533px 2113px #00d9ef,
    2067px 2211px #00d9ef, 1230px 1168px #00d9ef, 1739px 2472px #00d9ef,
    1244px 1733px #00d9ef, 2426px 1409px #00d9ef, 871px 511px #00d9ef,
    1980px 1149px #00d9ef, 1163px 1473px #00d9ef, 2191px 7px #00d9ef,
    2541px 1364px #00d9ef, 883px 411px #00d9ef, 1747px 1659px #00d9ef,
    645px 859px #00d9ef, 1393px 1764px #00d9ef, 2153px 1642px #00d9ef,
    851px 2045px #00d9ef, 1537px 1363px #00d9ef, 1454px 1780px #00d9ef,
    1854px 2096px #00d9ef, 1538px 2284px #00d9ef, 125px 316px #00d9ef,
    2181px 1643px #00d9ef, 1891px 1367px #00d9ef, 1213px 1054px #00d9ef,
    1167px 2190px #00d9ef, 1231px 1763px #00d9ef, 1635px 1759px #00d9ef,
    2525px 2366px #00d9ef, 1481px 794px #00d9ef, 2395px 2269px #00d9ef,
    561px 1786px #00d9ef, 399px 1176px #00d9ef, 1549px 1191px #00d9ef,
    1349px 2004px #00d9ef, 2334px 22px #00d9ef, 257px 1652px #00d9ef,
    2277px 2274px #00d9ef, 2472px 506px #00d9ef, 1439px 825px #00d9ef,
    764px 1010px #00d9ef, 1592px 164px #00d9ef, 658px 731px #00d9ef,
    2459px 2047px #00d9ef, 76px 1718px #00d9ef, 1778px 2395px #00d9ef,
    867px 1143px #00d9ef, 826px 539px #00d9ef, 1454px 2389px #00d9ef,
    1883px 746px #00d9ef, 915px 2033px #00d9ef, 1604px 1253px #00d9ef,
    2544px 449px #00d9ef, 2074px 1248px #00d9ef, 179px 2084px #00d9ef,
    1022px 721px #00d9ef, 2201px 2190px #00d9ef, 1366px 2059px #00d9ef,
    2194px 1463px #00d9ef, 1930px 864px #00d9ef, 435px 409px #00d9ef,
    348px 1184px #00d9ef, 1271px 243px #00d9ef, 1153px 1491px #00d9ef,
    1217px 2277px #00d9ef, 806px 961px #00d9ef, 696px 748px #00d9ef,
    1010px 384px #00d9ef, 1254px 1376px #00d9ef, 1593px 2414px #00d9ef,
    1923px 495px #00d9ef, 764px 2418px #00d9ef, 1447px 212px #00d9ef,
    360px 2445px #00d9ef, 1411px 366px #00d9ef, 2147px 503px #00d9ef,
    1130px 871px #00d9ef, 2px 2118px #00d9ef, 891px 2049px #00d9ef,
    651px 1153px #00d9ef, 1547px 2397px #00d9ef, 1444px 1231px #00d9ef,
    2074px 55px #00d9ef, 703px 2px #00d9ef, 1263px 2489px #00d9ef,
    1334px 2052px #00d9ef, 1525px 170px #00d9ef, 2494px 1016px #00d9ef,
    2142px 1201px #00d9ef, 70px 1299px #00d9ef, 2369px 1385px #00d9ef,
    2151px 1862px #00d9ef, 1691px 37px #00d9ef, 1188px 350px #00d9ef,
    2523px 60px #00d9ef, 1482px 2112px #00d9ef, 1588px 1020px #00d9ef,
    563px 1210px #00d9ef, 418px 263px #00d9ef, 2313px 1740px #00d9ef,
    1289px 1499px #00d9ef, 1393px 876px #00d9ef, 587px 1936px #00d9ef,
    1421px 2235px #00d9ef, 1585px 1542px #00d9ef, 1200px 248px #00d9ef,
    1604px 2295px #00d9ef, 2082px 2040px #00d9ef, 2312px 2283px #00d9ef,
    230px 1633px #00d9ef, 2034px 1549px #00d9ef, 69px 195px #00d9ef,
    129px 1441px #00d9ef, 1639px 1566px #00d9ef, 483px 2219px #00d9ef,
    1338px 2358px #00d9ef, 1202px 1488px #00d9ef, 148px 637px #00d9ef,
    2361px 2211px #00d9ef, 1722px 273px #00d9ef, 1995px 1320px #00d9ef,
    850px 32px #00d9ef, 2031px 91px #00d9ef, 2166px 837px #00d9ef,
    916px 385px #00d9ef, 446px 1519px #00d9ef, 212px 99px #00d9ef,
    725px 1812px #00d9ef, 1765px 910px #00d9ef, 1134px 481px #00d9ef,
    2154px 1037px #00d9ef, 2035px 978px #00d9ef, 420px 243px #00d9ef,
    388px 2143px #00d9ef, 1813px 1131px #00d9ef, 1134px 943px #00d9ef,
    2127px 2307px #00d9ef, 1133px 681px #00d9ef, 907px 442px #00d9ef,
    1072px 1028px #00d9ef, 727px 57px #00d9ef, 1842px 197px #00d9ef,
    1187px 1742px #00d9ef, 1210px 1343px #00d9ef, 443px 1306px #00d9ef,
    2102px 1739px #00d9ef, 1225px 2369px #00d9ef, 345px 2016px #00d9ef,
    1893px 1954px #00d9ef, 267px 2244px #00d9ef, 597px 2168px #00d9ef,
    1959px 179px #00d9ef, 2045px 1283px #00d9ef, 798px 1489px #00d9ef,
    2039px 1857px #00d9ef, 503px 265px #00d9ef, 1868px 190px #00d9ef,
    1066px 305px #00d9ef, 1980px 1901px #00d9ef, 1110px 1139px #00d9ef,
    1278px 286px #00d9ef, 1244px 566px #00d9ef, 257px 497px #00d9ef,
    2074px 545px #00d9ef, 701px 427px #00d9ef, 2106px 1852px #00d9ef,
    2293px 791px #00d9ef, 973px 1670px #00d9ef, 1016px 255px #00d9ef,
    1983px 390px #00d9ef, 2533px 1900px #00d9ef, 1164px 1571px #00d9ef,
    1386px 193px #00d9ef, 686px 367px #00d9ef, 2180px 2071px #00d9ef,
    1181px 2444px #00d9ef, 2531px 2089px #00d9ef, 2304px 2104px #00d9ef,
    1798px 1068px #00d9ef, 1010px 658px #00d9ef, 903px 2213px #00d9ef,
    1649px 2229px #00d9ef, 1073px 2124px #00d9ef, 1137px 1779px #00d9ef,
    22px 955px #00d9ef, 2552px 337px #00d9ef, 1998px 23px #00d9ef,
    832px 97px #00d9ef, 1532px 1457px #00d9ef, 920px 2539px #00d9ef,
    1287px 930px #00d9ef, 2548px 3px #00d9ef, 998px 438px #00d9ef,
    2157px 2543px #00d9ef, 1392px 1579px #00d9ef, 652px 1664px #00d9ef,
    62px 2135px #00d9ef, 638px 783px #00d9ef, 2348px 562px #00d9ef,
    39px 2213px #00d9ef, 1691px 395px #00d9ef, 2349px 9px #00d9ef,
    273px 596px #00d9ef, 1832px 2285px #00d9ef, 2010px 2150px #00d9ef,
    87px 2148px #00d9ef, 67px 174px #00d9ef, 2463px 285px #00d9ef,
    816px 301px #00d9ef, 1216px 1438px #00d9ef, 1229px 422px #00d9ef,
    1638px 1028px #00d9ef, 1733px 2439px #00d9ef, 259px 19px #00d9ef,
    2027px 1397px #00d9ef, 2502px 1961px #00d9ef, 2085px 2115px #00d9ef,
    1898px 1586px #00d9ef, 936px 2193px #00d9ef, 2298px 796px #00d9ef,
    1055px 57px #00d9ef, 1477px 2333px #00d9ef, 2058px 694px #00d9ef,
    70px 825px #00d9ef, 446px 415px #00d9ef, 869px 546px #00d9ef,
    427px 423px #00d9ef, 2212px 1096px #00d9ef, 1833px 672px #00d9ef,
    1845px 963px #00d9ef, 52px 2446px #00d9ef, 1908px 1856px #00d9ef,
    293px 298px #00d9ef, 1457px 1564px #00d9ef, 942px 2332px #00d9ef,
    492px 142px #00d9ef, 258px 2066px #00d9ef, 2510px 2370px #00d9ef,
    354px 967px #00d9ef, 2351px 1268px #00d9ef, 1707px 510px #00d9ef,
    576px 1677px #00d9ef, 90px 2232px #00d9ef, 957px 1184px #00d9ef,
    2359px 1797px #00d9ef, 1216px 2230px #00d9ef, 525px 2504px #00d9ef,
    1673px 1158px #00d9ef, 1571px 1995px #00d9ef, 1816px 1106px #00d9ef,
    2153px 1658px #00d9ef, 1959px 2027px #00d9ef, 2450px 690px #00d9ef,
    659px 484px #00d9ef, 1635px 2437px #00d9ef, 1477px 871px #00d9ef,
    1985px 1055px #00d9ef, 428px 1332px #00d9ef, 2239px 1759px #00d9ef,
    2221px 1010px #00d9ef, 1665px 1141px #00d9ef, 2509px 2225px #00d9ef,
    533px 2112px #00d9ef, 1072px 573px #00d9ef, 1728px 1297px #00d9ef,
    178px 326px #00d9ef, 850px 560px #00d9ef, 34px 1565px #00d9ef,
    1124px 815px #00d9ef, 2023px 126px #00d9ef, 129px 686px #00d9ef,
    400px 23px #00d9ef, 77px 1472px #00d9ef, 2374px 2080px #00d9ef,
    2165px 1669px #00d9ef, 922px 2502px #00d9ef, 1461px 2555px #00d9ef,
    371px 839px #00d9ef, 1179px 1210px #00d9ef, 213px 932px #00d9ef,
    2262px 2508px #00d9ef, 57px 2270px #00d9ef, 1411px 2149px #00d9ef,
    423px 876px #00d9ef, 175px 2039px #00d9ef, 2539px 1110px #00d9ef,
    2465px 587px #00d9ef, 1891px 1173px #00d9ef, 1147px 564px #00d9ef,
    965px 1137px #00d9ef, 985px 453px #00d9ef, 1317px 1691px #00d9ef,
    1413px 954px #00d9ef, 162px 497px #00d9ef, 2106px 1615px #00d9ef,
    1535px 1662px #00d9ef, 1733px 1649px #00d9ef, 280px 323px #00d9ef,
    18px 1985px #00d9ef, 673px 783px #00d9ef, 2311px 2554px #00d9ef,
    1437px 651px #00d9ef, 352px 924px #00d9ef, 1148px 1655px #00d9ef,
    2370px 1861px #00d9ef, 2409px 1451px #00d9ef, 1560px 661px #00d9ef,
    893px 1984px #00d9ef, 2145px 1681px #00d9ef, 1703px 1467px #00d9ef,
    158px 195px #00d9ef, 1801px 2290px #00d9ef, 439px 1155px #00d9ef,
    562px 2507px #00d9ef, 525px 2030px #00d9ef, 1249px 1671px #00d9ef,
    1032px 71px #00d9ef, 728px 2042px #00d9ef, 1731px 287px #00d9ef,
    1587px 692px #00d9ef, 1151px 2349px #00d9ef, 191px 515px #00d9ef,
    2298px 890px #00d9ef, 1577px 1378px #00d9ef, 2341px 1030px #00d9ef,
    1221px 365px #00d9ef, 2538px 288px #00d9ef, 2160px 1713px #00d9ef,
    1232px 1614px #00d9ef, 2494px 1514px #00d9ef, 1702px 1082px #00d9ef,
    2069px 1277px #00d9ef, 1407px 651px #00d9ef, 2014px 257px #00d9ef,
    2233px 204px #00d9ef, 1719px 501px #00d9ef, 2191px 2000px #00d9ef,
    1883px 1685px #00d9ef, 576px 1553px #00d9ef, 1972px 2075px #00d9ef,
    332px 3px #00d9ef, 867px 2380px #00d9ef, 606px 1621px #00d9ef,
    107px 2270px #00d9ef, 1514px 2040px #00d9ef, 2550px 224px #00d9ef,
    2005px 854px #00d9ef, 434px 1457px #00d9ef, 1507px 2287px #00d9ef,
    2512px 646px #00d9ef, 886px 1044px #00d9ef, 2262px 446px #00d9ef,
    1084px 2207px #00d9ef, 988px 1961px #00d9ef, 1160px 859px #00d9ef,
    54px 155px #00d9ef, 2139px 550px #00d9ef, 1442px 80px #00d9ef,
    770px 1753px #00d9ef, 1194px 267px #00d9ef, 2048px 2169px #00d9ef,
    711px 2291px #00d9ef;
}
#stars:after {
  -webkit-box-shadow: 0 0 #00d9ef, 59px 1384px #00d9ef, 1167px 2088px #00d9ef,
    471px 1238px #00d9ef, 1696px 220px #00d9ef, 1097px 215px #00d9ef,
    253px 415px #00d9ef, 1023px 700px #00d9ef, 1145px 1587px #00d9ef,
    2502px 1354px #00d9ef, 630px 1362px #00d9ef, 1888px 2201px #00d9ef,
    1822px 308px #00d9ef, 2223px 928px #00d9ef, 1233px 1816px #00d9ef,
    1367px 2139px #00d9ef, 597px 2189px #00d9ef, 1530px 929px #00d9ef,
    752px 1781px #00d9ef, 2467px 1193px #00d9ef, 787px 801px #00d9ef,
    2447px 581px #00d9ef, 1417px 1633px #00d9ef, 2537px 2472px #00d9ef,
    2387px 2344px #00d9ef, 2366px 381px #00d9ef, 2405px 2139px #00d9ef,
    1523px 1236px #00d9ef, 2171px 1242px #00d9ef, 2384px 2263px #00d9ef,
    2436px 1304px #00d9ef, 1295px 2238px #00d9ef, 463px 451px #00d9ef,
    1509px 1022px #00d9ef, 1489px 951px #00d9ef, 1319px 1471px #00d9ef,
    2528px 2550px #00d9ef, 2161px 2429px #00d9ef, 1734px 316px #00d9ef,
    306px 854px #00d9ef, 2002px 2356px #00d9ef, 1412px 2382px #00d9ef,
    2124px 2486px #00d9ef, 808px 223px #00d9ef, 498px 2503px #00d9ef,
    571px 2190px #00d9ef, 1811px 2503px #00d9ef, 31px 16px #00d9ef,
    412px 466px #00d9ef, 1181px 1833px #00d9ef, 844px 4px #00d9ef,
    1695px 524px #00d9ef, 951px 1848px #00d9ef, 1264px 35px #00d9ef,
    1461px 1619px #00d9ef, 1608px 1909px #00d9ef, 1929px 115px #00d9ef,
    1882px 886px #00d9ef, 440px 1905px #00d9ef, 573px 1919px #00d9ef,
    287px 2540px #00d9ef, 2031px 1172px #00d9ef, 851px 894px #00d9ef,
    2403px 1960px #00d9ef, 1508px 967px #00d9ef, 666px 360px #00d9ef,
    2126px 2386px #00d9ef, 1945px 1418px #00d9ef, 813px 2359px #00d9ef,
    124px 542px #00d9ef, 206px 581px #00d9ef, 1639px 369px #00d9ef,
    2453px 1188px #00d9ef, 713px 595px #00d9ef, 731px 1870px #00d9ef,
    485px 239px #00d9ef, 2056px 2497px #00d9ef, 49px 734px #00d9ef,
    1730px 2214px #00d9ef, 1913px 121px #00d9ef, 2040px 450px #00d9ef,
    1290px 2025px #00d9ef, 651px 2535px #00d9ef, 1657px 1124px #00d9ef,
    1484px 1998px #00d9ef, 50px 22px #00d9ef, 1779px 2423px #00d9ef,
    2026px 2353px #00d9ef, 2407px 1364px #00d9ef, 1152px 498px #00d9ef,
    382px 1274px #00d9ef, 415px 701px #00d9ef, 2364px 18px #00d9ef,
    805px 1502px #00d9ef, 601px 1702px #00d9ef, 50px 1408px #00d9ef,
    1220px 1092px #00d9ef, 1392px 1740px #00d9ef, 808px 1466px #00d9ef,
    1996px 429px #00d9ef, 827px 1529px #00d9ef, 111px 1899px #00d9ef,
    709px 616px #00d9ef, 2423px 1984px #00d9ef, 1959px 2507px #00d9ef,
    842px 578px #00d9ef, 35px 1310px #00d9ef, 2033px 287px #00d9ef,
    2076px 1157px #00d9ef, 1295px 1199px #00d9ef, 798px 1106px #00d9ef,
    2022px 670px #00d9ef, 1614px 1150px #00d9ef, 1152px 1094px #00d9ef,
    1831px 1681px #00d9ef, 621px 1182px #00d9ef, 1094px 2190px #00d9ef,
    1286px 1410px #00d9ef, 516px 104px #00d9ef, 1847px 818px #00d9ef,
    1618px 965px #00d9ef, 290px 524px #00d9ef, 204px 1372px #00d9ef,
    1001px 2366px #00d9ef, 373px 1750px #00d9ef, 2288px 371px #00d9ef,
    411px 652px #00d9ef, 2200px 1338px #00d9ef, 862px 819px #00d9ef,
    1449px 2291px #00d9ef, 1936px 72px #00d9ef, 1345px 1176px #00d9ef,
    2425px 700px #00d9ef, 1967px 119px #00d9ef, 2083px 1619px #00d9ef,
    823px 608px #00d9ef, 1319px 1485px #00d9ef, 411px 2285px #00d9ef,
    1069px 1297px #00d9ef, 1618px 1658px #00d9ef, 1636px 1676px #00d9ef,
    1824px 378px #00d9ef, 1528px 693px #00d9ef, 163px 367px #00d9ef,
    379px 732px #00d9ef, 678px 1179px #00d9ef, 2481px 844px #00d9ef,
    878px 1672px #00d9ef, 778px 725px #00d9ef, 661px 1635px #00d9ef,
    15px 761px #00d9ef, 383px 1552px #00d9ef, 1827px 2067px #00d9ef,
    1239px 304px #00d9ef, 1101px 33px #00d9ef, 1972px 2130px #00d9ef,
    1042px 2391px #00d9ef, 1354px 795px #00d9ef, 30px 1960px #00d9ef,
    2290px 686px #00d9ef, 1006px 1557px #00d9ef, 441px 1155px #00d9ef,
    1749px 951px #00d9ef, 2343px 238px #00d9ef, 949px 142px #00d9ef,
    2423px 2285px #00d9ef, 1484px 2077px #00d9ef, 913px 2232px #00d9ef,
    358px 2235px #00d9ef, 630px 154px #00d9ef, 1792px 2492px #00d9ef,
    999px 2381px #00d9ef, 1722px 2180px #00d9ef, 454px 736px #00d9ef,
    2250px 20px #00d9ef, 833px 572px #00d9ef, 371px 188px #00d9ef,
    2157px 1659px #00d9ef, 2492px 1808px #00d9ef, 2391px 1003px #00d9ef,
    2061px 1398px #00d9ef, 238px 2041px #00d9ef, 1865px 313px #00d9ef,
    675px 318px #00d9ef, 1989px 1710px #00d9ef, 2207px 702px #00d9ef,
    2510px 323px #00d9ef, 1946px 1569px #00d9ef, 43px 1025px #00d9ef,
    88px 247px #00d9ef, 1640px 548px #00d9ef, 858px 1057px #00d9ef,
    602px 569px #00d9ef, 1444px 1195px #00d9ef, 1285px 238px #00d9ef,
    1432px 1773px #00d9ef, 2549px 1269px #00d9ef, 410px 1230px #00d9ef,
    122px 1340px #00d9ef, 2532px 1160px #00d9ef, 1192px 2449px #00d9ef,
    1558px 1782px #00d9ef, 241px 1717px #00d9ef, 234px 2551px #00d9ef,
    2004px 1361px #00d9ef, 1945px 2201px #00d9ef, 1861px 9px #00d9ef,
    623px 826px #00d9ef, 2061px 1819px #00d9ef, 1089px 1867px #00d9ef,
    2048px 2336px #00d9ef, 2105px 1834px #00d9ef, 519px 1693px #00d9ef,
    925px 70px #00d9ef, 1175px 1512px #00d9ef, 1685px 1561px #00d9ef,
    1694px 2560px #00d9ef, 2480px 1313px #00d9ef, 1003px 1504px #00d9ef,
    2017px 2261px #00d9ef, 824px 605px #00d9ef, 104px 2313px #00d9ef,
    1338px 2491px #00d9ef, 2339px 2030px #00d9ef, 208px 675px #00d9ef,
    103px 2340px #00d9ef, 1914px 2121px #00d9ef, 746px 811px #00d9ef,
    1811px 1220px #00d9ef, 1210px 1037px #00d9ef, 1532px 1044px #00d9ef,
    1720px 1817px #00d9ef, 2440px 2126px #00d9ef, 1722px 203px #00d9ef,
    39px 358px #00d9ef, 981px 1921px #00d9ef, 2538px 1618px #00d9ef,
    125px 2319px #00d9ef, 744px 1899px #00d9ef, 2230px 480px #00d9ef,
    643px 2278px #00d9ef, 149px 1264px #00d9ef, 1298px 291px #00d9ef,
    2252px 291px #00d9ef, 616px 2090px #00d9ef, 1633px 64px #00d9ef,
    2116px 2548px #00d9ef, 389px 1576px #00d9ef, 1227px 2520px #00d9ef,
    2031px 1221px #00d9ef, 775px 83px #00d9ef, 977px 1629px #00d9ef,
    959px 2416px #00d9ef, 438px 2342px #00d9ef, 2144px 1911px #00d9ef,
    2199px 2343px #00d9ef, 1837px 1799px #00d9ef, 1011px 1833px #00d9ef,
    1645px 1450px #00d9ef, 948px 1827px #00d9ef, 2458px 868px #00d9ef,
    475px 1625px #00d9ef, 1149px 649px #00d9ef, 1771px 1874px #00d9ef,
    1801px 67px #00d9ef, 772px 1741px #00d9ef, 1003px 48px #00d9ef,
    869px 813px #00d9ef, 2428px 1812px #00d9ef, 1461px 433px #00d9ef,
    947px 607px #00d9ef, 1438px 132px #00d9ef, 1070px 1469px #00d9ef,
    2090px 547px #00d9ef, 2500px 1856px #00d9ef, 1489px 1688px #00d9ef,
    1840px 1602px #00d9ef, 693px 685px #00d9ef, 1159px 2497px #00d9ef,
    1694px 2289px #00d9ef, 21px 992px #00d9ef, 2285px 1305px #00d9ef,
    1061px 1073px #00d9ef, 2335px 999px #00d9ef, 2288px 249px #00d9ef,
    1661px 2540px #00d9ef, 2263px 2099px #00d9ef, 1836px 386px #00d9ef,
    1559px 1131px #00d9ef, 743px 2017px #00d9ef, 17px 233px #00d9ef,
    2390px 1414px #00d9ef, 835px 1421px #00d9ef, 696px 2237px #00d9ef,
    2454px 687px #00d9ef, 1442px 1793px #00d9ef, 1175px 1180px #00d9ef,
    1315px 349px #00d9ef, 2033px 1441px #00d9ef, 728px 976px #00d9ef,
    1347px 1293px #00d9ef, 1295px 2356px #00d9ef, 400px 344px #00d9ef,
    890px 718px #00d9ef, 581px 2050px #00d9ef, 1858px 2351px #00d9ef,
    2436px 1097px #00d9ef, 1072px 2513px #00d9ef, 2467px 951px #00d9ef,
    321px 1288px #00d9ef, 426px 2128px #00d9ef, 1631px 799px #00d9ef,
    1107px 1546px #00d9ef, 244px 2314px #00d9ef, 2247px 2333px #00d9ef,
    2407px 906px #00d9ef, 1821px 444px #00d9ef, 93px 34px #00d9ef,
    621px 2297px #00d9ef, 782px 1309px #00d9ef, 2324px 1776px #00d9ef,
    834px 230px #00d9ef, 1023px 1731px #00d9ef, 2334px 2267px #00d9ef,
    1329px 2050px #00d9ef, 1234px 1580px #00d9ef, 2305px 2316px #00d9ef,
    440px 199px #00d9ef, 460px 2475px #00d9ef, 1602px 733px #00d9ef,
    1574px 121px #00d9ef, 537px 1645px #00d9ef, 1723px 1631px #00d9ef,
    1862px 306px #00d9ef, 1288px 261px #00d9ef, 1163px 1806px #00d9ef,
    1175px 346px #00d9ef, 568px 1507px #00d9ef, 543px 2202px #00d9ef,
    2339px 999px #00d9ef, 1877px 2164px #00d9ef, 205px 2257px #00d9ef,
    2510px 271px #00d9ef, 848px 655px #00d9ef, 536px 463px #00d9ef,
    1577px 1425px #00d9ef, 2550px 1023px #00d9ef, 1467px 972px #00d9ef,
    1550px 844px #00d9ef, 1297px 869px #00d9ef, 2207px 1043px #00d9ef,
    2282px 104px #00d9ef, 203px 39px #00d9ef, 916px 785px #00d9ef,
    2439px 2238px #00d9ef, 1780px 486px #00d9ef, 919px 287px #00d9ef,
    226px 1948px #00d9ef, 918px 1644px #00d9ef, 998px 507px #00d9ef,
    1268px 1073px #00d9ef, 935px 500px #00d9ef, 1505px 300px #00d9ef,
    1068px 58px #00d9ef, 2442px 312px #00d9ef, 223px 1849px #00d9ef,
    1251px 991px #00d9ef, 1186px 472px #00d9ef, 2110px 457px #00d9ef,
    1027px 1331px #00d9ef, 248px 913px #00d9ef, 2157px 291px #00d9ef,
    498px 4px #00d9ef, 95px 2538px #00d9ef, 911px 2281px #00d9ef,
    69px 726px #00d9ef, 18px 1451px #00d9ef, 2194px 1883px #00d9ef,
    1493px 1410px #00d9ef, 1969px 964px #00d9ef, 1131px 1497px #00d9ef,
    927px 1677px #00d9ef, 2330px 1887px #00d9ef, 1942px 2338px #00d9ef,
    2222px 1135px #00d9ef, 327px 692px #00d9ef, 2174px 545px #00d9ef,
    2535px 663px #00d9ef, 921px 1570px #00d9ef, 866px 849px #00d9ef,
    1803px 769px #00d9ef, 1008px 196px #00d9ef, 439px 918px #00d9ef,
    2143px 848px #00d9ef, 1752px 1431px #00d9ef, 2189px 160px #00d9ef,
    423px 659px #00d9ef, 1733px 1856px #00d9ef, 1499px 1435px #00d9ef,
    486px 933px #00d9ef, 329px 908px #00d9ef, 789px 311px #00d9ef,
    2030px 395px #00d9ef, 1659px 1401px #00d9ef, 967px 2287px #00d9ef,
    2404px 443px #00d9ef, 1506px 575px #00d9ef, 1241px 1777px #00d9ef,
    736px 624px #00d9ef, 1531px 308px #00d9ef, 2022px 1079px #00d9ef,
    2273px 1114px #00d9ef, 51px 1349px #00d9ef, 987px 225px #00d9ef,
    189px 1518px #00d9ef, 252px 2478px #00d9ef, 2213px 2232px #00d9ef,
    1056px 1134px #00d9ef, 1164px 2330px #00d9ef, 686px 18px #00d9ef,
    1839px 1513px #00d9ef, 690px 2176px #00d9ef, 1464px 2197px #00d9ef,
    1185px 958px #00d9ef, 1158px 1477px #00d9ef, 1176px 1474px #00d9ef,
    1544px 592px #00d9ef, 1599px 2521px #00d9ef, 1827px 152px #00d9ef,
    441px 1694px #00d9ef, 379px 1250px #00d9ef, 2074px 1076px #00d9ef,
    737px 1656px #00d9ef, 983px 109px #00d9ef, 1749px 149px #00d9ef,
    1980px 118px #00d9ef, 256px 1261px #00d9ef, 41px 1045px #00d9ef,
    1104px 451px #00d9ef, 59px 737px #00d9ef, 111px 1239px #00d9ef,
    1016px 1289px #00d9ef, 121px 1290px #00d9ef, 1353px 1835px #00d9ef,
    869px 1256px #00d9ef, 90px 1318px #00d9ef, 2106px 1297px #00d9ef,
    597px 1532px #00d9ef, 1815px 646px #00d9ef, 1454px 2314px #00d9ef,
    645px 393px #00d9ef, 2238px 51px #00d9ef, 2514px 1773px #00d9ef,
    952px 287px #00d9ef, 254px 942px #00d9ef, 1698px 751px #00d9ef,
    637px 2313px #00d9ef, 668px 2523px #00d9ef, 1429px 1234px #00d9ef,
    1845px 635px #00d9ef, 1029px 2271px #00d9ef, 1313px 20px #00d9ef,
    2316px 621px #00d9ef, 2042px 499px #00d9ef, 98px 2526px #00d9ef,
    1351px 1173px #00d9ef, 1246px 1953px #00d9ef, 1054px 1413px #00d9ef,
    1156px 2184px #00d9ef, 101px 2548px #00d9ef, 898px 2474px #00d9ef,
    214px 1899px #00d9ef, 785px 970px #00d9ef, 2505px 1927px #00d9ef,
    1591px 1341px #00d9ef, 2193px 1664px #00d9ef, 2296px 1416px #00d9ef,
    2160px 2512px #00d9ef, 2222px 1901px #00d9ef, 2515px 1695px #00d9ef,
    562px 919px #00d9ef, 1846px 2112px #00d9ef, 2118px 2112px #00d9ef,
    2329px 2238px #00d9ef, 725px 1252px #00d9ef, 364px 1699px #00d9ef,
    409px 418px #00d9ef, 1563px 2049px #00d9ef, 2379px 436px #00d9ef,
    2032px 2284px #00d9ef, 1967px 1740px #00d9ef, 539px 1484px #00d9ef,
    2520px 1731px #00d9ef, 2253px 2191px #00d9ef, 1322px 1006px #00d9ef,
    1767px 2010px #00d9ef, 606px 2048px #00d9ef, 2403px 2408px #00d9ef,
    2078px 1297px #00d9ef, 843px 834px #00d9ef, 671px 953px #00d9ef,
    1584px 1226px #00d9ef, 1330px 2390px #00d9ef, 801px 596px #00d9ef,
    689px 1402px #00d9ef, 1437px 590px #00d9ef, 562px 2248px #00d9ef,
    2210px 1132px #00d9ef, 641px 641px #00d9ef, 153px 523px #00d9ef,
    672px 789px #00d9ef, 763px 2260px #00d9ef, 2299px 28px #00d9ef,
    1460px 1613px #00d9ef, 830px 1255px #00d9ef, 1117px 2555px #00d9ef,
    857px 770px #00d9ef, 1049px 663px #00d9ef, 1323px 924px #00d9ef,
    1646px 1731px #00d9ef, 961px 2366px #00d9ef, 167px 1555px #00d9ef,
    458px 144px #00d9ef, 1095px 910px #00d9ef, 2309px 724px #00d9ef,
    1905px 21px #00d9ef, 689px 90px #00d9ef, 579px 1030px #00d9ef,
    2066px 2062px #00d9ef, 1707px 1590px #00d9ef, 1781px 2052px #00d9ef,
    829px 2398px #00d9ef, 625px 832px #00d9ef, 1994px 2049px #00d9ef,
    1324px 1582px #00d9ef, 893px 1172px #00d9ef, 2080px 2302px #00d9ef,
    1370px 1892px #00d9ef, 926px 1719px #00d9ef, 696px 1334px #00d9ef,
    1963px 2025px #00d9ef, 555px 1866px #00d9ef, 761px 2175px #00d9ef,
    525px 2209px #00d9ef, 434px 1914px #00d9ef, 261px 481px #00d9ef,
    1625px 2505px #00d9ef, 2206px 1795px #00d9ef, 1412px 777px #00d9ef,
    2532px 1122px #00d9ef, 1799px 1039px #00d9ef, 1861px 1145px #00d9ef,
    1397px 1703px #00d9ef, 1201px 1187px #00d9ef, 391px 1477px #00d9ef,
    117px 2160px #00d9ef, 731px 2032px #00d9ef, 646px 186px #00d9ef,
    2088px 538px #00d9ef, 404px 1589px #00d9ef, 573px 1607px #00d9ef,
    49px 99px #00d9ef, 805px 875px #00d9ef, 796px 495px #00d9ef,
    1673px 896px #00d9ef, 459px 2106px #00d9ef, 1774px 405px #00d9ef,
    471px 1776px #00d9ef, 1966px 955px #00d9ef, 2366px 210px #00d9ef,
    1113px 1825px #00d9ef, 1069px 2070px #00d9ef, 1731px 2532px #00d9ef,
    2232px 1665px #00d9ef, 2016px 334px #00d9ef, 2058px 269px #00d9ef,
    1507px 541px #00d9ef, 1426px 2262px #00d9ef, 2016px 1590px #00d9ef,
    1217px 352px #00d9ef, 1890px 2156px #00d9ef, 1125px 2205px #00d9ef,
    2418px 1173px #00d9ef, 521px 643px #00d9ef, 92px 212px #00d9ef,
    431px 1590px #00d9ef, 1278px 581px #00d9ef, 2534px 2066px #00d9ef,
    1702px 964px #00d9ef, 199px 1841px #00d9ef, 1170px 1266px #00d9ef,
    1357px 324px #00d9ef, 975px 474px #00d9ef, 2372px 1276px #00d9ef,
    686px 1599px #00d9ef, 2517px 1155px #00d9ef, 904px 1844px #00d9ef,
    1227px 964px #00d9ef, 1190px 962px #00d9ef, 343px 20px #00d9ef,
    956px 1363px #00d9ef;
  box-shadow: 0 0 #00d9ef, 59px 1384px #00d9ef, 1167px 2088px #00d9ef,
    471px 1238px #00d9ef, 1696px 220px #00d9ef, 1097px 215px #00d9ef,
    253px 415px #00d9ef, 1023px 700px #00d9ef, 1145px 1587px #00d9ef,
    2502px 1354px #00d9ef, 630px 1362px #00d9ef, 1888px 2201px #00d9ef,
    1822px 308px #00d9ef, 2223px 928px #00d9ef, 1233px 1816px #00d9ef,
    1367px 2139px #00d9ef, 597px 2189px #00d9ef, 1530px 929px #00d9ef,
    752px 1781px #00d9ef, 2467px 1193px #00d9ef, 787px 801px #00d9ef,
    2447px 581px #00d9ef, 1417px 1633px #00d9ef, 2537px 2472px #00d9ef,
    2387px 2344px #00d9ef, 2366px 381px #00d9ef, 2405px 2139px #00d9ef,
    1523px 1236px #00d9ef, 2171px 1242px #00d9ef, 2384px 2263px #00d9ef,
    2436px 1304px #00d9ef, 1295px 2238px #00d9ef, 463px 451px #00d9ef,
    1509px 1022px #00d9ef, 1489px 951px #00d9ef, 1319px 1471px #00d9ef,
    2528px 2550px #00d9ef, 2161px 2429px #00d9ef, 1734px 316px #00d9ef,
    306px 854px #00d9ef, 2002px 2356px #00d9ef, 1412px 2382px #00d9ef,
    2124px 2486px #00d9ef, 808px 223px #00d9ef, 498px 2503px #00d9ef,
    571px 2190px #00d9ef, 1811px 2503px #00d9ef, 31px 16px #00d9ef,
    412px 466px #00d9ef, 1181px 1833px #00d9ef, 844px 4px #00d9ef,
    1695px 524px #00d9ef, 951px 1848px #00d9ef, 1264px 35px #00d9ef,
    1461px 1619px #00d9ef, 1608px 1909px #00d9ef, 1929px 115px #00d9ef,
    1882px 886px #00d9ef, 440px 1905px #00d9ef, 573px 1919px #00d9ef,
    287px 2540px #00d9ef, 2031px 1172px #00d9ef, 851px 894px #00d9ef,
    2403px 1960px #00d9ef, 1508px 967px #00d9ef, 666px 360px #00d9ef,
    2126px 2386px #00d9ef, 1945px 1418px #00d9ef, 813px 2359px #00d9ef,
    124px 542px #00d9ef, 206px 581px #00d9ef, 1639px 369px #00d9ef,
    2453px 1188px #00d9ef, 713px 595px #00d9ef, 731px 1870px #00d9ef,
    485px 239px #00d9ef, 2056px 2497px #00d9ef, 49px 734px #00d9ef,
    1730px 2214px #00d9ef, 1913px 121px #00d9ef, 2040px 450px #00d9ef,
    1290px 2025px #00d9ef, 651px 2535px #00d9ef, 1657px 1124px #00d9ef,
    1484px 1998px #00d9ef, 50px 22px #00d9ef, 1779px 2423px #00d9ef,
    2026px 2353px #00d9ef, 2407px 1364px #00d9ef, 1152px 498px #00d9ef,
    382px 1274px #00d9ef, 415px 701px #00d9ef, 2364px 18px #00d9ef,
    805px 1502px #00d9ef, 601px 1702px #00d9ef, 50px 1408px #00d9ef,
    1220px 1092px #00d9ef, 1392px 1740px #00d9ef, 808px 1466px #00d9ef,
    1996px 429px #00d9ef, 827px 1529px #00d9ef, 111px 1899px #00d9ef,
    709px 616px #00d9ef, 2423px 1984px #00d9ef, 1959px 2507px #00d9ef,
    842px 578px #00d9ef, 35px 1310px #00d9ef, 2033px 287px #00d9ef,
    2076px 1157px #00d9ef, 1295px 1199px #00d9ef, 798px 1106px #00d9ef,
    2022px 670px #00d9ef, 1614px 1150px #00d9ef, 1152px 1094px #00d9ef,
    1831px 1681px #00d9ef, 621px 1182px #00d9ef, 1094px 2190px #00d9ef,
    1286px 1410px #00d9ef, 516px 104px #00d9ef, 1847px 818px #00d9ef,
    1618px 965px #00d9ef, 290px 524px #00d9ef, 204px 1372px #00d9ef,
    1001px 2366px #00d9ef, 373px 1750px #00d9ef, 2288px 371px #00d9ef,
    411px 652px #00d9ef, 2200px 1338px #00d9ef, 862px 819px #00d9ef,
    1449px 2291px #00d9ef, 1936px 72px #00d9ef, 1345px 1176px #00d9ef,
    2425px 700px #00d9ef, 1967px 119px #00d9ef, 2083px 1619px #00d9ef,
    823px 608px #00d9ef, 1319px 1485px #00d9ef, 411px 2285px #00d9ef,
    1069px 1297px #00d9ef, 1618px 1658px #00d9ef, 1636px 1676px #00d9ef,
    1824px 378px #00d9ef, 1528px 693px #00d9ef, 163px 367px #00d9ef,
    379px 732px #00d9ef, 678px 1179px #00d9ef, 2481px 844px #00d9ef,
    878px 1672px #00d9ef, 778px 725px #00d9ef, 661px 1635px #00d9ef,
    15px 761px #00d9ef, 383px 1552px #00d9ef, 1827px 2067px #00d9ef,
    1239px 304px #00d9ef, 1101px 33px #00d9ef, 1972px 2130px #00d9ef,
    1042px 2391px #00d9ef, 1354px 795px #00d9ef, 30px 1960px #00d9ef,
    2290px 686px #00d9ef, 1006px 1557px #00d9ef, 441px 1155px #00d9ef,
    1749px 951px #00d9ef, 2343px 238px #00d9ef, 949px 142px #00d9ef,
    2423px 2285px #00d9ef, 1484px 2077px #00d9ef, 913px 2232px #00d9ef,
    358px 2235px #00d9ef, 630px 154px #00d9ef, 1792px 2492px #00d9ef,
    999px 2381px #00d9ef, 1722px 2180px #00d9ef, 454px 736px #00d9ef,
    2250px 20px #00d9ef, 833px 572px #00d9ef, 371px 188px #00d9ef,
    2157px 1659px #00d9ef, 2492px 1808px #00d9ef, 2391px 1003px #00d9ef,
    2061px 1398px #00d9ef, 238px 2041px #00d9ef, 1865px 313px #00d9ef,
    675px 318px #00d9ef, 1989px 1710px #00d9ef, 2207px 702px #00d9ef,
    2510px 323px #00d9ef, 1946px 1569px #00d9ef, 43px 1025px #00d9ef,
    88px 247px #00d9ef, 1640px 548px #00d9ef, 858px 1057px #00d9ef,
    602px 569px #00d9ef, 1444px 1195px #00d9ef, 1285px 238px #00d9ef,
    1432px 1773px #00d9ef, 2549px 1269px #00d9ef, 410px 1230px #00d9ef,
    122px 1340px #00d9ef, 2532px 1160px #00d9ef, 1192px 2449px #00d9ef,
    1558px 1782px #00d9ef, 241px 1717px #00d9ef, 234px 2551px #00d9ef,
    2004px 1361px #00d9ef, 1945px 2201px #00d9ef, 1861px 9px #00d9ef,
    623px 826px #00d9ef, 2061px 1819px #00d9ef, 1089px 1867px #00d9ef,
    2048px 2336px #00d9ef, 2105px 1834px #00d9ef, 519px 1693px #00d9ef,
    925px 70px #00d9ef, 1175px 1512px #00d9ef, 1685px 1561px #00d9ef,
    1694px 2560px #00d9ef, 2480px 1313px #00d9ef, 1003px 1504px #00d9ef,
    2017px 2261px #00d9ef, 824px 605px #00d9ef, 104px 2313px #00d9ef,
    1338px 2491px #00d9ef, 2339px 2030px #00d9ef, 208px 675px #00d9ef,
    103px 2340px #00d9ef, 1914px 2121px #00d9ef, 746px 811px #00d9ef,
    1811px 1220px #00d9ef, 1210px 1037px #00d9ef, 1532px 1044px #00d9ef,
    1720px 1817px #00d9ef, 2440px 2126px #00d9ef, 1722px 203px #00d9ef,
    39px 358px #00d9ef, 981px 1921px #00d9ef, 2538px 1618px #00d9ef,
    125px 2319px #00d9ef, 744px 1899px #00d9ef, 2230px 480px #00d9ef,
    643px 2278px #00d9ef, 149px 1264px #00d9ef, 1298px 291px #00d9ef,
    2252px 291px #00d9ef, 616px 2090px #00d9ef, 1633px 64px #00d9ef,
    2116px 2548px #00d9ef, 389px 1576px #00d9ef, 1227px 2520px #00d9ef,
    2031px 1221px #00d9ef, 775px 83px #00d9ef, 977px 1629px #00d9ef,
    959px 2416px #00d9ef, 438px 2342px #00d9ef, 2144px 1911px #00d9ef,
    2199px 2343px #00d9ef, 1837px 1799px #00d9ef, 1011px 1833px #00d9ef,
    1645px 1450px #00d9ef, 948px 1827px #00d9ef, 2458px 868px #00d9ef,
    475px 1625px #00d9ef, 1149px 649px #00d9ef, 1771px 1874px #00d9ef,
    1801px 67px #00d9ef, 772px 1741px #00d9ef, 1003px 48px #00d9ef,
    869px 813px #00d9ef, 2428px 1812px #00d9ef, 1461px 433px #00d9ef,
    947px 607px #00d9ef, 1438px 132px #00d9ef, 1070px 1469px #00d9ef,
    2090px 547px #00d9ef, 2500px 1856px #00d9ef, 1489px 1688px #00d9ef,
    1840px 1602px #00d9ef, 693px 685px #00d9ef, 1159px 2497px #00d9ef,
    1694px 2289px #00d9ef, 21px 992px #00d9ef, 2285px 1305px #00d9ef,
    1061px 1073px #00d9ef, 2335px 999px #00d9ef, 2288px 249px #00d9ef,
    1661px 2540px #00d9ef, 2263px 2099px #00d9ef, 1836px 386px #00d9ef,
    1559px 1131px #00d9ef, 743px 2017px #00d9ef, 17px 233px #00d9ef,
    2390px 1414px #00d9ef, 835px 1421px #00d9ef, 696px 2237px #00d9ef,
    2454px 687px #00d9ef, 1442px 1793px #00d9ef, 1175px 1180px #00d9ef,
    1315px 349px #00d9ef, 2033px 1441px #00d9ef, 728px 976px #00d9ef,
    1347px 1293px #00d9ef, 1295px 2356px #00d9ef, 400px 344px #00d9ef,
    890px 718px #00d9ef, 581px 2050px #00d9ef, 1858px 2351px #00d9ef,
    2436px 1097px #00d9ef, 1072px 2513px #00d9ef, 2467px 951px #00d9ef,
    321px 1288px #00d9ef, 426px 2128px #00d9ef, 1631px 799px #00d9ef,
    1107px 1546px #00d9ef, 244px 2314px #00d9ef, 2247px 2333px #00d9ef,
    2407px 906px #00d9ef, 1821px 444px #00d9ef, 93px 34px #00d9ef,
    621px 2297px #00d9ef, 782px 1309px #00d9ef, 2324px 1776px #00d9ef,
    834px 230px #00d9ef, 1023px 1731px #00d9ef, 2334px 2267px #00d9ef,
    1329px 2050px #00d9ef, 1234px 1580px #00d9ef, 2305px 2316px #00d9ef,
    440px 199px #00d9ef, 460px 2475px #00d9ef, 1602px 733px #00d9ef,
    1574px 121px #00d9ef, 537px 1645px #00d9ef, 1723px 1631px #00d9ef,
    1862px 306px #00d9ef, 1288px 261px #00d9ef, 1163px 1806px #00d9ef,
    1175px 346px #00d9ef, 568px 1507px #00d9ef, 543px 2202px #00d9ef,
    2339px 999px #00d9ef, 1877px 2164px #00d9ef, 205px 2257px #00d9ef,
    2510px 271px #00d9ef, 848px 655px #00d9ef, 536px 463px #00d9ef,
    1577px 1425px #00d9ef, 2550px 1023px #00d9ef, 1467px 972px #00d9ef,
    1550px 844px #00d9ef, 1297px 869px #00d9ef, 2207px 1043px #00d9ef,
    2282px 104px #00d9ef, 203px 39px #00d9ef, 916px 785px #00d9ef,
    2439px 2238px #00d9ef, 1780px 486px #00d9ef, 919px 287px #00d9ef,
    226px 1948px #00d9ef, 918px 1644px #00d9ef, 998px 507px #00d9ef,
    1268px 1073px #00d9ef, 935px 500px #00d9ef, 1505px 300px #00d9ef,
    1068px 58px #00d9ef, 2442px 312px #00d9ef, 223px 1849px #00d9ef,
    1251px 991px #00d9ef, 1186px 472px #00d9ef, 2110px 457px #00d9ef,
    1027px 1331px #00d9ef, 248px 913px #00d9ef, 2157px 291px #00d9ef,
    498px 4px #00d9ef, 95px 2538px #00d9ef, 911px 2281px #00d9ef,
    69px 726px #00d9ef, 18px 1451px #00d9ef, 2194px 1883px #00d9ef,
    1493px 1410px #00d9ef, 1969px 964px #00d9ef, 1131px 1497px #00d9ef,
    927px 1677px #00d9ef, 2330px 1887px #00d9ef, 1942px 2338px #00d9ef,
    2222px 1135px #00d9ef, 327px 692px #00d9ef, 2174px 545px #00d9ef,
    2535px 663px #00d9ef, 921px 1570px #00d9ef, 866px 849px #00d9ef,
    1803px 769px #00d9ef, 1008px 196px #00d9ef, 439px 918px #00d9ef,
    2143px 848px #00d9ef, 1752px 1431px #00d9ef, 2189px 160px #00d9ef,
    423px 659px #00d9ef, 1733px 1856px #00d9ef, 1499px 1435px #00d9ef,
    486px 933px #00d9ef, 329px 908px #00d9ef, 789px 311px #00d9ef,
    2030px 395px #00d9ef, 1659px 1401px #00d9ef, 967px 2287px #00d9ef,
    2404px 443px #00d9ef, 1506px 575px #00d9ef, 1241px 1777px #00d9ef,
    736px 624px #00d9ef, 1531px 308px #00d9ef, 2022px 1079px #00d9ef,
    2273px 1114px #00d9ef, 51px 1349px #00d9ef, 987px 225px #00d9ef,
    189px 1518px #00d9ef, 252px 2478px #00d9ef, 2213px 2232px #00d9ef,
    1056px 1134px #00d9ef, 1164px 2330px #00d9ef, 686px 18px #00d9ef,
    1839px 1513px #00d9ef, 690px 2176px #00d9ef, 1464px 2197px #00d9ef,
    1185px 958px #00d9ef, 1158px 1477px #00d9ef, 1176px 1474px #00d9ef,
    1544px 592px #00d9ef, 1599px 2521px #00d9ef, 1827px 152px #00d9ef,
    441px 1694px #00d9ef, 379px 1250px #00d9ef, 2074px 1076px #00d9ef,
    737px 1656px #00d9ef, 983px 109px #00d9ef, 1749px 149px #00d9ef,
    1980px 118px #00d9ef, 256px 1261px #00d9ef, 41px 1045px #00d9ef,
    1104px 451px #00d9ef, 59px 737px #00d9ef, 111px 1239px #00d9ef,
    1016px 1289px #00d9ef, 121px 1290px #00d9ef, 1353px 1835px #00d9ef,
    869px 1256px #00d9ef, 90px 1318px #00d9ef, 2106px 1297px #00d9ef,
    597px 1532px #00d9ef, 1815px 646px #00d9ef, 1454px 2314px #00d9ef,
    645px 393px #00d9ef, 2238px 51px #00d9ef, 2514px 1773px #00d9ef,
    952px 287px #00d9ef, 254px 942px #00d9ef, 1698px 751px #00d9ef,
    637px 2313px #00d9ef, 668px 2523px #00d9ef, 1429px 1234px #00d9ef,
    1845px 635px #00d9ef, 1029px 2271px #00d9ef, 1313px 20px #00d9ef,
    2316px 621px #00d9ef, 2042px 499px #00d9ef, 98px 2526px #00d9ef,
    1351px 1173px #00d9ef, 1246px 1953px #00d9ef, 1054px 1413px #00d9ef,
    1156px 2184px #00d9ef, 101px 2548px #00d9ef, 898px 2474px #00d9ef,
    214px 1899px #00d9ef, 785px 970px #00d9ef, 2505px 1927px #00d9ef,
    1591px 1341px #00d9ef, 2193px 1664px #00d9ef, 2296px 1416px #00d9ef,
    2160px 2512px #00d9ef, 2222px 1901px #00d9ef, 2515px 1695px #00d9ef,
    562px 919px #00d9ef, 1846px 2112px #00d9ef, 2118px 2112px #00d9ef,
    2329px 2238px #00d9ef, 725px 1252px #00d9ef, 364px 1699px #00d9ef,
    409px 418px #00d9ef, 1563px 2049px #00d9ef, 2379px 436px #00d9ef,
    2032px 2284px #00d9ef, 1967px 1740px #00d9ef, 539px 1484px #00d9ef,
    2520px 1731px #00d9ef, 2253px 2191px #00d9ef, 1322px 1006px #00d9ef,
    1767px 2010px #00d9ef, 606px 2048px #00d9ef, 2403px 2408px #00d9ef,
    2078px 1297px #00d9ef, 843px 834px #00d9ef, 671px 953px #00d9ef,
    1584px 1226px #00d9ef, 1330px 2390px #00d9ef, 801px 596px #00d9ef,
    689px 1402px #00d9ef, 1437px 590px #00d9ef, 562px 2248px #00d9ef,
    2210px 1132px #00d9ef, 641px 641px #00d9ef, 153px 523px #00d9ef,
    672px 789px #00d9ef, 763px 2260px #00d9ef, 2299px 28px #00d9ef,
    1460px 1613px #00d9ef, 830px 1255px #00d9ef, 1117px 2555px #00d9ef,
    857px 770px #00d9ef, 1049px 663px #00d9ef, 1323px 924px #00d9ef,
    1646px 1731px #00d9ef, 961px 2366px #00d9ef, 167px 1555px #00d9ef,
    458px 144px #00d9ef, 1095px 910px #00d9ef, 2309px 724px #00d9ef,
    1905px 21px #00d9ef, 689px 90px #00d9ef, 579px 1030px #00d9ef,
    2066px 2062px #00d9ef, 1707px 1590px #00d9ef, 1781px 2052px #00d9ef,
    829px 2398px #00d9ef, 625px 832px #00d9ef, 1994px 2049px #00d9ef,
    1324px 1582px #00d9ef, 893px 1172px #00d9ef, 2080px 2302px #00d9ef,
    1370px 1892px #00d9ef, 926px 1719px #00d9ef, 696px 1334px #00d9ef,
    1963px 2025px #00d9ef, 555px 1866px #00d9ef, 761px 2175px #00d9ef,
    525px 2209px #00d9ef, 434px 1914px #00d9ef, 261px 481px #00d9ef,
    1625px 2505px #00d9ef, 2206px 1795px #00d9ef, 1412px 777px #00d9ef,
    2532px 1122px #00d9ef, 1799px 1039px #00d9ef, 1861px 1145px #00d9ef,
    1397px 1703px #00d9ef, 1201px 1187px #00d9ef, 391px 1477px #00d9ef,
    117px 2160px #00d9ef, 731px 2032px #00d9ef, 646px 186px #00d9ef,
    2088px 538px #00d9ef, 404px 1589px #00d9ef, 573px 1607px #00d9ef,
    49px 99px #00d9ef, 805px 875px #00d9ef, 796px 495px #00d9ef,
    1673px 896px #00d9ef, 459px 2106px #00d9ef, 1774px 405px #00d9ef,
    471px 1776px #00d9ef, 1966px 955px #00d9ef, 2366px 210px #00d9ef,
    1113px 1825px #00d9ef, 1069px 2070px #00d9ef, 1731px 2532px #00d9ef,
    2232px 1665px #00d9ef, 2016px 334px #00d9ef, 2058px 269px #00d9ef,
    1507px 541px #00d9ef, 1426px 2262px #00d9ef, 2016px 1590px #00d9ef,
    1217px 352px #00d9ef, 1890px 2156px #00d9ef, 1125px 2205px #00d9ef,
    2418px 1173px #00d9ef, 521px 643px #00d9ef, 92px 212px #00d9ef,
    431px 1590px #00d9ef, 1278px 581px #00d9ef, 2534px 2066px #00d9ef,
    1702px 964px #00d9ef, 199px 1841px #00d9ef, 1170px 1266px #00d9ef,
    1357px 324px #00d9ef, 975px 474px #00d9ef, 2372px 1276px #00d9ef,
    686px 1599px #00d9ef, 2517px 1155px #00d9ef, 904px 1844px #00d9ef,
    1227px 964px #00d9ef, 1190px 962px #00d9ef, 343px 20px #00d9ef,
    956px 1363px #00d9ef;
}
#stars2 {
  -webkit-animation: animParticle 120s linear infinite;
  animation: animParticle 120s linear infinite;
  -webkit-box-shadow: 0 0 #00d9ef, 1360px 1208px #00d9ef, 2248px 1745px #00d9ef,
    1534px 2351px #00d9ef, 2047px 2203px #00d9ef, 480px 1109px #00d9ef,
    609px 955px #00d9ef, 1888px 1198px #00d9ef, 1475px 269px #00d9ef,
    1661px 802px #00d9ef, 1939px 2527px #00d9ef, 1181px 192px #00d9ef,
    1731px 1082px #00d9ef, 968px 910px #00d9ef, 1583px 2109px #00d9ef,
    1164px 949px #00d9ef, 1614px 1130px #00d9ef, 2506px 675px #00d9ef,
    1332px 1415px #00d9ef, 175px 700px #00d9ef, 1055px 713px #00d9ef,
    1947px 312px #00d9ef, 290px 2231px #00d9ef, 1473px 2064px #00d9ef,
    609px 1158px #00d9ef, 1874px 481px #00d9ef, 2385px 2441px #00d9ef,
    452px 235px #00d9ef, 2388px 2438px #00d9ef, 1114px 312px #00d9ef,
    1945px 2204px #00d9ef, 1918px 1972px #00d9ef, 2460px 280px #00d9ef,
    2041px 782px #00d9ef, 854px 1735px #00d9ef, 2330px 716px #00d9ef,
    2495px 593px #00d9ef, 1669px 1617px #00d9ef, 2199px 452px #00d9ef,
    2450px 2013px #00d9ef, 2232px 1685px #00d9ef, 2154px 858px #00d9ef,
    2391px 1903px #00d9ef, 1866px 1171px #00d9ef, 963px 2075px #00d9ef,
    1480px 548px #00d9ef, 1736px 1798px #00d9ef, 1647px 195px #00d9ef,
    2022px 131px #00d9ef, 259px 2334px #00d9ef, 701px 519px #00d9ef,
    1116px 717px #00d9ef, 287px 1700px #00d9ef, 495px 1241px #00d9ef,
    2321px 2299px #00d9ef, 1679px 1955px #00d9ef, 222px 2063px #00d9ef,
    2222px 1293px #00d9ef, 1886px 641px #00d9ef, 83px 1338px #00d9ef,
    208px 2235px #00d9ef, 2135px 1484px #00d9ef, 1818px 2548px #00d9ef,
    293px 660px #00d9ef, 2172px 608px #00d9ef, 1390px 317px #00d9ef,
    2375px 509px #00d9ef, 209px 1962px #00d9ef, 2128px 1074px #00d9ef,
    2020px 1673px #00d9ef, 136px 1938px #00d9ef, 350px 1190px #00d9ef,
    505px 2295px #00d9ef, 951px 444px #00d9ef, 903px 268px #00d9ef,
    311px 18px #00d9ef, 135px 2493px #00d9ef, 2523px 1178px #00d9ef,
    431px 1785px #00d9ef, 1174px 453px #00d9ef, 2514px 990px #00d9ef,
    1629px 850px #00d9ef, 19px 2105px #00d9ef, 640px 1412px #00d9ef,
    1149px 664px #00d9ef, 943px 1353px #00d9ef, 2424px 1337px #00d9ef,
    2120px 550px #00d9ef, 747px 1298px #00d9ef, 419px 126px #00d9ef,
    663px 453px #00d9ef, 2177px 609px #00d9ef, 427px 1942px #00d9ef,
    1921px 40px #00d9ef, 1012px 924px #00d9ef, 178px 2087px #00d9ef,
    2191px 2322px #00d9ef, 843px 1096px #00d9ef, 1930px 2224px #00d9ef,
    2303px 756px #00d9ef, 777px 111px #00d9ef, 1090px 2223px #00d9ef,
    356px 445px #00d9ef, 2178px 2201px #00d9ef, 579px 1961px #00d9ef,
    2423px 110px #00d9ef, 1133px 1861px #00d9ef, 67px 769px #00d9ef,
    597px 1723px #00d9ef, 586px 390px #00d9ef, 2068px 240px #00d9ef,
    56px 1874px #00d9ef, 2553px 935px #00d9ef, 1073px 1985px #00d9ef,
    2369px 1933px #00d9ef, 687px 222px #00d9ef, 2353px 2550px #00d9ef,
    575px 2467px #00d9ef, 617px 1267px #00d9ef, 178px 1534px #00d9ef,
    1263px 2180px #00d9ef, 867px 2115px #00d9ef, 1003px 495px #00d9ef,
    674px 1332px #00d9ef, 868px 651px #00d9ef, 417px 2223px #00d9ef,
    2289px 1753px #00d9ef, 994px 447px #00d9ef, 2485px 1692px #00d9ef,
    975px 2377px #00d9ef, 1489px 1016px #00d9ef, 1645px 548px #00d9ef,
    2046px 512px #00d9ef, 661px 232px #00d9ef, 2131px 573px #00d9ef,
    2081px 399px #00d9ef, 2508px 1683px #00d9ef, 2383px 1749px #00d9ef,
    1457px 1871px #00d9ef, 195px 2504px #00d9ef, 504px 2410px #00d9ef,
    1322px 1063px #00d9ef, 155px 491px #00d9ef, 1503px 919px #00d9ef,
    2138px 2280px #00d9ef, 1303px 2113px #00d9ef, 2547px 585px #00d9ef,
    334px 1363px #00d9ef, 2340px 1482px #00d9ef, 277px 633px #00d9ef,
    1905px 877px #00d9ef, 2207px 147px #00d9ef, 905px 719px #00d9ef,
    289px 1657px #00d9ef, 2366px 2031px #00d9ef, 1327px 662px #00d9ef,
    1015px 942px #00d9ef, 519px 779px #00d9ef, 1779px 798px #00d9ef,
    1872px 1668px #00d9ef, 8px 1838px #00d9ef, 155px 34px #00d9ef,
    1494px 1759px #00d9ef, 1276px 1181px #00d9ef, 2461px 891px #00d9ef,
    430px 2223px #00d9ef, 1375px 2062px #00d9ef, 1909px 2077px #00d9ef,
    377px 2377px #00d9ef, 1651px 1632px #00d9ef, 406px 517px #00d9ef,
    862px 1180px #00d9ef, 1771px 1985px #00d9ef, 210px 1909px #00d9ef,
    1486px 1395px #00d9ef, 1835px 347px #00d9ef, 1438px 1161px #00d9ef,
    2461px 1898px #00d9ef, 1161px 1793px #00d9ef, 2163px 1553px #00d9ef,
    352px 1308px #00d9ef, 293px 564px #00d9ef, 2320px 122px #00d9ef,
    1169px 956px #00d9ef, 1776px 1064px #00d9ef, 718px 1074px #00d9ef,
    726px 2162px #00d9ef, 778px 179px #00d9ef, 1745px 1070px #00d9ef,
    2145px 2413px #00d9ef, 1879px 2309px #00d9ef, 2534px 2115px #00d9ef,
    429px 501px #00d9ef, 828px 1832px #00d9ef, 2343px 1618px #00d9ef,
    1085px 1544px #00d9ef, 1990px 2238px #00d9ef, 725px 1677px #00d9ef,
    174px 454px #00d9ef, 1713px 1375px #00d9ef, 1809px 1905px #00d9ef;
  box-shadow: 0 0 #00d9ef, 1360px 1208px #00d9ef, 2248px 1745px #00d9ef,
    1534px 2351px #00d9ef, 2047px 2203px #00d9ef, 480px 1109px #00d9ef,
    609px 955px #00d9ef, 1888px 1198px #00d9ef, 1475px 269px #00d9ef,
    1661px 802px #00d9ef, 1939px 2527px #00d9ef, 1181px 192px #00d9ef,
    1731px 1082px #00d9ef, 968px 910px #00d9ef, 1583px 2109px #00d9ef,
    1164px 949px #00d9ef, 1614px 1130px #00d9ef, 2506px 675px #00d9ef,
    1332px 1415px #00d9ef, 175px 700px #00d9ef, 1055px 713px #00d9ef,
    1947px 312px #00d9ef, 290px 2231px #00d9ef, 1473px 2064px #00d9ef,
    609px 1158px #00d9ef, 1874px 481px #00d9ef, 2385px 2441px #00d9ef,
    452px 235px #00d9ef, 2388px 2438px #00d9ef, 1114px 312px #00d9ef,
    1945px 2204px #00d9ef, 1918px 1972px #00d9ef, 2460px 280px #00d9ef,
    2041px 782px #00d9ef, 854px 1735px #00d9ef, 2330px 716px #00d9ef,
    2495px 593px #00d9ef, 1669px 1617px #00d9ef, 2199px 452px #00d9ef,
    2450px 2013px #00d9ef, 2232px 1685px #00d9ef, 2154px 858px #00d9ef,
    2391px 1903px #00d9ef, 1866px 1171px #00d9ef, 963px 2075px #00d9ef,
    1480px 548px #00d9ef, 1736px 1798px #00d9ef, 1647px 195px #00d9ef,
    2022px 131px #00d9ef, 259px 2334px #00d9ef, 701px 519px #00d9ef,
    1116px 717px #00d9ef, 287px 1700px #00d9ef, 495px 1241px #00d9ef,
    2321px 2299px #00d9ef, 1679px 1955px #00d9ef, 222px 2063px #00d9ef,
    2222px 1293px #00d9ef, 1886px 641px #00d9ef, 83px 1338px #00d9ef,
    208px 2235px #00d9ef, 2135px 1484px #00d9ef, 1818px 2548px #00d9ef,
    293px 660px #00d9ef, 2172px 608px #00d9ef, 1390px 317px #00d9ef,
    2375px 509px #00d9ef, 209px 1962px #00d9ef, 2128px 1074px #00d9ef,
    2020px 1673px #00d9ef, 136px 1938px #00d9ef, 350px 1190px #00d9ef,
    505px 2295px #00d9ef, 951px 444px #00d9ef, 903px 268px #00d9ef,
    311px 18px #00d9ef, 135px 2493px #00d9ef, 2523px 1178px #00d9ef,
    431px 1785px #00d9ef, 1174px 453px #00d9ef, 2514px 990px #00d9ef,
    1629px 850px #00d9ef, 19px 2105px #00d9ef, 640px 1412px #00d9ef,
    1149px 664px #00d9ef, 943px 1353px #00d9ef, 2424px 1337px #00d9ef,
    2120px 550px #00d9ef, 747px 1298px #00d9ef, 419px 126px #00d9ef,
    663px 453px #00d9ef, 2177px 609px #00d9ef, 427px 1942px #00d9ef,
    1921px 40px #00d9ef, 1012px 924px #00d9ef, 178px 2087px #00d9ef,
    2191px 2322px #00d9ef, 843px 1096px #00d9ef, 1930px 2224px #00d9ef,
    2303px 756px #00d9ef, 777px 111px #00d9ef, 1090px 2223px #00d9ef,
    356px 445px #00d9ef, 2178px 2201px #00d9ef, 579px 1961px #00d9ef,
    2423px 110px #00d9ef, 1133px 1861px #00d9ef, 67px 769px #00d9ef,
    597px 1723px #00d9ef, 586px 390px #00d9ef, 2068px 240px #00d9ef,
    56px 1874px #00d9ef, 2553px 935px #00d9ef, 1073px 1985px #00d9ef,
    2369px 1933px #00d9ef, 687px 222px #00d9ef, 2353px 2550px #00d9ef,
    575px 2467px #00d9ef, 617px 1267px #00d9ef, 178px 1534px #00d9ef,
    1263px 2180px #00d9ef, 867px 2115px #00d9ef, 1003px 495px #00d9ef,
    674px 1332px #00d9ef, 868px 651px #00d9ef, 417px 2223px #00d9ef,
    2289px 1753px #00d9ef, 994px 447px #00d9ef, 2485px 1692px #00d9ef,
    975px 2377px #00d9ef, 1489px 1016px #00d9ef, 1645px 548px #00d9ef,
    2046px 512px #00d9ef, 661px 232px #00d9ef, 2131px 573px #00d9ef,
    2081px 399px #00d9ef, 2508px 1683px #00d9ef, 2383px 1749px #00d9ef,
    1457px 1871px #00d9ef, 195px 2504px #00d9ef, 504px 2410px #00d9ef,
    1322px 1063px #00d9ef, 155px 491px #00d9ef, 1503px 919px #00d9ef,
    2138px 2280px #00d9ef, 1303px 2113px #00d9ef, 2547px 585px #00d9ef,
    334px 1363px #00d9ef, 2340px 1482px #00d9ef, 277px 633px #00d9ef,
    1905px 877px #00d9ef, 2207px 147px #00d9ef, 905px 719px #00d9ef,
    289px 1657px #00d9ef, 2366px 2031px #00d9ef, 1327px 662px #00d9ef,
    1015px 942px #00d9ef, 519px 779px #00d9ef, 1779px 798px #00d9ef,
    1872px 1668px #00d9ef, 8px 1838px #00d9ef, 155px 34px #00d9ef,
    1494px 1759px #00d9ef, 1276px 1181px #00d9ef, 2461px 891px #00d9ef,
    430px 2223px #00d9ef, 1375px 2062px #00d9ef, 1909px 2077px #00d9ef,
    377px 2377px #00d9ef, 1651px 1632px #00d9ef, 406px 517px #00d9ef,
    862px 1180px #00d9ef, 1771px 1985px #00d9ef, 210px 1909px #00d9ef,
    1486px 1395px #00d9ef, 1835px 347px #00d9ef, 1438px 1161px #00d9ef,
    2461px 1898px #00d9ef, 1161px 1793px #00d9ef, 2163px 1553px #00d9ef,
    352px 1308px #00d9ef, 293px 564px #00d9ef, 2320px 122px #00d9ef,
    1169px 956px #00d9ef, 1776px 1064px #00d9ef, 718px 1074px #00d9ef,
    726px 2162px #00d9ef, 778px 179px #00d9ef, 1745px 1070px #00d9ef,
    2145px 2413px #00d9ef, 1879px 2309px #00d9ef, 2534px 2115px #00d9ef,
    429px 501px #00d9ef, 828px 1832px #00d9ef, 2343px 1618px #00d9ef,
    1085px 1544px #00d9ef, 1990px 2238px #00d9ef, 725px 1677px #00d9ef,
    174px 454px #00d9ef, 1713px 1375px #00d9ef, 1809px 1905px #00d9ef;
  height: 1px;
  width: 1px;
}
#stars2:after {
  -webkit-box-shadow: 0 0 #00d9ef, 103px 2064px #00d9ef, 2080px 1653px #00d9ef,
    2490px 1265px #00d9ef, 387px 2545px #00d9ef, 1638px 2069px #00d9ef,
    814px 836px #00d9ef, 1817px 1376px #00d9ef, 2532px 1336px #00d9ef,
    1337px 516px #00d9ef, 1673px 1612px #00d9ef, 2256px 478px #00d9ef,
    456px 764px #00d9ef, 146px 2503px #00d9ef, 603px 731px #00d9ef,
    2032px 141px #00d9ef, 660px 249px #00d9ef, 247px 1515px #00d9ef,
    2518px 842px #00d9ef, 132px 671px #00d9ef, 1741px 1929px #00d9ef,
    320px 1965px #00d9ef, 968px 2525px #00d9ef, 1344px 348px #00d9ef,
    698px 1918px #00d9ef, 861px 154px #00d9ef, 514px 943px #00d9ef,
    1260px 389px #00d9ef, 2438px 1706px #00d9ef, 1510px 2238px #00d9ef,
    54px 992px #00d9ef, 2233px 900px #00d9ef, 2530px 816px #00d9ef,
    866px 1751px #00d9ef, 2109px 232px #00d9ef, 1478px 2559px #00d9ef,
    250px 1991px #00d9ef, 267px 926px #00d9ef, 1054px 1362px #00d9ef,
    1837px 2417px #00d9ef, 2270px 1484px #00d9ef, 1062px 1725px #00d9ef,
    915px 738px #00d9ef, 600px 660px #00d9ef, 1774px 467px #00d9ef,
    1959px 436px #00d9ef, 1966px 1002px #00d9ef, 1574px 348px #00d9ef,
    1833px 1206px #00d9ef, 570px 634px #00d9ef, 2368px 1008px #00d9ef,
    1224px 1985px #00d9ef, 1607px 391px #00d9ef, 890px 2477px #00d9ef,
    1861px 2421px #00d9ef, 1828px 1275px #00d9ef, 1801px 5px #00d9ef,
    2121px 2297px #00d9ef, 2437px 1979px #00d9ef, 1677px 1952px #00d9ef,
    67px 1333px #00d9ef, 2100px 114px #00d9ef, 1px 2054px #00d9ef,
    1417px 1351px #00d9ef, 951px 506px #00d9ef, 1613px 1857px #00d9ef,
    679px 1327px #00d9ef, 1068px 1025px #00d9ef, 2299px 908px #00d9ef,
    832px 2397px #00d9ef, 1668px 511px #00d9ef, 1387px 538px #00d9ef,
    1785px 1961px #00d9ef, 2533px 496px #00d9ef, 2007px 1510px #00d9ef,
    623px 1608px #00d9ef, 2085px 801px #00d9ef, 703px 2462px #00d9ef,
    2507px 141px #00d9ef, 1335px 1514px #00d9ef, 1428px 930px #00d9ef,
    1106px 582px #00d9ef, 2547px 1042px #00d9ef, 822px 2512px #00d9ef,
    1665px 393px #00d9ef, 1437px 1157px #00d9ef, 2256px 1913px #00d9ef,
    2493px 348px #00d9ef, 1800px 1898px #00d9ef, 593px 1847px #00d9ef,
    1648px 867px #00d9ef, 2441px 294px #00d9ef, 1131px 489px #00d9ef,
    2367px 1185px #00d9ef, 1033px 2068px #00d9ef, 2416px 1026px #00d9ef,
    2341px 2042px #00d9ef, 72px 838px #00d9ef, 2149px 1962px #00d9ef,
    968px 1697px #00d9ef, 2243px 539px #00d9ef, 220px 802px #00d9ef,
    1057px 1941px #00d9ef, 2421px 1383px #00d9ef, 41px 1252px #00d9ef,
    1368px 1937px #00d9ef, 2414px 2384px #00d9ef, 2553px 1362px #00d9ef,
    2159px 1082px #00d9ef, 1362px 879px #00d9ef, 1571px 1080px #00d9ef,
    2459px 991px #00d9ef, 73px 520px #00d9ef, 2175px 867px #00d9ef,
    528px 2027px #00d9ef, 398px 580px #00d9ef, 363px 855px #00d9ef,
    2217px 1658px #00d9ef, 2065px 376px #00d9ef, 1189px 1563px #00d9ef,
    1112px 981px #00d9ef, 361px 1799px #00d9ef, 1304px 1264px #00d9ef,
    1392px 1004px #00d9ef, 2362px 2061px #00d9ef, 1223px 778px #00d9ef,
    1413px 2535px #00d9ef, 380px 2483px #00d9ef, 2012px 964px #00d9ef,
    2557px 893px #00d9ef, 2172px 2344px #00d9ef, 369px 1884px #00d9ef,
    681px 1326px #00d9ef, 354px 1928px #00d9ef, 1089px 777px #00d9ef,
    2256px 1234px #00d9ef, 2000px 700px #00d9ef, 1134px 1837px #00d9ef,
    1955px 235px #00d9ef, 913px 2339px #00d9ef, 1909px 601px #00d9ef,
    2134px 1019px #00d9ef, 1188px 1954px #00d9ef, 188px 1590px #00d9ef,
    1356px 872px #00d9ef, 1964px 151px #00d9ef, 424px 1408px #00d9ef,
    1247px 1062px #00d9ef, 2311px 905px #00d9ef, 1225px 1360px #00d9ef,
    1124px 902px #00d9ef, 268px 1846px #00d9ef, 36px 1259px #00d9ef,
    302px 944px #00d9ef, 2438px 1659px #00d9ef, 1985px 285px #00d9ef,
    916px 2486px #00d9ef, 1062px 1443px #00d9ef, 1098px 732px #00d9ef,
    2094px 556px #00d9ef, 1575px 826px #00d9ef, 1518px 1685px #00d9ef,
    127px 829px #00d9ef, 995px 1005px #00d9ef, 490px 1032px #00d9ef,
    2266px 442px #00d9ef, 1636px 599px #00d9ef, 313px 2555px #00d9ef,
    2096px 1726px #00d9ef, 1948px 2096px #00d9ef, 1268px 2205px #00d9ef,
    620px 1141px #00d9ef, 1077px 700px #00d9ef, 1756px 1799px #00d9ef,
    2503px 1474px #00d9ef, 1999px 556px #00d9ef, 2132px 146px #00d9ef,
    1683px 1596px #00d9ef, 1140px 231px #00d9ef, 416px 1184px #00d9ef,
    598px 571px #00d9ef, 938px 595px #00d9ef, 1657px 2117px #00d9ef,
    1443px 1226px #00d9ef, 1969px 2448px #00d9ef, 413px 2243px #00d9ef,
    790px 2032px #00d9ef, 746px 51px #00d9ef, 28px 828px #00d9ef,
    393px 1508px #00d9ef, 1385px 2050px #00d9ef, 517px 1774px #00d9ef,
    2543px 1637px #00d9ef, 2036px 1990px #00d9ef, 2549px 505px #00d9ef,
    860px 304px #00d9ef, 963px 2070px #00d9ef, 2034px 1818px #00d9ef,
    199px 317px #00d9ef, 851px 1728px #00d9ef, 2058px 1613px #00d9ef;
  box-shadow: 0 0 #00d9ef, 103px 2064px #00d9ef, 2080px 1653px #00d9ef,
    2490px 1265px #00d9ef, 387px 2545px #00d9ef, 1638px 2069px #00d9ef,
    814px 836px #00d9ef, 1817px 1376px #00d9ef, 2532px 1336px #00d9ef,
    1337px 516px #00d9ef, 1673px 1612px #00d9ef, 2256px 478px #00d9ef,
    456px 764px #00d9ef, 146px 2503px #00d9ef, 603px 731px #00d9ef,
    2032px 141px #00d9ef, 660px 249px #00d9ef, 247px 1515px #00d9ef,
    2518px 842px #00d9ef, 132px 671px #00d9ef, 1741px 1929px #00d9ef,
    320px 1965px #00d9ef, 968px 2525px #00d9ef, 1344px 348px #00d9ef,
    698px 1918px #00d9ef, 861px 154px #00d9ef, 514px 943px #00d9ef,
    1260px 389px #00d9ef, 2438px 1706px #00d9ef, 1510px 2238px #00d9ef,
    54px 992px #00d9ef, 2233px 900px #00d9ef, 2530px 816px #00d9ef,
    866px 1751px #00d9ef, 2109px 232px #00d9ef, 1478px 2559px #00d9ef,
    250px 1991px #00d9ef, 267px 926px #00d9ef, 1054px 1362px #00d9ef,
    1837px 2417px #00d9ef, 2270px 1484px #00d9ef, 1062px 1725px #00d9ef,
    915px 738px #00d9ef, 600px 660px #00d9ef, 1774px 467px #00d9ef,
    1959px 436px #00d9ef, 1966px 1002px #00d9ef, 1574px 348px #00d9ef,
    1833px 1206px #00d9ef, 570px 634px #00d9ef, 2368px 1008px #00d9ef,
    1224px 1985px #00d9ef, 1607px 391px #00d9ef, 890px 2477px #00d9ef,
    1861px 2421px #00d9ef, 1828px 1275px #00d9ef, 1801px 5px #00d9ef,
    2121px 2297px #00d9ef, 2437px 1979px #00d9ef, 1677px 1952px #00d9ef,
    67px 1333px #00d9ef, 2100px 114px #00d9ef, 1px 2054px #00d9ef,
    1417px 1351px #00d9ef, 951px 506px #00d9ef, 1613px 1857px #00d9ef,
    679px 1327px #00d9ef, 1068px 1025px #00d9ef, 2299px 908px #00d9ef,
    832px 2397px #00d9ef, 1668px 511px #00d9ef, 1387px 538px #00d9ef,
    1785px 1961px #00d9ef, 2533px 496px #00d9ef, 2007px 1510px #00d9ef,
    623px 1608px #00d9ef, 2085px 801px #00d9ef, 703px 2462px #00d9ef,
    2507px 141px #00d9ef, 1335px 1514px #00d9ef, 1428px 930px #00d9ef,
    1106px 582px #00d9ef, 2547px 1042px #00d9ef, 822px 2512px #00d9ef,
    1665px 393px #00d9ef, 1437px 1157px #00d9ef, 2256px 1913px #00d9ef,
    2493px 348px #00d9ef, 1800px 1898px #00d9ef, 593px 1847px #00d9ef,
    1648px 867px #00d9ef, 2441px 294px #00d9ef, 1131px 489px #00d9ef,
    2367px 1185px #00d9ef, 1033px 2068px #00d9ef, 2416px 1026px #00d9ef,
    2341px 2042px #00d9ef, 72px 838px #00d9ef, 2149px 1962px #00d9ef,
    968px 1697px #00d9ef, 2243px 539px #00d9ef, 220px 802px #00d9ef,
    1057px 1941px #00d9ef, 2421px 1383px #00d9ef, 41px 1252px #00d9ef,
    1368px 1937px #00d9ef, 2414px 2384px #00d9ef, 2553px 1362px #00d9ef,
    2159px 1082px #00d9ef, 1362px 879px #00d9ef, 1571px 1080px #00d9ef,
    2459px 991px #00d9ef, 73px 520px #00d9ef, 2175px 867px #00d9ef,
    528px 2027px #00d9ef, 398px 580px #00d9ef, 363px 855px #00d9ef,
    2217px 1658px #00d9ef, 2065px 376px #00d9ef, 1189px 1563px #00d9ef,
    1112px 981px #00d9ef, 361px 1799px #00d9ef, 1304px 1264px #00d9ef,
    1392px 1004px #00d9ef, 2362px 2061px #00d9ef, 1223px 778px #00d9ef,
    1413px 2535px #00d9ef, 380px 2483px #00d9ef, 2012px 964px #00d9ef,
    2557px 893px #00d9ef, 2172px 2344px #00d9ef, 369px 1884px #00d9ef,
    681px 1326px #00d9ef, 354px 1928px #00d9ef, 1089px 777px #00d9ef,
    2256px 1234px #00d9ef, 2000px 700px #00d9ef, 1134px 1837px #00d9ef,
    1955px 235px #00d9ef, 913px 2339px #00d9ef, 1909px 601px #00d9ef,
    2134px 1019px #00d9ef, 1188px 1954px #00d9ef, 188px 1590px #00d9ef,
    1356px 872px #00d9ef, 1964px 151px #00d9ef, 424px 1408px #00d9ef,
    1247px 1062px #00d9ef, 2311px 905px #00d9ef, 1225px 1360px #00d9ef,
    1124px 902px #00d9ef, 268px 1846px #00d9ef, 36px 1259px #00d9ef,
    302px 944px #00d9ef, 2438px 1659px #00d9ef, 1985px 285px #00d9ef,
    916px 2486px #00d9ef, 1062px 1443px #00d9ef, 1098px 732px #00d9ef,
    2094px 556px #00d9ef, 1575px 826px #00d9ef, 1518px 1685px #00d9ef,
    127px 829px #00d9ef, 995px 1005px #00d9ef, 490px 1032px #00d9ef,
    2266px 442px #00d9ef, 1636px 599px #00d9ef, 313px 2555px #00d9ef,
    2096px 1726px #00d9ef, 1948px 2096px #00d9ef, 1268px 2205px #00d9ef,
    620px 1141px #00d9ef, 1077px 700px #00d9ef, 1756px 1799px #00d9ef,
    2503px 1474px #00d9ef, 1999px 556px #00d9ef, 2132px 146px #00d9ef,
    1683px 1596px #00d9ef, 1140px 231px #00d9ef, 416px 1184px #00d9ef,
    598px 571px #00d9ef, 938px 595px #00d9ef, 1657px 2117px #00d9ef,
    1443px 1226px #00d9ef, 1969px 2448px #00d9ef, 413px 2243px #00d9ef,
    790px 2032px #00d9ef, 746px 51px #00d9ef, 28px 828px #00d9ef,
    393px 1508px #00d9ef, 1385px 2050px #00d9ef, 517px 1774px #00d9ef,
    2543px 1637px #00d9ef, 2036px 1990px #00d9ef, 2549px 505px #00d9ef,
    860px 304px #00d9ef, 963px 2070px #00d9ef, 2034px 1818px #00d9ef,
    199px 317px #00d9ef, 851px 1728px #00d9ef, 2058px 1613px #00d9ef;
  height: 1px;
  width: 1px;
}
#stars3 {
  -webkit-animation: animParticle 180s linear infinite;
  animation: animParticle 180s linear infinite;
  -webkit-box-shadow: 0 0 #00d9ef, 608px 1003px #00d9ef, 1498px 218px #00d9ef,
    693px 2484px #00d9ef, 1072px 2267px #00d9ef, 1397px 692px #00d9ef,
    1832px 38px #00d9ef, 1596px 1193px #00d9ef, 1161px 76px #00d9ef,
    334px 1686px #00d9ef, 1963px 812px #00d9ef, 1724px 1455px #00d9ef,
    2052px 12px #00d9ef, 1031px 1812px #00d9ef, 1806px 584px #00d9ef,
    600px 159px #00d9ef, 2289px 501px #00d9ef, 1704px 1034px #00d9ef,
    2547px 2327px #00d9ef, 2335px 851px #00d9ef, 898px 576px #00d9ef,
    383px 76px #00d9ef, 2010px 18px #00d9ef, 1263px 715px #00d9ef,
    44px 81px #00d9ef, 1630px 893px #00d9ef, 1977px 1165px #00d9ef,
    1680px 1516px #00d9ef, 1430px 631px #00d9ef, 1660px 50px #00d9ef,
    1164px 2419px #00d9ef, 1287px 1591px #00d9ef, 1153px 1580px #00d9ef,
    1924px 29px #00d9ef, 2312px 1199px #00d9ef, 942px 1641px #00d9ef,
    911px 442px #00d9ef, 1043px 379px #00d9ef, 865px 772px #00d9ef,
    2204px 1382px #00d9ef, 1100px 1222px #00d9ef, 481px 2140px #00d9ef,
    1414px 1738px #00d9ef, 190px 957px #00d9ef, 1473px 317px #00d9ef,
    2526px 2342px #00d9ef, 2212px 554px #00d9ef, 2254px 809px #00d9ef,
    2016px 1451px #00d9ef, 1601px 1667px #00d9ef, 838px 1202px #00d9ef,
    1649px 269px #00d9ef, 1982px 1525px #00d9ef, 2368px 1074px #00d9ef,
    15px 219px #00d9ef, 1212px 2099px #00d9ef, 716px 308px #00d9ef,
    242px 498px #00d9ef, 2339px 1583px #00d9ef, 1577px 2469px #00d9ef,
    883px 1747px #00d9ef, 1693px 114px #00d9ef, 289px 2217px #00d9ef,
    2049px 2279px #00d9ef, 1167px 1655px #00d9ef, 662px 1284px #00d9ef,
    2557px 1256px #00d9ef, 1134px 2422px #00d9ef, 822px 647px #00d9ef,
    1758px 1188px #00d9ef, 1088px 1329px #00d9ef, 1969px 941px #00d9ef,
    2522px 2062px #00d9ef, 457px 1065px #00d9ef, 430px 2437px #00d9ef,
    1069px 1085px #00d9ef, 2108px 660px #00d9ef, 649px 78px #00d9ef,
    404px 395px #00d9ef, 2275px 1981px #00d9ef, 2226px 2163px #00d9ef,
    1855px 1538px #00d9ef, 1387px 646px #00d9ef, 644px 1305px #00d9ef,
    799px 1143px #00d9ef, 1809px 190px #00d9ef, 744px 1873px #00d9ef,
    1374px 821px #00d9ef, 2128px 1646px #00d9ef, 1162px 1297px #00d9ef,
    1036px 111px #00d9ef, 434px 498px #00d9ef, 282px 1432px #00d9ef,
    12px 1444px #00d9ef, 354px 2130px #00d9ef, 660px 1453px #00d9ef,
    52px 1076px #00d9ef, 684px 1310px #00d9ef, 742px 944px #00d9ef,
    1800px 835px #00d9ef, 1895px 188px #00d9ef;
  box-shadow: 0 0 #00d9ef, 608px 1003px #00d9ef, 1498px 218px #00d9ef,
    693px 2484px #00d9ef, 1072px 2267px #00d9ef, 1397px 692px #00d9ef,
    1832px 38px #00d9ef, 1596px 1193px #00d9ef, 1161px 76px #00d9ef,
    334px 1686px #00d9ef, 1963px 812px #00d9ef, 1724px 1455px #00d9ef,
    2052px 12px #00d9ef, 1031px 1812px #00d9ef, 1806px 584px #00d9ef,
    600px 159px #00d9ef, 2289px 501px #00d9ef, 1704px 1034px #00d9ef,
    2547px 2327px #00d9ef, 2335px 851px #00d9ef, 898px 576px #00d9ef,
    383px 76px #00d9ef, 2010px 18px #00d9ef, 1263px 715px #00d9ef,
    44px 81px #00d9ef, 1630px 893px #00d9ef, 1977px 1165px #00d9ef,
    1680px 1516px #00d9ef, 1430px 631px #00d9ef, 1660px 50px #00d9ef,
    1164px 2419px #00d9ef, 1287px 1591px #00d9ef, 1153px 1580px #00d9ef,
    1924px 29px #00d9ef, 2312px 1199px #00d9ef, 942px 1641px #00d9ef,
    911px 442px #00d9ef, 1043px 379px #00d9ef, 865px 772px #00d9ef,
    2204px 1382px #00d9ef, 1100px 1222px #00d9ef, 481px 2140px #00d9ef,
    1414px 1738px #00d9ef, 190px 957px #00d9ef, 1473px 317px #00d9ef,
    2526px 2342px #00d9ef, 2212px 554px #00d9ef, 2254px 809px #00d9ef,
    2016px 1451px #00d9ef, 1601px 1667px #00d9ef, 838px 1202px #00d9ef,
    1649px 269px #00d9ef, 1982px 1525px #00d9ef, 2368px 1074px #00d9ef,
    15px 219px #00d9ef, 1212px 2099px #00d9ef, 716px 308px #00d9ef,
    242px 498px #00d9ef, 2339px 1583px #00d9ef, 1577px 2469px #00d9ef,
    883px 1747px #00d9ef, 1693px 114px #00d9ef, 289px 2217px #00d9ef,
    2049px 2279px #00d9ef, 1167px 1655px #00d9ef, 662px 1284px #00d9ef,
    2557px 1256px #00d9ef, 1134px 2422px #00d9ef, 822px 647px #00d9ef,
    1758px 1188px #00d9ef, 1088px 1329px #00d9ef, 1969px 941px #00d9ef,
    2522px 2062px #00d9ef, 457px 1065px #00d9ef, 430px 2437px #00d9ef,
    1069px 1085px #00d9ef, 2108px 660px #00d9ef, 649px 78px #00d9ef,
    404px 395px #00d9ef, 2275px 1981px #00d9ef, 2226px 2163px #00d9ef,
    1855px 1538px #00d9ef, 1387px 646px #00d9ef, 644px 1305px #00d9ef,
    799px 1143px #00d9ef, 1809px 190px #00d9ef, 744px 1873px #00d9ef,
    1374px 821px #00d9ef, 2128px 1646px #00d9ef, 1162px 1297px #00d9ef,
    1036px 111px #00d9ef, 434px 498px #00d9ef, 282px 1432px #00d9ef,
    12px 1444px #00d9ef, 354px 2130px #00d9ef, 660px 1453px #00d9ef,
    52px 1076px #00d9ef, 684px 1310px #00d9ef, 742px 944px #00d9ef,
    1800px 835px #00d9ef, 1895px 188px #00d9ef;
  height: 1px;
  width: 1px;
}
#stars3:after {
  -webkit-box-shadow: 0 0 #00d9ef, 2035px 1710px #00d9ef, 2220px 12px #00d9ef,
    2423px 308px #00d9ef, 596px 1855px #00d9ef, 600px 1667px #00d9ef,
    2086px 1525px #00d9ef, 743px 1557px #00d9ef, 1037px 1455px #00d9ef,
    772px 2480px #00d9ef, 537px 353px #00d9ef, 1930px 135px #00d9ef,
    1962px 1596px #00d9ef, 1552px 1319px #00d9ef, 1889px 134px #00d9ef,
    550px 983px #00d9ef, 1337px 2285px #00d9ef, 361px 2039px #00d9ef,
    2379px 2171px #00d9ef, 2337px 999px #00d9ef, 1675px 417px #00d9ef,
    1022px 856px #00d9ef, 181px 311px #00d9ef, 819px 1129px #00d9ef,
    471px 1806px #00d9ef, 2030px 188px #00d9ef, 1582px 2381px #00d9ef,
    2071px 977px #00d9ef, 1500px 935px #00d9ef, 848px 1578px #00d9ef,
    1715px 2344px #00d9ef, 1095px 274px #00d9ef, 464px 1570px #00d9ef,
    906px 1146px #00d9ef, 1266px 1079px #00d9ef, 1894px 1160px #00d9ef,
    2478px 828px #00d9ef, 361px 573px #00d9ef, 1263px 2066px #00d9ef,
    166px 1856px #00d9ef, 1833px 616px #00d9ef, 1070px 2354px #00d9ef,
    1687px 882px #00d9ef, 1698px 1641px #00d9ef, 447px 1128px #00d9ef,
    1180px 1739px #00d9ef, 308px 2156px #00d9ef, 391px 989px #00d9ef,
    2440px 288px #00d9ef, 2446px 2445px #00d9ef, 110px 1947px #00d9ef,
    1308px 203px #00d9ef, 1476px 1246px #00d9ef, 1055px 638px #00d9ef,
    2079px 533px #00d9ef, 1350px 1646px #00d9ef, 1491px 317px #00d9ef,
    2317px 1882px #00d9ef, 1566px 1863px #00d9ef, 1570px 1345px #00d9ef,
    411px 1780px #00d9ef, 1737px 235px #00d9ef, 506px 225px #00d9ef,
    1343px 1456px #00d9ef, 479px 1239px #00d9ef, 756px 1775px #00d9ef,
    1792px 2237px #00d9ef, 1741px 816px #00d9ef, 942px 1962px #00d9ef,
    85px 2430px #00d9ef, 1998px 375px #00d9ef, 1884px 1036px #00d9ef,
    1084px 2457px #00d9ef, 1069px 1406px #00d9ef, 1111px 953px #00d9ef,
    316px 2070px #00d9ef, 2298px 2121px #00d9ef, 229px 401px #00d9ef,
    1008px 979px #00d9ef, 1729px 1363px #00d9ef, 2131px 1084px #00d9ef,
    868px 2453px #00d9ef, 1080px 1768px #00d9ef, 1508px 92px #00d9ef,
    2036px 117px #00d9ef, 2196px 621px #00d9ef, 281px 83px #00d9ef,
    1949px 2212px #00d9ef, 354px 1794px #00d9ef, 1834px 730px #00d9ef,
    1057px 898px #00d9ef, 2219px 2506px #00d9ef, 1371px 1251px #00d9ef,
    1657px 935px #00d9ef, 1981px 1699px #00d9ef, 2211px 1456px #00d9ef,
    438px 476px #00d9ef, 146px 2485px #00d9ef, 1345px 415px #00d9ef,
    1759px 1086px #00d9ef, 1933px 322px #00d9ef;
  box-shadow: 0 0 #00d9ef, 2035px 1710px #00d9ef, 2220px 12px #00d9ef,
    2423px 308px #00d9ef, 596px 1855px #00d9ef, 600px 1667px #00d9ef,
    2086px 1525px #00d9ef, 743px 1557px #00d9ef, 1037px 1455px #00d9ef,
    772px 2480px #00d9ef, 537px 353px #00d9ef, 1930px 135px #00d9ef,
    1962px 1596px #00d9ef, 1552px 1319px #00d9ef, 1889px 134px #00d9ef,
    550px 983px #00d9ef, 1337px 2285px #00d9ef, 361px 2039px #00d9ef,
    2379px 2171px #00d9ef, 2337px 999px #00d9ef, 1675px 417px #00d9ef,
    1022px 856px #00d9ef, 181px 311px #00d9ef, 819px 1129px #00d9ef,
    471px 1806px #00d9ef, 2030px 188px #00d9ef, 1582px 2381px #00d9ef,
    2071px 977px #00d9ef, 1500px 935px #00d9ef, 848px 1578px #00d9ef,
    1715px 2344px #00d9ef, 1095px 274px #00d9ef, 464px 1570px #00d9ef,
    906px 1146px #00d9ef, 1266px 1079px #00d9ef, 1894px 1160px #00d9ef,
    2478px 828px #00d9ef, 361px 573px #00d9ef, 1263px 2066px #00d9ef,
    166px 1856px #00d9ef, 1833px 616px #00d9ef, 1070px 2354px #00d9ef,
    1687px 882px #00d9ef, 1698px 1641px #00d9ef, 447px 1128px #00d9ef,
    1180px 1739px #00d9ef, 308px 2156px #00d9ef, 391px 989px #00d9ef,
    2440px 288px #00d9ef, 2446px 2445px #00d9ef, 110px 1947px #00d9ef,
    1308px 203px #00d9ef, 1476px 1246px #00d9ef, 1055px 638px #00d9ef,
    2079px 533px #00d9ef, 1350px 1646px #00d9ef, 1491px 317px #00d9ef,
    2317px 1882px #00d9ef, 1566px 1863px #00d9ef, 1570px 1345px #00d9ef,
    411px 1780px #00d9ef, 1737px 235px #00d9ef, 506px 225px #00d9ef,
    1343px 1456px #00d9ef, 479px 1239px #00d9ef, 756px 1775px #00d9ef,
    1792px 2237px #00d9ef, 1741px 816px #00d9ef, 942px 1962px #00d9ef,
    85px 2430px #00d9ef, 1998px 375px #00d9ef, 1884px 1036px #00d9ef,
    1084px 2457px #00d9ef, 1069px 1406px #00d9ef, 1111px 953px #00d9ef,
    316px 2070px #00d9ef, 2298px 2121px #00d9ef, 229px 401px #00d9ef,
    1008px 979px #00d9ef, 1729px 1363px #00d9ef, 2131px 1084px #00d9ef,
    868px 2453px #00d9ef, 1080px 1768px #00d9ef, 1508px 92px #00d9ef,
    2036px 117px #00d9ef, 2196px 621px #00d9ef, 281px 83px #00d9ef,
    1949px 2212px #00d9ef, 354px 1794px #00d9ef, 1834px 730px #00d9ef,
    1057px 898px #00d9ef, 2219px 2506px #00d9ef, 1371px 1251px #00d9ef,
    1657px 935px #00d9ef, 1981px 1699px #00d9ef, 2211px 1456px #00d9ef,
    438px 476px #00d9ef, 146px 2485px #00d9ef, 1345px 415px #00d9ef,
    1759px 1086px #00d9ef, 1933px 322px #00d9ef;
  height: 3px;
  width: 3px;
}
@-webkit-keyframes animParticle {
  from {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-2560px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2560px);
  }
}
@keyframes animParticle {
  from {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-2560px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2560px);
  }
}
